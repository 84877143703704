export enum TraitCategoryColor {
  BUSINESS_ATTRIBUTE = '#0084D1',
  CUSTOMER_FOCUS = '#00908D',
  ENGAGEMENT_BEHAVIOR = '#B476FF',
  PURCHASE_BEHAVIOR = '#FF575A',
  default = '#A7A7A7'
}

export enum TraitSource {
  preDefined = 'PRE_DEFINED',
  customAudience = 'CUSTOM_AUDIENCE'
}

export type TTraitId = string;

export type TTraitSource = 'PRE_DEFINED' | 'CUSTOM_AUDIENCE';

export enum TraitType {
  boolean = 'BOOLEAN',
  comparison = 'COMPARISON',
  list = 'LIST',
  listBoolean = 'LIST_BOOLEAN',
  listComparison = 'LIST_COMPARISON',
  numeric = 'NUMERIC',
  listPriority = 'LIST_PRIORITY',
  listPriorityRange = 'LIST_PRIORITY_RANGE',
  listObject = 'LIST_OBJECT'
}

export interface TraitToggle {
  [TraitType.listPriority]: boolean;
}

export type TTraitType =
  | 'BOOLEAN'
  | 'COMPARISON'
  | 'LIST'
  | 'LIST_BOOLEAN'
  | 'LIST_COMPARISON'
  | 'NUMERIC'
  | 'LIST_PRIORITY'
  | 'LIST_PRIORITY_RANGE'
  | 'LIST_OBJECT';

export interface TraitMetadata {
  id: string;
  value: boolean;
}
export interface Trait {
  id: TTraitId;
  name: string;
  type: TTraitType;
  metadata?: TraitMetadata[];
  category: string;
  source: TTraitSource | null;
  color?: string;
}
