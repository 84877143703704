import { styled } from '@hexa-ui/theme';
import { globalCss } from '@stitches/react';

export default styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '32px 0px'
});

export const globalStyles = globalCss({
  'div[class*="-variant-overlay"]': { left: "50%", top: '50%', bottom: 'unset', minWidth: 'auto'},
});