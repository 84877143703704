import { Paragraph } from '@hexa-ui/components';
import { Copy } from '@hexa-ui/icons';
import { useCallback, useState } from 'react';
import { LinkProps } from './Link.d';
import { linkStyles } from './Link.styles';

export default function Link({ url }: LinkProps) {
  const [showCopy, setShowCopy] = useState(false);
  const styles = linkStyles({ showCopy });

  const handleTruncate = useCallback(() => {
    const LIMIT_LENGHT = 40;
    const REMAINING_COUNT = 28;
    const CUT_COUNT = 36;

    if (url.length < LIMIT_LENGHT) return url;

    const truncatedUrl = url.split('');

    const deletCount = truncatedUrl.length - CUT_COUNT;

    truncatedUrl.splice(REMAINING_COUNT, deletCount, '...');
    return truncatedUrl.join('');
  }, [url]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(url);
  }, [url]);

  return (
    <div
      className={styles.container}
      data-testid="link-container"
      onMouseEnter={() => setShowCopy(true)}
      onMouseLeave={() => setShowCopy(false)}
    >
      <Paragraph className={styles.url}>{handleTruncate()}</Paragraph>

      <button type="button" className={styles.icon} onClick={handleCopy}>
        <Copy size="large" />
      </button>
    </div>
  );
}
