import { ImageProps } from '../Image.d';

export function BusinessImage({ size = '64px' }: ImageProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 64 64" version="1.1">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="174.915953%" id="linearGradientBusiness">
          <stop stopColor="#9CFFFD" offset="0%" />
          <stop stopColor="#7CE5E3" offset="100%" />
        </linearGradient>
      </defs>
      <g
        id="-Prototype-v2-(Hub-layout)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="4.1---Goal-selection-Copy-2"
          transform="translate(-356.000000, -224.000000)"
          fillRule="nonzero"
        >
          <g id="Group-2" transform="translate(332.000000, 200.000000)">
            <g id="increase64x64" transform="translate(24.000000, 24.000000)">
              <circle id="Oval" fill="url(#linearGradientBusiness)" cx="32" cy="32" r="32" />
              <g id="Path" transform="translate(3.000000, 0.000000)">
                <path
                  d="M12.2374957,15.2455 C12.8301188,6.7275 19.9892675,0 28.7338311,0 C37.4783947,0 44.6375435,6.7275 45.2301665,15.2455 C45.2563988,15.6235 45.2301665,31.368 28.7338311,31.368 C12.2373696,31.368 12.2111373,15.6235 12.2374957,15.2455 L12.2374957,15.2455 Z"
                  fill="#FFC16B"
                />
                <path
                  d="M28.7338311,30.491125 C19.9893936,30.491125 12.8312538,23.763375 12.2385046,15.2455 C12.2121462,15.6235 12.1977689,16.004875 12.1977689,16.3895 C12.1977689,25.44125 19.601206,32.779125 28.7338311,32.779125 C37.8664563,32.779125 45.2697672,25.44125 45.2697672,16.3895 C45.2697672,16.004875 45.2553899,15.6235 45.2290315,15.2455 C44.6364084,23.763375 37.4782686,30.491125 28.7338311,30.491125 L28.7338311,30.491125 Z"
                  fill="#FFB042"
                />
                <g id="Group" transform="translate(4.215452, 30.266625)" fill="#FF6F71">
                  <path
                    d="M14.7303246,19.811875 L8.210336,12.94175 C8.1144872,12.84075 8.00136039,12.755 7.87221674,12.702 C7.48516415,12.543375 7.06279884,12.645625 6.79543113,12.927375 L0.280739443,19.791875 C0.0686109115,20.015375 -0.0403540416,20.324 0.0137500844,20.626125 C0.0978700193,21.095875 0.50384014,21.4185 0.962274867,21.4185 L2.85074849,21.4185 L2.85074849,30.49225 L3.98580008,32.608625 L11.0062203,32.608625 L12.1412719,30.49225 L12.1412719,21.4185 L14.0018737,21.4185 C14.3113644,21.4185 14.6127837,21.28575 14.7977971,21.039875 C15.0855957,20.657125 15.0444817,20.142875 14.7303246,19.811875 L14.7303246,19.811875 Z"
                    id="Path"
                  />
                  <path
                    d="M31.7341542,13.2335 L25.218958,6.3685 C24.9442755,6.079 24.505515,5.979 24.110391,6.157 C23.9965075,6.208375 23.8962446,6.28575 23.8104851,6.376125 L17.3031082,13.233 C17.0909797,13.4565 16.9820147,13.765125 17.0361189,14.06725 C17.1202388,14.537 17.5262089,14.859625 17.9846436,14.859625 L19.8731173,14.859625 L19.8731173,30.492125 L21.0081689,32.6085 L28.0285891,32.6085 L29.1636407,30.492125 L29.1636407,14.859625 L31.0521143,14.859625 C31.5104229,14.859625 31.9162669,14.53725 32.0006391,14.067625 C32.0548693,13.765625 31.9461566,13.457 31.7341542,13.2335 L31.7341542,13.2335 Z"
                    id="Path"
                  />
                  <path
                    d="M48.7563968,7.165125 L42.2413268,0.300125 C42.0595924,0.108625 41.8059715,0 41.5407477,0 C41.275524,0 41.021903,0.108625 40.8401687,0.300125 L34.3249725,7.165125 C34.1129701,7.388625 34.0041313,7.69725 34.0583615,7.99925 C34.1427337,8.468875 34.5485777,8.79125 35.0068863,8.79125 L36.8953599,8.79125 L36.8953599,30.492125 L38.0304115,32.6085 L45.0508317,32.6085 L46.1858833,30.492125 L46.1858833,8.79125 L48.0742308,8.79125 C48.5325394,8.79125 48.9383834,8.468875 49.0227556,7.99925 C49.0772381,7.69725 48.9683993,7.3885 48.7563968,7.165125 L48.7563968,7.165125 Z"
                    id="Path"
                  />
                </g>
                <path
                  d="M7.06620065,60.758625 L7.06620065,63.046625 C7.06620065,63.573125 7.49688967,64 8.02809381,64 L15.394957,64 C15.9261612,64 16.3568502,63.573125 16.3568502,63.046625 L16.3568502,60.758625 L7.06620065,60.758625 Z"
                  fill="#E4423F"
                />
                <path
                  d="M24.0885694,60.758625 L24.0885694,63.046625 C24.0885694,63.573125 24.5192584,64 25.0504626,64 L32.4173258,64 C32.9485299,64 33.379219,63.573125 33.379219,63.046625 L33.379219,60.758625 L24.0885694,60.758625 Z"
                  fill="#E4423F"
                />
                <path
                  d="M41.1109382,60.758625 L41.1109382,63.046625 C41.1109382,63.573125 41.5415011,64 42.0728314,64 L49.4396946,64 C49.9708987,64 50.4015877,63.573125 50.4015877,63.046625 L50.4015877,60.758625 L41.1109382,60.758625 Z"
                  fill="#E4423F"
                />
                <path
                  d="M17.0974083,15.2455 C17.6770413,9.382 22.6657192,4.800375 28.7338311,4.800375 C34.7879441,4.800375 39.7674154,9.360625 40.3660921,15.204625 C40.405945,15.59425 40.3663443,26.85375 28.7338311,26.85375 C17.0971561,26.85375 17.0602038,15.621875 17.0974083,15.2455 Z"
                  fill="#FFEBD0"
                />
                <path
                  d="M28.7338311,25.690625 C22.6657192,25.690625 17.6775458,21.109 17.0977866,15.2455 C17.0605822,15.621875 17.0410341,16.003375 17.0410341,16.3895 C17.0410341,22.79 22.2760181,27.978625 28.733705,27.978625 C35.1913919,27.978625 40.426376,22.79 40.426376,16.3895 C40.426376,16.003375 40.4068278,15.621875 40.3696234,15.2455 C39.7901165,21.109 34.8020692,25.690625 28.7338311,25.690625 L28.7338311,25.690625 Z"
                  fill="#FAD9AC"
                />
                <path
                  d="M28.3677139,20.86025 C26.8541857,20.768875 26.4383784,19.866 26.3611949,19.653875 C26.2509688,19.245625 25.8761496,18.944625 25.4294437,18.944625 C24.8960956,18.944625 24.463767,19.373125 24.463767,19.90175 C24.463767,19.9785 24.4738564,20.052875 24.4911344,20.124375 L24.490756,20.1245 C24.4916388,20.127875 24.4935306,20.1345 24.4959268,20.14275 C24.5077818,20.187875 24.5217808,20.232125 24.5398155,20.274375 C24.7180186,20.76175 25.4323444,22.27375 27.5369823,22.677625 L27.5369823,23.948625 C27.5369823,24.476125 27.968428,24.90375 28.5007672,24.90375 C29.0331064,24.90375 29.4645521,24.476125 29.4645521,23.948625 L29.4645521,22.706375 C30.3869707,22.54725 31.0067089,22.147625 31.3941398,21.78625 C32.1150237,21.113875 32.5454605,20.0975 32.5454605,19.06725 C32.5454605,18.099375 32.1624436,15.80625 28.6147768,15.41825 C27.9120538,15.341375 27.4106132,15.11975 27.1245802,14.759375 C26.8720943,14.44125 26.8447269,14.08975 26.8447269,13.953375 C26.8447269,13.785375 26.891264,12.312 28.5007672,12.312 C29.5567435,12.312 29.9831446,12.977625 30.0633549,13.122375 C30.2062453,13.473875 30.5529405,13.722375 30.9587845,13.722375 C31.4921326,13.722375 31.9244612,13.293875 31.9244612,12.76525 C31.9244612,12.608125 31.8854911,12.46025 31.8177663,12.329375 L31.8182708,12.329125 C31.5466151,11.743875 30.7795724,10.828375 29.4645521,10.513125 L29.4645521,9.23125 C29.4645521,8.70375 29.0331064,8.276 28.5007672,8.276 C27.9685541,8.276 27.5369823,8.703625 27.5369823,9.23125 L27.5369823,10.50925 C25.6347619,10.956875 24.9172832,12.716 24.9172832,13.95325 C24.9172832,15.329 25.830243,17.035375 28.4032789,17.316875 C30.6178907,17.559125 30.6178907,18.694125 30.6178907,19.067125 C30.6178907,19.463375 30.4748742,20.02025 30.073192,20.39475 C29.6935803,20.749 29.120127,20.90575 28.3677139,20.86025 L28.3677139,20.86025 Z"
                  fill="#FF6F71"
                />
                <g id="Group" transform="translate(0.000000, 6.579826)" fill="#FFC16B">
                  <path
                    d="M7.58000067,10.1219243 C7.58000067,9.59442429 7.14855494,9.16667429 6.61621575,9.16667429 L0.963784921,9.16667429 C0.43157184,9.16667429 0,9.59429929 0,10.1219243 C0,10.6494243 0.431445723,11.0770493 0.963784921,11.0770493 L6.61621575,11.0770493 C7.14855494,11.0770493 7.58000067,10.6494243 7.58000067,10.1219243 Z"
                    id="Path"
                  />
                  <path
                    d="M7.86300687,17.6181743 L5.85270437,18.3987993 C5.35706518,18.5912993 5.11277685,19.1454243 5.30687067,19.6366743 C5.45581467,20.0134243 5.81940953,20.2436743 6.20444425,20.2436743 C6.32135457,20.2436743 6.44028275,20.2224243 6.55567966,20.1776743 L8.56598215,19.3970493 C9.06162135,19.2045493 9.30590968,18.6504243 9.11181585,18.1591743 C8.91759591,17.6680493 8.35851994,17.4256743 7.86300687,17.6181743 Z"
                    id="Path"
                  />
                  <path
                    d="M5.85270437,1.84492429 L7.86300687,2.62554929 C7.97852989,2.67042429 8.09733196,2.69154929 8.21436839,2.69154929 C8.59940312,2.69154929 8.96299798,2.46129929 9.11194197,2.08454929 C9.30603579,1.59342429 9.06174747,1.03917429 8.56610827,0.846674287 L6.55580578,0.0660492872 C6.05991435,-0.126325713 5.50121673,0.115799287 5.30687067,0.607049287 C5.11277685,1.09829929 5.35706518,1.65242429 5.85270437,1.84492429 L5.85270437,1.84492429 Z"
                    id="Path"
                  />
                  <path
                    d="M56.0543969,9.16667429 L50.4019661,9.16667429 C49.8696269,9.16667429 49.4381812,9.59429929 49.4381812,10.1219243 C49.4381812,10.6494243 49.8696269,11.0770493 50.4019661,11.0770493 L56.0543969,11.0770493 C56.5867361,11.0770493 57.0181818,10.6494243 57.0181818,10.1219243 C57.0180557,9.59442429 56.58661,9.16667429 56.0543969,9.16667429 L56.0543969,9.16667429 Z"
                    id="Path"
                  />
                  <path
                    d="M48.4520735,19.3969243 L50.462376,20.1775493 C50.5778991,20.2224243 50.6968273,20.2435493 50.8137376,20.2435493 C51.1987723,20.2435493 51.5623672,20.0132993 51.7113111,19.6365493 C51.905405,19.1454243 51.6611166,18.5911743 51.1656036,18.3986743 L49.1553011,17.6180493 C48.6592835,17.4255493 48.1005859,17.6677993 47.906366,18.1590493 C47.7122721,18.6502993 47.9565605,19.2045493 48.4520735,19.3969243 Z"
                    id="Path"
                  />
                  <path
                    d="M48.8039395,2.69167429 C48.9208499,2.69167429 49.0399042,2.67042429 49.1553011,2.62567429 L51.1656036,1.84504929 C51.6611166,1.65254929 51.9055311,1.09842429 51.7113111,0.607174287 C51.5170912,0.115924287 50.957763,-0.125950713 50.462376,0.0661742872 L48.4520735,0.846799287 C47.9565605,1.03929929 47.7122721,1.59342429 47.906366,2.08467429 C48.05531,2.46142429 48.4189048,2.69167429 48.8039395,2.69167429 L48.8039395,2.69167429 Z"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
