/* eslint-disable effector/no-getState */
import { CmsBanners, ResponseBanner, ResponseRedirectUrl } from '../../domain/channel';
import { getAllModules, getCmsRedirectUrl } from '../../services/identity/channel/ChannelService';
import {
  loadChannelData,
  loadChannelDataDone,
  loadChannelDataFail
} from '../../stores/channel/ChannelEvents';
import $SupportedCountriesStore from '../../stores/supportedCountries/SupportedCountriesStore';

const execute = async (): Promise<CmsBanners[]> => {
  try {
    const userCountry = $SupportedCountriesStore
      .getState()
      .selectedCountry.code.toLocaleLowerCase();

    loadChannelData();

    const response: ResponseBanner = await getAllModules(userCountry);
    loadChannelDataDone();
    return response.results;
  } catch (error) {
    loadChannelDataFail();
    return undefined;
  }
};

const executeCmsRedirectUrl = async (): Promise<string> => {
  try {
    const userCountry = $SupportedCountriesStore
      .getState()
      .selectedCountry.code.toLocaleLowerCase();

    const response: ResponseRedirectUrl = await getCmsRedirectUrl(userCountry);
    return response.url;
  } catch (error) {
    return undefined;
  }
};

const ChannelUsesCase = {
  execute,
  executeCmsRedirectUrl
};

export default ChannelUsesCase;
