import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    return <ErrorBoundary err={error} />;
  }
});

export const { bootstrap, mount, unmount } = reactLifecycles;
