import { Grid } from '@hexa-ui/components';
import { Outlet } from 'react-router-dom';
import { BreadcrumbComponent } from '../Breadcrumb';

function MainTemplate(): JSX.Element {
  return (
    <Grid.Container style={{ width: '100%' }}>
      <BreadcrumbComponent />
      <Outlet />
    </Grid.Container>
  );
}

export default MainTemplate;
