/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv'
import * as Segment from './segment'

export interface KpIsItem {
	/**
	 * The campaign KPIs
	 */
	campaign_kpis?: string | null
}
export interface ActionInteraction {
	/**
	 * 
The campaign KPIs
	 */
	KPIs: KpIsItem[] | null
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * 
The campaign primary goal
	 */
	campaign_goal: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
The campaign KPIs count
	 */
	campaign_kpis_count: number | null
	/**
	 * The the current status of campaign that is being shown. Samples: "Draft", "In Progress"
	 */
	campaign_status: string | null
	/**
	 * 
The campaign type
	 */
	campaign_type: string | null
	/**
	 * 
The country where the campaign will act
	 */
	country: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * The campaign end date
	 */
	end_date: string | null
	/**
	 * 
The Manufacture ID the campaign is for
	 */
	manufacturer_id: string | null
	/**
	 * 
The Manufacture name the campaign is for
	 */
	manufacturer_name: string | null
	/**
	 * The end date for the package that is being shown
	 */
	package_end_date: string | null
	/**
	 * The id for the package that is being shown
	 */
	package_id: string | null
	/**
	 * The start date for the package that is being shown
	 */
	package_start_date: string | null
	/**
	 * The partner name for the package that is being shown. If there is no package associated, then null
	 */
	partner_name: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * The campaign start date
	 */
	start_date: string | null
	/**
	 * 
The Vendor ID the campaign is for
	 */
	vendor_id: string | null
	/**
	 * The Vendor name the campaign is for
	 */
	vendor_name: string | null
}
export interface AddPersonalizationClicked {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * The field where the action was taken. Ex: Notification title, Body
	 */
	field: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface AddPersonalizationConfirmed {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * The personalization custom value the user has chosen
	 */
	custom_value: string | null
	/**
	 * The personalization default value the user has set
	 */
	default_value: string | null
	/**
	 * The field where the action was taken. Ex: Notification title, Body
	 */
	field: string | null
	/**
	 * The preview including the liquid logic statement
	 */
	preview: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface AddPersonalizationExited {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * The field where the action was taken. Ex: Notification title, Body
	 */
	field: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface AdsPackageConnectionInteraction {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * If the toggle is on or off
	 */
	toggle_status: string | null
}
export interface AdsPackageSelected {
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * The end date for the package that is being shown
	 */
	package_end_date: string | null
	/**
	 * The id for the package that is being shown
	 */
	package_id: string | null
	/**
	 * The start date for the package that is being shown
	 */
	package_start_date: string | null
	/**
	 * The partner name for the package that is being shown. If there is no package associated, then null
	 */
	partner_name: string | null
}
export interface BackwardStepperNavigation {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface CampaignAudiencesPageViewed {
	/**
	 * The ID Key for the campaign being created
	 */
	campaign_id: string | null
	/**
	 * 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
	 */
	referrer: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface CampaignContentPageViewed {
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
	 */
	referrer: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface KpIsItem1 {
	/**
	 * The campaign KPIs
	 */
	campaign_KPIs: string | null
}
export interface CampaignCreationFinished {
	/**
	 * 
The campaign KPIs
	 */
	KPIs: KpIsItem1[] | null
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * 
The campaign KPIs count
	 */
	campaign_KPIs_count: number | null
	/**
	 * 
The campaign primary goal
	 */
	campaign_goal: string | null
	/**
	 * 
The campaign type
	 */
	campaign_type: string | null
	/**
	 * 
The country where the campaign will act
	 */
	country: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * The campaign end date
	 */
	end_date: string | null
	/**
	 * 
The Manufacture ID the campaign is for
	 */
	manufacturer_id: string | null
	/**
	 * 
The Manufacture name the campaign is for
	 */
	manufacturer_name: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * The campaign start date
	 */
	start_date: string | null
	/**
	 * 
The Vendor ID the campaign is for
	 */
	vendor_id: string | null
	/**
	 * The Vendor name the campaign is for
	 */
	vendor_name: string | null
}
export interface KpIsItem2 {
	/**
	 * The campaign KPIs
	 */
	campaign_KPIs: string | null
}
export interface CampaignCreationFinishedV2 {
	/**
	 * 
The campaign KPIs
	 */
	KPIs: KpIsItem2[] | null
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * 
The campaign KPIs count
	 */
	campaign_KPIs_count: number | null
	/**
	 * 
The campaign primary goal
	 */
	campaign_goal: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
The campaign type
	 */
	campaign_type: string | null
	/**
	 * 
The country where the campaign will act
	 */
	country: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * The campaign end date
	 */
	end_date: string | null
	/**
	 * 
The Manufacture ID the campaign is for
	 */
	manufacturer_id: string | null
	/**
	 * 
The Manufacture name the campaign is for
	 */
	manufacturer_name: string | null
	/**
	 * The end date for the package that is being shown
	 */
	package_end_date: string | null
	/**
	 * The id for the package that is being shown
	 */
	package_id: string | null
	/**
	 * The start date for the package that is being shown
	 */
	package_start_date: string | null
	/**
	 * The partner name for the package that is being shown. If there is no package associated, then null
	 */
	partner_name: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * The campaign start date
	 */
	start_date: string | null
	/**
	 * 
The Vendor ID the campaign is for
	 */
	vendor_id: string | null
	/**
	 * The Vendor name the campaign is for
	 */
	vendor_name: string | null
}
export interface CampaignCreationStarted {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
}
export interface KpIsItem3 {
	/**
	 * The campaign KPIs
	 */
	campaign_KPIs: string | null
}
export interface CampaignDetailsPageViewed {
	/**
	 * 
The campaign KPIs
	 */
	KPIs: KpIsItem3[] | null
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * 
The campaign KPIs count
	 */
	campaign_KPIs_count: number | null
	/**
	 * 
The campaign primary goal
	 */
	campaign_goal: string | null
	/**
	 * The ID Key for the campaign being created
	 */
	campaign_id: string | null
	/**
	 * 
The campaign type
	 */
	campaign_type: string | null
	/**
	 * 
The country where the campaign will act
	 */
	country: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * The campaign end date
	 */
	end_date: string | null
	/**
	 * 
The Manufacture ID the campaign is for
	 */
	manufacturer_id: string | null
	/**
	 * 
The Manufacture name the campaign is for
	 */
	manufacturer_name: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * The campaign start date
	 */
	start_date: string | null
	/**
	 * 
The Vendor ID the campaign is for
	 */
	vendor_id: string | null
	/**
	 * The Vendor name the campaign is for
	 */
	vendor_name: string | null
}
export interface KpIsItem4 {
	/**
	 * The campaign KPIs
	 */
	campaign_KPIs: string | null
}
export interface CampaignDetailsPageViewedV2 {
	/**
	 * 
The campaign KPIs
	 */
	KPIs: KpIsItem4[] | null
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * 
The campaign KPIs count
	 */
	campaign_KPIs_count: number | null
	/**
	 * 
The campaign primary goal
	 */
	campaign_goal: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
The campaign type
	 */
	campaign_type: string | null
	/**
	 * 
The country where the campaign will act
	 */
	country: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * The campaign end date
	 */
	end_date: string | null
	/**
	 * 
The Manufacture ID the campaign is for
	 */
	manufacturer_id: string | null
	/**
	 * 
The Manufacture name the campaign is for
	 */
	manufacturer_name: string | null
	/**
	 * The end date for the package that is being shown
	 */
	package_end_date: string | null
	/**
	 * The id for the package that is being shown
	 */
	package_id: string | null
	/**
	 * The start date for the package that is being shown
	 */
	package_start_date: string | null
	/**
	 * The partner name for the package that is being shown. If there is no package associated, then null
	 */
	partner_name: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * The campaign start date
	 */
	start_date: string | null
	/**
	 * 
The Vendor ID the campaign is for
	 */
	vendor_id: string | null
	/**
	 * The Vendor name the campaign is for
	 */
	vendor_name: string | null
}
export interface KpIsItem5 {
	/**
	 * The campaign KPIs
	 */
	campaign_KPIs: string | null
}
export interface CampaignDuplicateCreationStarted {
	/**
	 * 
The campaign KPIs
	 */
	KPIs: KpIsItem5[] | null
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * 
The campaign KPIs count
	 */
	campaign_KPIs_count: number | null
	/**
	 * 
The campaign primary goal
	 */
	campaign_goal: string | null
	/**
	 * The ID Key for the campaign being created
	 */
	campaign_id: string | null
	/**
	 * 
The campaign type
	 */
	campaign_type: string | null
	/**
	 * 
The country where the campaign will act
	 */
	country: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * The campaign end date
	 */
	end_date: string | null
	/**
	 * 
The Manufacture ID the campaign is for
	 */
	manufacturer_id: string | null
	/**
	 * 
The Manufacture name the campaign is for
	 */
	manufacturer_name: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
The ID Key for the original campaign (the one that was duplicated)
	 */
	source_campaign_id: string | null
	/**
	 * 
The original campaign (the one that was duplicated) status
	 */
	source_campaign_status: string | null
	/**
	 * The campaign start date
	 */
	start_date: string | null
	/**
	 * 
The Vendor ID the campaign is for
	 */
	vendor_id: string | null
	/**
	 * The Vendor name the campaign is for
	 */
	vendor_name: string | null
}
export interface KpIsItem6 {
	/**
	 * The campaign KPIs
	 */
	campaign_KPIs: string | null
}
export interface CampaignEditionStarted {
	/**
	 * 
The campaign KPIs
	 */
	KPIs: KpIsItem6[] | null
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * 
The campaign KPIs count
	 */
	campaign_KPIs_count: number | null
	/**
	 * 
The campaign primary goal
	 */
	campaign_goal: string | null
	/**
	 * The ID Key for the campaign being created
	 */
	campaign_id: string | null
	/**
	 * 
The campaign type
	 */
	campaign_type: string | null
	/**
	 * 
The country where the campaign will act
	 */
	country: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * The campaign end date
	 */
	end_date: string | null
	/**
	 * 
The Manufacture ID the campaign is for
	 */
	manufacturer_id: string | null
	/**
	 * 
The Manufacture name the campaign is for
	 */
	manufacturer_name: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * The campaign start date
	 */
	start_date: string | null
	/**
	 * 
The Vendor ID the campaign is for
	 */
	vendor_id: string | null
	/**
	 * The Vendor name the campaign is for
	 */
	vendor_name: string | null
}
export interface KpIsItem7 {
	/**
	 * The campaign KPIs
	 */
	campaign_KPIs: string | null
}
export interface CampaignEditionStartedV2 {
	/**
	 * 
The campaign KPIs
	 */
	KPIs: KpIsItem7[] | null
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * 
The campaign KPIs count
	 */
	campaign_KPIs_count: number | null
	/**
	 * 
The campaign primary goal
	 */
	campaign_goal: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
The campaign type
	 */
	campaign_type: string | null
	/**
	 * 
The country where the campaign will act
	 */
	country: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * The campaign end date
	 */
	end_date: string | null
	/**
	 * 
The Manufacture ID the campaign is for
	 */
	manufacturer_id: string | null
	/**
	 * 
The Manufacture name the campaign is for
	 */
	manufacturer_name: string | null
	/**
	 * The end date for the package that is being shown
	 */
	package_end_date: string | null
	/**
	 * The id for the package that is being shown
	 */
	package_id: string | null
	/**
	 * The start date for the package that is being shown
	 */
	package_start_date: string | null
	/**
	 * The partner name for the package that is being shown. If there is no package associated, then null
	 */
	partner_name: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * The campaign start date
	 */
	start_date: string | null
	/**
	 * 
The Vendor ID the campaign is for
	 */
	vendor_id: string | null
	/**
	 * The Vendor name the campaign is for
	 */
	vendor_name: string | null
}
export interface CampaignFilterApplied {
	/**
	 * Package association filtering option selected (none, 'Ads package connected', or 'No ads package')
	 */
	ads_package_filter: string | null
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * 
The total number of campaigns on the user's campaign dashboard
	 */
	campaign_count: number | null
	/**
	 * 
Campaing types selected for the filter concatenated ("Drive Purchace, Drive behavior")
	 */
	campaing_type_filters: string | null
	/**
	 * The campaign end date
	 */
	end_date: string | null
	/**
	 * 
End date selected for the filter
	 */
	end_date_filter: string
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * The campaign start date
	 */
	start_date: string | null
	/**
	 * 
Start date selected for the filter
	 */
	start_date_filter: string | null
	/**
	 * 
Status selected for the filter concatenated ("Drive Purchace, Drive behavior")
	 */
	status_filter: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface CampaignListingPageViewed {
	/**
	 * 
The total number of campaigns on the user's campaign dashboard
	 */
	campaign_count: number | null
	/**
	 * 
The count of campaigns actually being shown
	 */
	page_item_count: number | null
	/**
	 * The total quantity of pages. E.g.: "1" (current_page) of "10" (pages_quantity)
	 */
	pages_quantity: number | null
	/**
	 * 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
	 */
	referrer: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface CampaignPrimaryGoalPageViewed {
	/**
	 * 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
	 */
	referrer: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface CampaignSeeAnalyticsClicked {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
}
export interface CampaignSettingsPageViewed {
	/**
	 * The ID Key for the campaign being created
	 */
	campaign_id: string | null
	/**
	 * 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
	 */
	referrer: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface CampaignSummaryPageViewed {
	/**
	 * The ID Key for the campaign being created
	 */
	campaign_id: string | null
	/**
	 * 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
	 */
	referrer: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface CampaignSummaryPageViewedV2 {
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * The end date for the package that is being shown
	 */
	package_end_date: string | null
	/**
	 * The id for the package that is being shown
	 */
	package_id: string | null
	/**
	 * The start date for the package that is being shown
	 */
	package_start_date: string | null
	/**
	 * The partner name for the package that is being shown. If there is no package associated, then null
	 */
	partner_name: string | null
	/**
	 * 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
	 */
	referrer: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface CampaignTouchpointsPageViewed {
	/**
	 * The ID Key for the campaign being created
	 */
	campaign_id: string | null
	/**
	 * 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
	 */
	referrer: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface CampaignTypePageViewed {
	/**
	 * 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
	 */
	referrer: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface DownloadPdfButtonClicked {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * The the current status of campaign that is being shown. Samples: "Draft", "In Progress"
	 */
	campaign_status: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * The campaign end date
	 */
	end_date: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * The campaign start date
	 */
	start_date: string | null
}
export interface ExitStepperNavigation {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface ForwardStepperNavigation {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
	 */
	destination_url: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface ItemsPerPageChange {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The Items count option the chose
	 */
	new_items_count: number | null
	/**
	 * The Items count option the user changed
	 */
	previous_items_count: number | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface MenuInteraction {
	/**
	 * Top level Menu Category in the given context, always in English. Samples:  Dashboard, Report_orders, Report_Customer.
	 */
	menu_category: string | null
	/**
	 * 
What is the link of the page when clicks in the menu. 
	 */
	menu_link: string | null
	/**
	 * The Sub Category of the menu in the given context. If the Menu does not have a subcategory, log null. Samples: Orders, Customer, Behaviour.
	 */
	menu_subcategory: string | null
}
export interface NoSkUsModalConfirmation {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface NoSkUsModalTurnBack {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface PdfDownloadStatus {
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * The the current status of campaign that is being shown. Samples: "Draft", "In Progress"
	 */
	campaign_status: string | null
	/**
	 * The status of the document download request. Samples: "Success", "Fail"
	 */
	download_status: string | null
	/**
	 * The type of error that led to na error event. If status is Success, then error_type is null.
	 */
	error_type: string | null
	/**
	 * The size of the file the user choices generated in KB. If Status is Error, then file_size is null
	 */
	file_size: number | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface SearchInteraction {
	/**
	 * 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
	 */
	referrer: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * The text the user searched for
	 */
	search: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface SortInteraction {
	/**
	 * The header label displayed to the user. Samples:'Campaign Name", "Nome da Campanha"
	 */
	header_label: string | null
	/**
	 * The header name. Samples: Status, Campaign Name
	 */
	header_name: string | null
	/**
	 * 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
	 */
	referrer: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * The type of sorting the user is performing: ASCENDING, DESCENDING or DEFAULT
	 */
	sort_type: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface UploadNewBannerClicked {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}
export interface VendorChange {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
	/**
	 * The Vendor name the campaign is for
	 */
	vendor_name: string | null
}
export interface ViewAllTargetKpIsClicked {
	/**
	 * The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
	 */
	button_label?: string | null
	/**
	 * 
The button name. Samples: See Analytics
	 */
	button_name: string | null
	/**
	 * The campaign id
	 */
	campaign_id: string | null
	/**
	 * 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
	 */
	screen_name: string | null
	/**
	 * 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
	 */
	url: string | null
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: Ajv.ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
	onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
	const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true })
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'))

	if (!ajv.validate(schema, message) && ajv.errors) {
		onViolation(message, ajv.errors)
	}
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef KpIsItem
 * @property {string | null} [campaign_kpis] - The campaign KPIs
 */
/**
 * @typedef ActionInteraction
 * @property {KpIsItem[] | null} KPIs - 
The campaign KPIs
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_goal - 
The campaign primary goal
 * @property {string | null} campaign_id - The campaign id
 * @property {number | null} campaign_kpis_count - 
The campaign KPIs count
 * @property {string | null} campaign_status - The the current status of campaign that is being shown. Samples: "Draft", "In Progress"
 * @property {string | null} campaign_type - 
The campaign type
 * @property {string | null} country - 
The country where the campaign will act
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} end_date - The campaign end date
 * @property {string | null} manufacturer_id - 
The Manufacture ID the campaign is for
 * @property {string | null} manufacturer_name - 
The Manufacture name the campaign is for
 * @property {string | null} package_end_date - The end date for the package that is being shown
 * @property {string | null} package_id - The id for the package that is being shown
 * @property {string | null} package_start_date - The start date for the package that is being shown
 * @property {string | null} partner_name - The partner name for the package that is being shown. If there is no package associated, then null
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} start_date - The campaign start date
 * @property {string | null} vendor_id - 
The Vendor ID the campaign is for
 * @property {string | null} vendor_name - The Vendor name the campaign is for
 */
/**
 * @typedef AddPersonalizationClicked
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} field - The field where the action was taken. Ex: Notification title, Body
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef AddPersonalizationConfirmed
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} custom_value - The personalization custom value the user has chosen
 * @property {string | null} default_value - The personalization default value the user has set
 * @property {string | null} field - The field where the action was taken. Ex: Notification title, Body
 * @property {string | null} preview - The preview including the liquid logic statement
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef AddPersonalizationExited
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} field - The field where the action was taken. Ex: Notification title, Body
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef AdsPackageConnectionInteraction
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} toggle_status - If the toggle is on or off
 */
/**
 * @typedef AdsPackageSelected
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} package_end_date - The end date for the package that is being shown
 * @property {string | null} package_id - The id for the package that is being shown
 * @property {string | null} package_start_date - The start date for the package that is being shown
 * @property {string | null} partner_name - The partner name for the package that is being shown. If there is no package associated, then null
 */
/**
 * @typedef BackwardStepperNavigation
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef CampaignAudiencesPageViewed
 * @property {string | null} campaign_id - The ID Key for the campaign being created
 * @property {string | null} referrer - 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef CampaignContentPageViewed
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} referrer - 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef KpIsItem1
 * @property {string | null} campaign_KPIs - The campaign KPIs
 */
/**
 * @typedef CampaignCreationFinished
 * @property {KpIsItem1[] | null} KPIs - 
The campaign KPIs
 * @property {string | null} button_label - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {number | null} campaign_KPIs_count - 
The campaign KPIs count
 * @property {string | null} campaign_goal - 
The campaign primary goal
 * @property {string | null} campaign_type - 
The campaign type
 * @property {string | null} country - 
The country where the campaign will act
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} end_date - The campaign end date
 * @property {string | null} manufacturer_id - 
The Manufacture ID the campaign is for
 * @property {string | null} manufacturer_name - 
The Manufacture name the campaign is for
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} start_date - The campaign start date
 * @property {string | null} vendor_id - 
The Vendor ID the campaign is for
 * @property {string | null} vendor_name - The Vendor name the campaign is for
 */
/**
 * @typedef KpIsItem2
 * @property {string | null} campaign_KPIs - The campaign KPIs
 */
/**
 * @typedef CampaignCreationFinishedV2
 * @property {KpIsItem2[] | null} KPIs - 
The campaign KPIs
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {number | null} campaign_KPIs_count - 
The campaign KPIs count
 * @property {string | null} campaign_goal - 
The campaign primary goal
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} campaign_type - 
The campaign type
 * @property {string | null} country - 
The country where the campaign will act
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} end_date - The campaign end date
 * @property {string | null} manufacturer_id - 
The Manufacture ID the campaign is for
 * @property {string | null} manufacturer_name - 
The Manufacture name the campaign is for
 * @property {string | null} package_end_date - The end date for the package that is being shown
 * @property {string | null} package_id - The id for the package that is being shown
 * @property {string | null} package_start_date - The start date for the package that is being shown
 * @property {string | null} partner_name - The partner name for the package that is being shown. If there is no package associated, then null
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} start_date - The campaign start date
 * @property {string | null} vendor_id - 
The Vendor ID the campaign is for
 * @property {string | null} vendor_name - The Vendor name the campaign is for
 */
/**
 * @typedef CampaignCreationStarted
 * @property {string | null} button_label - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 */
/**
 * @typedef KpIsItem3
 * @property {string | null} campaign_KPIs - The campaign KPIs
 */
/**
 * @typedef CampaignDetailsPageViewed
 * @property {KpIsItem3[] | null} KPIs - 
The campaign KPIs
 * @property {string | null} button_label - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {number | null} campaign_KPIs_count - 
The campaign KPIs count
 * @property {string | null} campaign_goal - 
The campaign primary goal
 * @property {string | null} campaign_id - The ID Key for the campaign being created
 * @property {string | null} campaign_type - 
The campaign type
 * @property {string | null} country - 
The country where the campaign will act
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} end_date - The campaign end date
 * @property {string | null} manufacturer_id - 
The Manufacture ID the campaign is for
 * @property {string | null} manufacturer_name - 
The Manufacture name the campaign is for
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} start_date - The campaign start date
 * @property {string | null} vendor_id - 
The Vendor ID the campaign is for
 * @property {string | null} vendor_name - The Vendor name the campaign is for
 */
/**
 * @typedef KpIsItem4
 * @property {string | null} campaign_KPIs - The campaign KPIs
 */
/**
 * @typedef CampaignDetailsPageViewedV2
 * @property {KpIsItem4[] | null} KPIs - 
The campaign KPIs
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {number | null} campaign_KPIs_count - 
The campaign KPIs count
 * @property {string | null} campaign_goal - 
The campaign primary goal
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} campaign_type - 
The campaign type
 * @property {string | null} country - 
The country where the campaign will act
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} end_date - The campaign end date
 * @property {string | null} manufacturer_id - 
The Manufacture ID the campaign is for
 * @property {string | null} manufacturer_name - 
The Manufacture name the campaign is for
 * @property {string | null} package_end_date - The end date for the package that is being shown
 * @property {string | null} package_id - The id for the package that is being shown
 * @property {string | null} package_start_date - The start date for the package that is being shown
 * @property {string | null} partner_name - The partner name for the package that is being shown. If there is no package associated, then null
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} start_date - The campaign start date
 * @property {string | null} vendor_id - 
The Vendor ID the campaign is for
 * @property {string | null} vendor_name - The Vendor name the campaign is for
 */
/**
 * @typedef KpIsItem5
 * @property {string | null} campaign_KPIs - The campaign KPIs
 */
/**
 * @typedef CampaignDuplicateCreationStarted
 * @property {KpIsItem5[] | null} KPIs - 
The campaign KPIs
 * @property {string | null} button_label - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {number | null} campaign_KPIs_count - 
The campaign KPIs count
 * @property {string | null} campaign_goal - 
The campaign primary goal
 * @property {string | null} campaign_id - The ID Key for the campaign being created
 * @property {string | null} campaign_type - 
The campaign type
 * @property {string | null} country - 
The country where the campaign will act
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} end_date - The campaign end date
 * @property {string | null} manufacturer_id - 
The Manufacture ID the campaign is for
 * @property {string | null} manufacturer_name - 
The Manufacture name the campaign is for
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} source_campaign_id - 
The ID Key for the original campaign (the one that was duplicated)
 * @property {string | null} source_campaign_status - 
The original campaign (the one that was duplicated) status
 * @property {string | null} start_date - The campaign start date
 * @property {string | null} vendor_id - 
The Vendor ID the campaign is for
 * @property {string | null} vendor_name - The Vendor name the campaign is for
 */
/**
 * @typedef KpIsItem6
 * @property {string | null} campaign_KPIs - The campaign KPIs
 */
/**
 * @typedef CampaignEditionStarted
 * @property {KpIsItem6[] | null} KPIs - 
The campaign KPIs
 * @property {string | null} button_label - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {number | null} campaign_KPIs_count - 
The campaign KPIs count
 * @property {string | null} campaign_goal - 
The campaign primary goal
 * @property {string | null} campaign_id - The ID Key for the campaign being created
 * @property {string | null} campaign_type - 
The campaign type
 * @property {string | null} country - 
The country where the campaign will act
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} end_date - The campaign end date
 * @property {string | null} manufacturer_id - 
The Manufacture ID the campaign is for
 * @property {string | null} manufacturer_name - 
The Manufacture name the campaign is for
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} start_date - The campaign start date
 * @property {string | null} vendor_id - 
The Vendor ID the campaign is for
 * @property {string | null} vendor_name - The Vendor name the campaign is for
 */
/**
 * @typedef KpIsItem7
 * @property {string | null} campaign_KPIs - The campaign KPIs
 */
/**
 * @typedef CampaignEditionStartedV2
 * @property {KpIsItem7[] | null} KPIs - 
The campaign KPIs
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {number | null} campaign_KPIs_count - 
The campaign KPIs count
 * @property {string | null} campaign_goal - 
The campaign primary goal
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} campaign_type - 
The campaign type
 * @property {string | null} country - 
The country where the campaign will act
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} end_date - The campaign end date
 * @property {string | null} manufacturer_id - 
The Manufacture ID the campaign is for
 * @property {string | null} manufacturer_name - 
The Manufacture name the campaign is for
 * @property {string | null} package_end_date - The end date for the package that is being shown
 * @property {string | null} package_id - The id for the package that is being shown
 * @property {string | null} package_start_date - The start date for the package that is being shown
 * @property {string | null} partner_name - The partner name for the package that is being shown. If there is no package associated, then null
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} start_date - The campaign start date
 * @property {string | null} vendor_id - 
The Vendor ID the campaign is for
 * @property {string | null} vendor_name - The Vendor name the campaign is for
 */
/**
 * @typedef CampaignFilterApplied
 * @property {string | null} ads_package_filter - Package association filtering option selected (none, 'Ads package connected', or 'No ads package')
 * @property {string | null} button_label - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {number | null} campaign_count - 
The total number of campaigns on the user's campaign dashboard
 * @property {string | null} campaing_type_filters - 
Campaing types selected for the filter concatenated ("Drive Purchace, Drive behavior")
 * @property {string | null} end_date - The campaign end date
 * @property {string} end_date_filter - 
End date selected for the filter
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} start_date - The campaign start date
 * @property {string | null} start_date_filter - 
Start date selected for the filter
 * @property {string | null} status_filter - 
Status selected for the filter concatenated ("Drive Purchace, Drive behavior")
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef CampaignListingPageViewed
 * @property {number | null} campaign_count - 
The total number of campaigns on the user's campaign dashboard
 * @property {number | null} page_item_count - 
The count of campaigns actually being shown
 * @property {number | null} pages_quantity - The total quantity of pages. E.g.: "1" (current_page) of "10" (pages_quantity)
 * @property {string | null} referrer - 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef CampaignPrimaryGoalPageViewed
 * @property {string | null} referrer - 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef CampaignSeeAnalyticsClicked
 * @property {string | null} button_label - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 */
/**
 * @typedef CampaignSettingsPageViewed
 * @property {string | null} campaign_id - The ID Key for the campaign being created
 * @property {string | null} referrer - 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef CampaignSummaryPageViewed
 * @property {string | null} campaign_id - The ID Key for the campaign being created
 * @property {string | null} referrer - 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef CampaignSummaryPageViewedV2
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} package_end_date - The end date for the package that is being shown
 * @property {string | null} package_id - The id for the package that is being shown
 * @property {string | null} package_start_date - The start date for the package that is being shown
 * @property {string | null} partner_name - The partner name for the package that is being shown. If there is no package associated, then null
 * @property {string | null} referrer - 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef CampaignTouchpointsPageViewed
 * @property {string | null} campaign_id - The ID Key for the campaign being created
 * @property {string | null} referrer - 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef CampaignTypePageViewed
 * @property {string | null} referrer - 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef DownloadPdfButtonClicked
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} campaign_status - The the current status of campaign that is being shown. Samples: "Draft", "In Progress"
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} end_date - The campaign end date
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} start_date - The campaign start date
 */
/**
 * @typedef ExitStepperNavigation
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef ForwardStepperNavigation
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} destination_url - 
Url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef ItemsPerPageChange
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {number | null} new_items_count - The Items count option the chose
 * @property {number | null} previous_items_count - The Items count option the user changed
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef MenuInteraction
 * @property {string | null} menu_category - Top level Menu Category in the given context, always in English. Samples:  Dashboard, Report_orders, Report_Customer. 
 * @property {string | null} menu_link - 
What is the link of the page when clicks in the menu. 
 * @property {string | null} menu_subcategory - The Sub Category of the menu in the given context. If the Menu does not have a subcategory, log null. Samples: Orders, Customer, Behaviour. 
 */
/**
 * @typedef NoSkUsModalConfirmation
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef NoSkUsModalTurnBack
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef PdfDownloadStatus
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} campaign_status - The the current status of campaign that is being shown. Samples: "Draft", "In Progress"
 * @property {string | null} download_status - The status of the document download request. Samples: "Success", "Fail"
 * @property {string | null} error_type - The type of error that led to na error event. If status is Success, then error_type is null.
 * @property {number | null} file_size - The size of the file the user choices generated in KB. If Status is Error, then file_size is null
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef SearchInteraction
 * @property {string | null} referrer - 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} search - The text the user searched for
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef SortInteraction
 * @property {string | null} header_label - The header label displayed to the user. Samples:'Campaign Name", "Nome da Campanha"
 * @property {string | null} header_name - The header name. Samples: Status, Campaign Name
 * @property {string | null} referrer - 
Full URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} sort_type - The type of sorting the user is performing: ASCENDING, DESCENDING or DEFAULT
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef UploadNewBannerClicked
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */
/**
 * @typedef VendorChange
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 * @property {string | null} vendor_name - The Vendor name the campaign is for
 */
/**
 * @typedef ViewAllTargetKpIsClicked
 * @property {string | null} [button_label] - The button label displayed to the user. Samples:'See Analytics', 'Ver análises''
 * @property {string | null} button_name - 
The button name. Samples: See Analytics
 * @property {string | null} campaign_id - The campaign id
 * @property {string | null} screen_name - 
The name of the screen where the user is taking this action. Samples: Campaign Dashboard Page
 * @property {string | null} url - 
Full URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...
 */

/**
 * Triggered when the user clicks on a action at the actions menu (...)















 *
 * @param {ActionInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function actionInteraction(
	props: ActionInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on a action at the actions menu (...)\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					KPIs: {
						description: '\nThe campaign KPIs',
						id: '/properties/properties/properties/KPIs',
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array\n",
							id: '/properties/properties/properties/KPIs/items',
							properties: {
								campaign_kpis: {
									description: 'The campaign KPIs',
									id:
										'/properties/properties/properties/KPIs/items/properties/campaign_kpis',
									type: ['string', 'null'],
								},
							},
							required: ['campaign_KPIs'],
							type: ['object', 'null'],
						},
						type: ['array', 'null'],
					},
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_goal: {
						description: '\nThe campaign primary goal',
						id: '/properties/properties/properties/campaign_goal',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					campaign_kpis_count: {
						description: '\nThe campaign KPIs count',
						id: '/properties/properties/properties/campaign_kpis_count',
						type: ['integer', 'null'],
					},
					campaign_status: {
						description:
							'The the current status of campaign that is being shown. Samples: "Draft", "In Progress"',
						id: '/properties/properties/properties/campaign_status',
						type: ['string', 'null'],
					},
					campaign_type: {
						description: '\nThe campaign type',
						id: '/properties/properties/properties/campaign_type',
						type: ['string', 'null'],
					},
					country: {
						description: '\nThe country where the campaign will act',
						id: '/properties/properties/properties/country',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					end_date: {
						description: 'The campaign end date',
						id: '/properties/properties/properties/end_date',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description: '\nThe Manufacture ID the campaign is for',
						id: '/properties/properties/properties/manufacturer_id',
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description: '\nThe Manufacture name the campaign is for',
						id: '/properties/properties/properties/manufacturer_name',
						type: ['string', 'null'],
					},
					package_end_date: {
						description: 'The end date for the package that is being shown',
						id: '/properties/properties/properties/package_end_date',
						type: ['string', 'null'],
					},
					package_id: {
						description: 'The id for the package that is being shown',
						id: '/properties/properties/properties/package_id',
						type: ['string', 'null'],
					},
					package_start_date: {
						description: 'The start date for the package that is being shown',
						id: '/properties/properties/properties/package_start_date',
						type: ['string', 'null'],
					},
					partner_name: {
						description:
							'The partner name for the package that is being shown. If there is no package associated, then null',
						id: '/properties/properties/properties/partner_name',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					start_date: {
						description: 'The campaign start date',
						id: '/properties/properties/properties/start_date',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: '\nThe Vendor ID the campaign is for',
						id: '/properties/properties/properties/vendor_id',
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The Vendor name the campaign is for',
						id: '/properties/properties/properties/vendor_name',
						type: ['string', 'null'],
					},
				},
				required: [
					'KPIs',
					'button_name',
					'campaign_goal',
					'campaign_id',
					'campaign_kpis_count',
					'campaign_status',
					'campaign_type',
					'country',
					'destination_url',
					'end_date',
					'manufacturer_id',
					'manufacturer_name',
					'package_end_date',
					'package_id',
					'package_start_date',
					'partner_name',
					'screen_name',
					'start_date',
					'vendor_id',
					'vendor_name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Action Interaction',
		type: 'object',
	}
	const message = {
		event: 'Action Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Action Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "+" button at the fields while configuring a Push notification touchpoint





 *
 * @param {AddPersonalizationClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addPersonalizationClicked(
	props?: AddPersonalizationClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "+" button at the fields while configuring a Push notification touchpoint\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					field: {
						description:
							'The field where the action was taken. Ex: Notification title, Body',
						id: '/properties/properties/properties/field',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['url', 'button_name', 'campaign_id', 'field', 'screen_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Add Personalization Clicked',
		type: 'object',
	}
	const message = {
		event: 'Add Personalization Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Add Personalization Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "Confirm" button at the Add pesonalization modal while configuring a Push notification touchpoint








 *
 * @param {AddPersonalizationConfirmed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addPersonalizationConfirmed(
	props?: AddPersonalizationConfirmed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "Confirm" button at the Add pesonalization modal while configuring a Push notification touchpoint\n\n\n\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					custom_value: {
						description: 'The personalization custom value the user has chosen',
						id: '/properties/properties/properties/custom_value',
						type: ['string', 'null'],
					},
					default_value: {
						description: 'The personalization default value the user has set',
						id: '/properties/properties/properties/default_value',
						type: ['string', 'null'],
					},
					field: {
						description:
							'The field where the action was taken. Ex: Notification title, Body',
						id: '/properties/properties/properties/field',
						type: ['string', 'null'],
					},
					preview: {
						description: 'The preview including the liquid logic statement',
						id: '/properties/properties/properties/preview',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'url',
					'button_name',
					'campaign_id',
					'custom_value',
					'default_value',
					'field',
					'preview',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Add Personalization Confirmed',
		type: 'object',
	}
	const message = {
		event: 'Add Personalization Confirmed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Add Personalization Confirmed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "Go back" or the "X" button at the Add pesonalization modal while configuring a Push notification touchpoint





 *
 * @param {AddPersonalizationExited} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addPersonalizationExited(
	props?: AddPersonalizationExited,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "Go back" or the "X" button at the Add pesonalization modal while configuring a Push notification touchpoint\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					field: {
						description:
							'The field where the action was taken. Ex: Notification title, Body',
						id: '/properties/properties/properties/field',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['url', 'button_name', 'campaign_id', 'field', 'screen_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Add Personalization Exited',
		type: 'object',
	}
	const message = {
		event: 'Add Personalization Exited',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Add Personalization Exited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks in the ‘Connect’ button to associate an ads package



 *
 * @param {AdsPackageConnectionInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adsPackageConnectionInteraction(
	props?: AdsPackageConnectionInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks in the ‘Connect’ button to associate an ads package\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					toggle_status: {
						description: 'If the toggle is on or off',
						enum: ['NO', 'YES', null],
						id: '/properties/properties/properties/toggle_status',
						type: ['string', 'null'],
					},
				},
				required: ['campaign_id', 'button_name', 'toggle_status'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Ads Package Connection Interaction',
		type: 'object',
	}
	const message = {
		event: 'Ads Package Connection Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Ads Package Connection Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks in a package





 *
 * @param {AdsPackageSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adsPackageSelected(
	props?: AdsPackageSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks in a package\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					package_end_date: {
						description: 'The end date for the package that is being shown',
						id: '/properties/properties/properties/package_end_date',
						type: ['string', 'null'],
					},
					package_id: {
						description: 'The id for the package that is being shown',
						id: '/properties/properties/properties/package_id',
						type: ['string', 'null'],
					},
					package_start_date: {
						description: 'The start date for the package that is being shown',
						id: '/properties/properties/properties/package_start_date',
						type: ['string', 'null'],
					},
					partner_name: {
						description:
							'The partner name for the package that is being shown. If there is no package associated, then null',
						id: '/properties/properties/properties/partner_name',
						type: ['string', 'null'],
					},
				},
				required: [
					'package_start_date',
					'package_end_date',
					'campaign_id',
					'package_id',
					'partner_name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Ads Package Selected',
		type: 'object',
	}
	const message = {
		event: 'Ads Package Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Ads Package Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on a button that moves him backwards on the creation flow





 *
 * @param {BackwardStepperNavigation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function backwardStepperNavigation(
	props: BackwardStepperNavigation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on a button that moves him backwards on the creation flow\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: [
					'destination_url',
					'button_name',
					'campaign_id',
					'url',
					'screen_name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Backward Stepper Navigation',
		type: 'object',
	}
	const message = {
		event: 'Backward Stepper Navigation',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Backward Stepper Navigation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user loads the Campaign Audiences page on the creation flow
 *
 * @param {CampaignAudiencesPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignAudiencesPageViewed(
	props: CampaignAudiencesPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the user loads the Campaign Audiences page on the creation flow',
		properties: {
			context: {},
			properties: {
				properties: {
					campaign_id: {
						description: 'The ID Key for the campaign being created',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					referrer: {
						description:
							'\nFull URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property',
						id: '/properties/properties/properties/referrer',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['url', 'campaign_id', 'screen_name', 'referrer'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Audiences Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Campaign Audiences Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Audiences Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user loads the Campaign type page on the creation flow


 *
 * @param {CampaignContentPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignContentPageViewed(
	props: CampaignContentPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user loads the Campaign type page on the creation flow\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					referrer: {
						description:
							'\nFull URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property',
						id: '/properties/properties/properties/referrer',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['screen_name', 'campaign_id', 'referrer', 'url'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Content Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Campaign Content Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Content Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user publishes a campaign by clicking on the "Publish" button on the Summary page
 *
 * @param {CampaignCreationFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignCreationFinished(
	props: CampaignCreationFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the user publishes a campaign by clicking on the "Publish" button on the Summary page',
		properties: {
			context: {},
			properties: {
				properties: {
					KPIs: {
						description: '\nThe campaign KPIs',
						id: '/properties/properties/properties/KPIs',
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array\n",
							id: '/properties/properties/properties/KPIs/items',
							properties: {
								campaign_KPIs: {
									description: 'The campaign KPIs',
									id:
										'/properties/properties/properties/KPIs/items/properties/campaign_KPIs',
									type: ['string', 'null'],
								},
							},
							required: ['campaign_KPIs'],
							type: ['object', 'null'],
						},
						type: ['array', 'null'],
					},
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_KPIs_count: {
						description: '\nThe campaign KPIs count',
						id: '/properties/properties/properties/campaign_KPIs_count',
						type: ['integer', 'null'],
					},
					campaign_goal: {
						description: '\nThe campaign primary goal',
						id: '/properties/properties/properties/campaign_goal',
						type: ['string', 'null'],
					},
					campaign_type: {
						description: '\nThe campaign type',
						id: '/properties/properties/properties/campaign_type',
						type: ['string', 'null'],
					},
					country: {
						description: '\nThe country where the campaign will act',
						id: '/properties/properties/properties/country',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					end_date: {
						description: 'The campaign end date',
						id: '/properties/properties/properties/end_date',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description: '\nThe Manufacture ID the campaign is for',
						id: '/properties/properties/properties/manufacturer_id',
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description: '\nThe Manufacture name the campaign is for',
						id: '/properties/properties/properties/manufacturer_name',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					start_date: {
						description: 'The campaign start date',
						id: '/properties/properties/properties/start_date',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: '\nThe Vendor ID the campaign is for',
						id: '/properties/properties/properties/vendor_id',
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The Vendor name the campaign is for',
						id: '/properties/properties/properties/vendor_name',
						type: ['string', 'null'],
					},
				},
				required: [
					'country',
					'destination_url',
					'manufacturer_id',
					'button_name',
					'vendor_id',
					'vendor_name',
					'KPIs',
					'campaign_KPIs_count',
					'campaign_goal',
					'start_date',
					'button_label',
					'end_date',
					'screen_name',
					'manufacturer_name',
					'campaign_type',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Creation Finished',
		type: 'object',
	}
	const message = {
		event: 'Campaign Creation Finished',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Creation Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user publishes a campaign by clicking on the "Publish" button on the Summary page



















 *
 * @param {CampaignCreationFinishedV2} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignCreationFinishedV2(
	props?: CampaignCreationFinishedV2,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user publishes a campaign by clicking on the "Publish" button on the Summary page\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					KPIs: {
						description: '\nThe campaign KPIs',
						id: '/properties/properties/properties/KPIs',
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array\n",
							id: '/properties/properties/properties/KPIs/items',
							properties: {
								campaign_KPIs: {
									description: 'The campaign KPIs',
									id:
										'/properties/properties/properties/KPIs/items/properties/campaign_KPIs',
									type: ['string', 'null'],
								},
							},
							required: ['campaign_KPIs'],
							type: ['object', 'null'],
						},
						type: ['array', 'null'],
					},
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_KPIs_count: {
						description: '\nThe campaign KPIs count',
						id: '/properties/properties/properties/campaign_KPIs_count',
						type: ['integer', 'null'],
					},
					campaign_goal: {
						description: '\nThe campaign primary goal',
						id: '/properties/properties/properties/campaign_goal',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					campaign_type: {
						description: '\nThe campaign type',
						id: '/properties/properties/properties/campaign_type',
						type: ['string', 'null'],
					},
					country: {
						description: '\nThe country where the campaign will act',
						id: '/properties/properties/properties/country',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					end_date: {
						description: 'The campaign end date',
						id: '/properties/properties/properties/end_date',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description: '\nThe Manufacture ID the campaign is for',
						id: '/properties/properties/properties/manufacturer_id',
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description: '\nThe Manufacture name the campaign is for',
						id: '/properties/properties/properties/manufacturer_name',
						type: ['string', 'null'],
					},
					package_end_date: {
						description: 'The end date for the package that is being shown',
						id: '/properties/properties/properties/package_end_date',
						type: ['string', 'null'],
					},
					package_id: {
						description: 'The id for the package that is being shown',
						id: '/properties/properties/properties/package_id',
						type: ['string', 'null'],
					},
					package_start_date: {
						description: 'The start date for the package that is being shown',
						id: '/properties/properties/properties/package_start_date',
						type: ['string', 'null'],
					},
					partner_name: {
						description:
							'The partner name for the package that is being shown. If there is no package associated, then null',
						id: '/properties/properties/properties/partner_name',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					start_date: {
						description: 'The campaign start date',
						id: '/properties/properties/properties/start_date',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: '\nThe Vendor ID the campaign is for',
						id: '/properties/properties/properties/vendor_id',
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The Vendor name the campaign is for',
						id: '/properties/properties/properties/vendor_name',
						type: ['string', 'null'],
					},
				},
				required: [
					'manufacturer_id',
					'screen_name',
					'campaign_KPIs_count',
					'vendor_name',
					'destination_url',
					'partner_name',
					'vendor_id',
					'start_date',
					'package_id',
					'campaign_id',
					'campaign_type',
					'package_start_date',
					'KPIs',
					'campaign_goal',
					'country',
					'package_end_date',
					'manufacturer_name',
					'button_name',
					'end_date',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Campaign Creation Finished V2',
		type: 'object',
	}
	const message = {
		event: 'Campaign Creation Finished V2',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Creation Finished V2',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered  when the user starts the creation of a new Campaign by clicking on the "Create new Campaign" button
 *
 * @param {CampaignCreationStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignCreationStarted(
	props: CampaignCreationStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered  when the user starts the creation of a new Campaign by clicking on the "Create new Campaign" button',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
				},
				required: [
					'button_name',
					'destination_url',
					'button_label',
					'screen_name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Creation Started',
		type: 'object',
	}
	const message = {
		event: 'Campaign Creation Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Creation Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user loads the Campaign Details page
 *
 * @param {CampaignDetailsPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignDetailsPageViewed(
	props: CampaignDetailsPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: '\nTriggered when the user loads the Campaign Details page',
		properties: {
			context: {},
			properties: {
				properties: {
					KPIs: {
						description: '\nThe campaign KPIs',
						id: '/properties/properties/properties/KPIs',
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array\n",
							id: '/properties/properties/properties/KPIs/items',
							properties: {
								campaign_KPIs: {
									description: 'The campaign KPIs',
									id:
										'/properties/properties/properties/KPIs/items/properties/campaign_KPIs',
									type: ['string', 'null'],
								},
							},
							required: ['campaign_KPIs'],
							type: ['object', 'null'],
						},
						type: ['array', 'null'],
					},
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_KPIs_count: {
						description: '\nThe campaign KPIs count',
						id: '/properties/properties/properties/campaign_KPIs_count',
						type: ['integer', 'null'],
					},
					campaign_goal: {
						description: '\nThe campaign primary goal',
						id: '/properties/properties/properties/campaign_goal',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The ID Key for the campaign being created',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					campaign_type: {
						description: '\nThe campaign type',
						id: '/properties/properties/properties/campaign_type',
						type: ['string', 'null'],
					},
					country: {
						description: '\nThe country where the campaign will act',
						id: '/properties/properties/properties/country',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					end_date: {
						description: 'The campaign end date',
						id: '/properties/properties/properties/end_date',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description: '\nThe Manufacture ID the campaign is for',
						id: '/properties/properties/properties/manufacturer_id',
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description: '\nThe Manufacture name the campaign is for',
						id: '/properties/properties/properties/manufacturer_name',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					start_date: {
						description: 'The campaign start date',
						id: '/properties/properties/properties/start_date',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: '\nThe Vendor ID the campaign is for',
						id: '/properties/properties/properties/vendor_id',
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The Vendor name the campaign is for',
						id: '/properties/properties/properties/vendor_name',
						type: ['string', 'null'],
					},
				},
				required: [
					'button_label',
					'vendor_name',
					'campaign_goal',
					'campaign_type',
					'vendor_id',
					'campaign_KPIs_count',
					'KPIs',
					'screen_name',
					'start_date',
					'country',
					'campaign_id',
					'destination_url',
					'manufacturer_name',
					'manufacturer_id',
					'button_name',
					'end_date',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Details Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Campaign Details Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Details Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user loads the Campaign Details page



















 *
 * @param {CampaignDetailsPageViewedV2} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignDetailsPageViewedV2(
	props?: CampaignDetailsPageViewedV2,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user loads the Campaign Details page\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					KPIs: {
						description: '\nThe campaign KPIs',
						id: '/properties/properties/properties/KPIs',
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array\n",
							id: '/properties/properties/properties/KPIs/items',
							properties: {
								campaign_KPIs: {
									description: 'The campaign KPIs',
									id:
										'/properties/properties/properties/KPIs/items/properties/campaign_KPIs',
									type: ['string', 'null'],
								},
							},
							required: ['campaign_KPIs'],
							type: ['object', 'null'],
						},
						type: ['array', 'null'],
					},
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_KPIs_count: {
						description: '\nThe campaign KPIs count',
						id: '/properties/properties/properties/campaign_KPIs_count',
						type: ['integer', 'null'],
					},
					campaign_goal: {
						description: '\nThe campaign primary goal',
						id: '/properties/properties/properties/campaign_goal',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					campaign_type: {
						description: '\nThe campaign type',
						id: '/properties/properties/properties/campaign_type',
						type: ['string', 'null'],
					},
					country: {
						description: '\nThe country where the campaign will act',
						id: '/properties/properties/properties/country',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					end_date: {
						description: 'The campaign end date',
						id: '/properties/properties/properties/end_date',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description: '\nThe Manufacture ID the campaign is for',
						id: '/properties/properties/properties/manufacturer_id',
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description: '\nThe Manufacture name the campaign is for',
						id: '/properties/properties/properties/manufacturer_name',
						type: ['string', 'null'],
					},
					package_end_date: {
						description: 'The end date for the package that is being shown',
						id: '/properties/properties/properties/package_end_date',
						type: ['string', 'null'],
					},
					package_id: {
						description: 'The id for the package that is being shown',
						id: '/properties/properties/properties/package_id',
						type: ['string', 'null'],
					},
					package_start_date: {
						description: 'The start date for the package that is being shown',
						id: '/properties/properties/properties/package_start_date',
						type: ['string', 'null'],
					},
					partner_name: {
						description:
							'The partner name for the package that is being shown. If there is no package associated, then null',
						id: '/properties/properties/properties/partner_name',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					start_date: {
						description: 'The campaign start date',
						id: '/properties/properties/properties/start_date',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: '\nThe Vendor ID the campaign is for',
						id: '/properties/properties/properties/vendor_id',
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The Vendor name the campaign is for',
						id: '/properties/properties/properties/vendor_name',
						type: ['string', 'null'],
					},
				},
				required: [
					'manufacturer_id',
					'destination_url',
					'manufacturer_name',
					'package_id',
					'country',
					'KPIs',
					'button_name',
					'campaign_goal',
					'campaign_type',
					'package_end_date',
					'start_date',
					'campaign_KPIs_count',
					'partner_name',
					'screen_name',
					'vendor_name',
					'package_start_date',
					'campaign_id',
					'end_date',
					'vendor_id',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Campaign Details Page Viewed V2',
		type: 'object',
	}
	const message = {
		event: 'Campaign Details Page Viewed V2',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Details Page Viewed V2',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered  when the user starts the creation of a new Campaign by clicking on the "Duplicate" button at the "..." drop down menu
 *
 * @param {CampaignDuplicateCreationStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignDuplicateCreationStarted(
	props: CampaignDuplicateCreationStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered  when the user starts the creation of a new Campaign by clicking on the "Duplicate" button at the "..." drop down menu',
		properties: {
			context: {},
			properties: {
				properties: {
					KPIs: {
						description: '\nThe campaign KPIs',
						id: '/properties/properties/properties/KPIs',
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array\n",
							id: '/properties/properties/properties/KPIs/items',
							properties: {
								campaign_KPIs: {
									description: 'The campaign KPIs',
									id:
										'/properties/properties/properties/KPIs/items/properties/campaign_KPIs',
									type: ['string', 'null'],
								},
							},
							required: ['campaign_KPIs'],
							type: ['object', 'null'],
						},
						type: ['array', 'null'],
					},
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_KPIs_count: {
						description: '\nThe campaign KPIs count',
						id: '/properties/properties/properties/campaign_KPIs_count',
						type: ['integer', 'null'],
					},
					campaign_goal: {
						description: '\nThe campaign primary goal',
						id: '/properties/properties/properties/campaign_goal',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The ID Key for the campaign being created',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					campaign_type: {
						description: '\nThe campaign type',
						id: '/properties/properties/properties/campaign_type',
						type: ['string', 'null'],
					},
					country: {
						description: '\nThe country where the campaign will act',
						id: '/properties/properties/properties/country',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					end_date: {
						description: 'The campaign end date',
						id: '/properties/properties/properties/end_date',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description: '\nThe Manufacture ID the campaign is for',
						id: '/properties/properties/properties/manufacturer_id',
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description: '\nThe Manufacture name the campaign is for',
						id: '/properties/properties/properties/manufacturer_name',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					source_campaign_id: {
						description:
							'\nThe ID Key for the original campaign (the one that was duplicated)',
						id: '/properties/properties/properties/source_campaign_id',
						type: ['string', 'null'],
					},
					source_campaign_status: {
						description:
							'\nThe original campaign (the one that was duplicated) status',
						id: '/properties/properties/properties/source_campaign_status',
						type: ['string', 'null'],
					},
					start_date: {
						description: 'The campaign start date',
						id: '/properties/properties/properties/start_date',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: '\nThe Vendor ID the campaign is for',
						id: '/properties/properties/properties/vendor_id',
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The Vendor name the campaign is for',
						id: '/properties/properties/properties/vendor_name',
						type: ['string', 'null'],
					},
				},
				required: [
					'source_campaign_status',
					'country',
					'button_label',
					'campaign_type',
					'manufacturer_name',
					'campaign_goal',
					'screen_name',
					'start_date',
					'campaign_KPIs_count',
					'vendor_id',
					'vendor_name',
					'KPIs',
					'campaign_id',
					'destination_url',
					'source_campaign_id',
					'end_date',
					'manufacturer_id',
					'button_name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Duplicate Creation Started',
		type: 'object',
	}
	const message = {
		event: 'Campaign Duplicate Creation Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Duplicate Creation Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user begings to edit a Campaign by clicking on the "Edit" button at the "..." drop down menu
 *
 * @param {CampaignEditionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignEditionStarted(
	props: CampaignEditionStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the user begings to edit a Campaign by clicking on the "Edit" button at the "..." drop down menu',
		properties: {
			context: {},
			properties: {
				properties: {
					KPIs: {
						description: '\nThe campaign KPIs',
						id: '/properties/properties/properties/KPIs',
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array\n",
							id: '/properties/properties/properties/KPIs/items',
							properties: {
								campaign_KPIs: {
									description: 'The campaign KPIs',
									id:
										'/properties/properties/properties/KPIs/items/properties/campaign_KPIs',
									type: ['string', 'null'],
								},
							},
							required: ['campaign_KPIs'],
							type: ['object', 'null'],
						},
						type: ['array', 'null'],
					},
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_KPIs_count: {
						description: '\nThe campaign KPIs count',
						id: '/properties/properties/properties/campaign_KPIs_count',
						type: ['integer', 'null'],
					},
					campaign_goal: {
						description: '\nThe campaign primary goal',
						id: '/properties/properties/properties/campaign_goal',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The ID Key for the campaign being created',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					campaign_type: {
						description: '\nThe campaign type',
						id: '/properties/properties/properties/campaign_type',
						type: ['string', 'null'],
					},
					country: {
						description: '\nThe country where the campaign will act',
						id: '/properties/properties/properties/country',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					end_date: {
						description: 'The campaign end date',
						id: '/properties/properties/properties/end_date',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description: '\nThe Manufacture ID the campaign is for',
						id: '/properties/properties/properties/manufacturer_id',
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description: '\nThe Manufacture name the campaign is for',
						id: '/properties/properties/properties/manufacturer_name',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					start_date: {
						description: 'The campaign start date',
						id: '/properties/properties/properties/start_date',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: '\nThe Vendor ID the campaign is for',
						id: '/properties/properties/properties/vendor_id',
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The Vendor name the campaign is for',
						id: '/properties/properties/properties/vendor_name',
						type: ['string', 'null'],
					},
				},
				required: [
					'vendor_name',
					'manufacturer_id',
					'vendor_id',
					'screen_name',
					'campaign_type',
					'campaign_KPIs_count',
					'campaign_goal',
					'manufacturer_name',
					'button_label',
					'country',
					'end_date',
					'campaign_id',
					'button_name',
					'KPIs',
					'start_date',
					'destination_url',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Edition Started',
		type: 'object',
	}
	const message = {
		event: 'Campaign Edition Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Edition Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user publishes a campaign by clicking on the "Publish" button on the Summary page



















 *
 * @param {CampaignEditionStartedV2} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignEditionStartedV2(
	props?: CampaignEditionStartedV2,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user publishes a campaign by clicking on the "Publish" button on the Summary page\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					KPIs: {
						description: '\nThe campaign KPIs',
						id: '/properties/properties/properties/KPIs',
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array\n",
							id: '/properties/properties/properties/KPIs/items',
							properties: {
								campaign_KPIs: {
									description: 'The campaign KPIs',
									id:
										'/properties/properties/properties/KPIs/items/properties/campaign_KPIs',
									type: ['string', 'null'],
								},
							},
							required: ['campaign_KPIs'],
							type: ['object', 'null'],
						},
						type: ['array', 'null'],
					},
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_KPIs_count: {
						description: '\nThe campaign KPIs count',
						id: '/properties/properties/properties/campaign_KPIs_count',
						type: ['integer', 'null'],
					},
					campaign_goal: {
						description: '\nThe campaign primary goal',
						id: '/properties/properties/properties/campaign_goal',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					campaign_type: {
						description: '\nThe campaign type',
						id: '/properties/properties/properties/campaign_type',
						type: ['string', 'null'],
					},
					country: {
						description: '\nThe country where the campaign will act',
						id: '/properties/properties/properties/country',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					end_date: {
						description: 'The campaign end date',
						id: '/properties/properties/properties/end_date',
						type: ['string', 'null'],
					},
					manufacturer_id: {
						description: '\nThe Manufacture ID the campaign is for',
						id: '/properties/properties/properties/manufacturer_id',
						type: ['string', 'null'],
					},
					manufacturer_name: {
						description: '\nThe Manufacture name the campaign is for',
						id: '/properties/properties/properties/manufacturer_name',
						type: ['string', 'null'],
					},
					package_end_date: {
						description: 'The end date for the package that is being shown',
						id: '/properties/properties/properties/package_end_date',
						type: ['string', 'null'],
					},
					package_id: {
						description: 'The id for the package that is being shown',
						id: '/properties/properties/properties/package_id',
						type: ['string', 'null'],
					},
					package_start_date: {
						description: 'The start date for the package that is being shown',
						id: '/properties/properties/properties/package_start_date',
						type: ['string', 'null'],
					},
					partner_name: {
						description:
							'The partner name for the package that is being shown. If there is no package associated, then null',
						id: '/properties/properties/properties/partner_name',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					start_date: {
						description: 'The campaign start date',
						id: '/properties/properties/properties/start_date',
						type: ['string', 'null'],
					},
					vendor_id: {
						description: '\nThe Vendor ID the campaign is for',
						id: '/properties/properties/properties/vendor_id',
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The Vendor name the campaign is for',
						id: '/properties/properties/properties/vendor_name',
						type: ['string', 'null'],
					},
				},
				required: [
					'destination_url',
					'package_start_date',
					'end_date',
					'country',
					'manufacturer_id',
					'campaign_type',
					'campaign_goal',
					'campaign_id',
					'manufacturer_name',
					'screen_name',
					'campaign_KPIs_count',
					'package_id',
					'partner_name',
					'package_end_date',
					'vendor_id',
					'start_date',
					'button_name',
					'vendor_name',
					'KPIs',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Campaign Edition Started V2',
		type: 'object',
	}
	const message = {
		event: 'Campaign Edition Started V2',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Edition Started V2',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user filters the campaign list by clicking on the "Apply" button at the filter menu
 *
 * @param {CampaignFilterApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignFilterApplied(
	props: CampaignFilterApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user filters the campaign list by clicking on the "Apply" button at the filter menu',
		properties: {
			context: {},
			properties: {
				properties: {
					ads_package_filter: {
						description:
							"Package association filtering option selected (none, 'Ads package connected', or 'No ads package')",
						id: '/properties/properties/properties/ads_package_filter',
						type: ['string', 'null'],
					},
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_count: {
						description:
							"\nThe total number of campaigns on the user's campaign dashboard",
						id: '/properties/properties/properties/campaign_count',
						type: ['integer', 'null'],
					},
					campaing_type_filters: {
						description:
							'\nCampaing types selected for the filter concatenated ("Drive Purchace, Drive behavior")',
						id: '/properties/properties/properties/campaing_type_filters',
						type: ['string', 'null'],
					},
					end_date: {
						description: 'The campaign end date',
						id: '/properties/properties/properties/end_date',
						type: ['string', 'null'],
					},
					end_date_filter: {
						description: '\nEnd date selected for the filter',
						id: '/properties/properties/properties/end_date_filter',
						type: 'string',
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					start_date: {
						description: 'The campaign start date',
						id: '/properties/properties/properties/start_date',
						type: ['string', 'null'],
					},
					start_date_filter: {
						description: '\nStart date selected for the filter',
						id: '/properties/properties/properties/start_date_filter',
						type: ['string', 'null'],
					},
					status_filter: {
						description:
							'\nStatus selected for the filter concatenated ("Drive Purchace, Drive behavior")',
						id: '/properties/properties/properties/status_filter',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: [
					'end_date_filter',
					'start_date_filter',
					'start_date',
					'campaign_count',
					'ads_package_filter',
					'status_filter',
					'campaing_type_filters',
					'button_label',
					'button_name',
					'end_date',
					'url',
					'screen_name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Filter Applied',
		type: 'object',
	}
	const message = {
		event: 'Campaign Filter Applied',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Filter Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user loads the Campaign Listing page/Campaign Dashboard page
 *
 * @param {CampaignListingPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignListingPageViewed(
	props: CampaignListingPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the user loads the Campaign Listing page/Campaign Dashboard page',
		properties: {
			context: {},
			properties: {
				properties: {
					campaign_count: {
						description:
							"\nThe total number of campaigns on the user's campaign dashboard",
						id: '/properties/properties/properties/campaign_count',
						type: ['integer', 'null'],
					},
					page_item_count: {
						description: '\nThe count of campaigns actually being shown',
						id: '/properties/properties/properties/page_item_count',
						type: ['integer', 'null'],
					},
					pages_quantity: {
						description:
							'The total quantity of pages. E.g.: "1" (current_page) of "10" (pages_quantity)',
						id: '/properties/properties/properties/pages_quantity',
						type: ['integer', 'null'],
					},
					referrer: {
						description:
							'\nFull URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property',
						id: '/properties/properties/properties/referrer',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: [
					'pages_quantity',
					'campaign_count',
					'referrer',
					'url',
					'page_item_count',
					'screen_name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Listing Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Campaign Listing Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Listing Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user loads the Campaign Primary Goal page on the creation flow
 *
 * @param {CampaignPrimaryGoalPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignPrimaryGoalPageViewed(
	props: CampaignPrimaryGoalPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user loads the Campaign Primary Goal page on the creation flow',
		properties: {
			context: {},
			properties: {
				properties: {
					referrer: {
						description:
							'\nFull URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property',
						id: '/properties/properties/properties/referrer',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['screen_name', 'url', 'referrer'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Primary Goal Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Campaign Primary Goal Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Primary Goal Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user access Campaigns' analytics tool by clicking on the "See Analytics" button
 *
 * @param {CampaignSeeAnalyticsClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignSeeAnalyticsClicked(
	props: CampaignSeeAnalyticsClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the user access Campaigns\' analytics tool by clicking on the "See Analytics" button',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'button_label',
					'button_name',
					'destination_url',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign See Analytics Clicked',
		type: 'object',
	}
	const message = {
		event: 'Campaign See Analytics Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign See Analytics Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user loads the Campaign Settings page on the creation flow
 *
 * @param {CampaignSettingsPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignSettingsPageViewed(
	props: CampaignSettingsPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user loads the Campaign Settings page on the creation flow',
		properties: {
			context: {},
			properties: {
				properties: {
					campaign_id: {
						description: 'The ID Key for the campaign being created',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					referrer: {
						description:
							'\nFull URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property',
						id: '/properties/properties/properties/referrer',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['referrer', 'url', 'campaign_id', 'screen_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Settings Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Campaign Settings Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Settings Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user loads the Campaign Summary page on the creation flow
 *
 * @param {CampaignSummaryPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignSummaryPageViewed(
	props: CampaignSummaryPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the user loads the Campaign Summary page on the creation flow',
		properties: {
			context: {},
			properties: {
				properties: {
					campaign_id: {
						description: 'The ID Key for the campaign being created',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					referrer: {
						description:
							'\nFull URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property',
						id: '/properties/properties/properties/referrer',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['referrer', 'url', 'campaign_id', 'screen_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Summary Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Campaign Summary Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Summary Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user loads the Campaign Summary page on the creation flow








 *
 * @param {CampaignSummaryPageViewedV2} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignSummaryPageViewedV2(
	props?: CampaignSummaryPageViewedV2,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user loads the Campaign Summary page on the creation flow\n\n\n\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					package_end_date: {
						description: 'The end date for the package that is being shown',
						id: '/properties/properties/properties/package_end_date',
						type: ['string', 'null'],
					},
					package_id: {
						description: 'The id for the package that is being shown',
						id: '/properties/properties/properties/package_id',
						type: ['string', 'null'],
					},
					package_start_date: {
						description: 'The start date for the package that is being shown',
						id: '/properties/properties/properties/package_start_date',
						type: ['string', 'null'],
					},
					partner_name: {
						description:
							'The partner name for the package that is being shown. If there is no package associated, then null',
						id: '/properties/properties/properties/partner_name',
						type: ['string', 'null'],
					},
					referrer: {
						description:
							'\nFull URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property',
						id: '/properties/properties/properties/referrer',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: [
					'package_end_date',
					'package_start_date',
					'campaign_id',
					'referrer',
					'screen_name',
					'url',
					'package_id',
					'partner_name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Campaign Summary Page Viewed V2',
		type: 'object',
	}
	const message = {
		event: 'Campaign Summary Page Viewed V2',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Summary Page Viewed V2',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user loads the Campaign Touchpoints page on the creation flow
 *
 * @param {CampaignTouchpointsPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignTouchpointsPageViewed(
	props: CampaignTouchpointsPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the user loads the Campaign Touchpoints page on the creation flow',
		properties: {
			context: {},
			properties: {
				properties: {
					campaign_id: {
						description: 'The ID Key for the campaign being created',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					referrer: {
						description:
							'\nFull URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property',
						id: '/properties/properties/properties/referrer',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['campaign_id', 'referrer', 'url', 'screen_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Touchpoints Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Campaign Touchpoints Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Touchpoints Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user loads the Campaign Settings page on the creation flow
 *
 * @param {CampaignTypePageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignTypePageViewed(
	props: CampaignTypePageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user loads the Campaign Settings page on the creation flow',
		properties: {
			context: {},
			properties: {
				properties: {
					referrer: {
						description:
							'\nFull URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property',
						id: '/properties/properties/properties/referrer',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['referrer', 'screen_name', 'url'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Campaign Type Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Campaign Type Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Campaign Type Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks the  "Download as PDF" button







 *
 * @param {DownloadPdfButtonClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function downloadPdfButtonClicked(
	props?: DownloadPdfButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks the  "Download as PDF" button\n\n\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					campaign_status: {
						description:
							'The the current status of campaign that is being shown. Samples: "Draft", "In Progress"',
						id: '/properties/properties/properties/campaign_status',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					end_date: {
						description: 'The campaign end date',
						id: '/properties/properties/properties/end_date',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					start_date: {
						description: 'The campaign start date',
						id: '/properties/properties/properties/start_date',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'campaign_id',
					'start_date',
					'end_date',
					'button_name',
					'destination_url',
					'campaign_status',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Download PDF Button Clicked',
		type: 'object',
	}
	const message = {
		event: 'Download PDF Button Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Download PDF Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user saves a campaign by clicking on one of the "Save and exit" buttons
 *
 * @param {ExitStepperNavigation} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exitStepperNavigation(
	props?: ExitStepperNavigation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the user saves a campaign by clicking on one of the "Save and exit" buttons',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: [
					'destination_url',
					'url',
					'screen_name',
					'campaign_id',
					'button_name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Exit Stepper Navigation',
		type: 'object',
	}
	const message = {
		event: 'Exit Stepper Navigation',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Exit Stepper Navigation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on a button that moves him forward on the creation flow





 *
 * @param {ForwardStepperNavigation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forwardStepperNavigation(
	props: ForwardStepperNavigation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on a button that moves him forward on the creation flow\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					destination_url: {
						description:
							'\nUrl in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to)',
						id: '/properties/properties/properties/destination_url',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: [
					'url',
					'button_name',
					'destination_url',
					'campaign_id',
					'screen_name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Forward Stepper Navigation',
		type: 'object',
	}
	const message = {
		event: 'Forward Stepper Navigation',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Forward Stepper Navigation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered  when the user changes the current items per page option





 *
 * @param {ItemsPerPageChange} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function itemsPerPageChange(
	props: ItemsPerPageChange,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered  when the user changes the current items per page option\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					new_items_count: {
						description: 'The Items count option the chose',
						id: '/properties/properties/properties/new_items_count',
						type: ['integer', 'null'],
					},
					previous_items_count: {
						description: 'The Items count option the user changed',
						id: '/properties/properties/properties/previous_items_count',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: [
					'url',
					'button_name',
					'new_items_count',
					'previous_items_count',
					'screen_name',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Items Per Page Change',
		type: 'object',
	}
	const message = {
		event: 'Items Per Page Change',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Items Per Page Change',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered  when the user interacts with any menu in the application

 *
 * @param {MenuInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuInteraction(
	props?: MenuInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered  when the user interacts with any menu in the application\n',
		properties: {
			context: {},
			properties: {
				properties: {
					menu_category: {
						description:
							'Top level Menu Category in the given context, always in English. Samples:  Dashboard, Report_orders, Report_Customer. ',
						id: '/properties/properties/properties/menu_category',
						type: ['string', 'null'],
					},
					menu_link: {
						description:
							'\nWhat is the link of the page when clicks in the menu. ',
						id: '/properties/properties/properties/menu_link',
						type: ['string', 'null'],
					},
					menu_subcategory: {
						description:
							'The Sub Category of the menu in the given context. If the Menu does not have a subcategory, log null. Samples: Orders, Customer, Behaviour. ',
						id: '/properties/properties/properties/menu_subcategory',
						type: ['string', 'null'],
					},
				},
				required: ['menu_link', 'menu_subcategory', 'menu_category'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Menu Interaction',
		type: 'object',
	}
	const message = {
		event: 'Menu Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Menu Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user is creating a no SKUs Drive Behavior campaign and clicks on the "Yes, proceed" button at confirmation modal




 *
 * @param {NoSkUsModalConfirmation} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function noSkUsModalConfirmation(
	props?: NoSkUsModalConfirmation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user is creating a no SKUs Drive Behavior campaign and clicks on the "Yes, proceed" button at confirmation modal\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['button_name', 'campaign_id', 'screen_name', 'url'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'No SKUs Modal Confirmation',
		type: 'object',
	}
	const message = {
		event: 'No SKUs Modal Confirmation',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'No SKUs Modal Confirmation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user is creating a no SKUs Drive Behavior campaign and clicks on the "No, go back" button at confirmation modal




 *
 * @param {NoSkUsModalTurnBack} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function noSkUsModalTurnBack(
	props?: NoSkUsModalTurnBack,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user is creating a no SKUs Drive Behavior campaign and clicks on the "No, go back" button at confirmation modal\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['button_name', 'campaign_id', 'screen_name', 'url'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'No SKUs Modal Turn Back',
		type: 'object',
	}
	const message = {
		event: 'No SKUs Modal Turn Back',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'No SKUs Modal Turn Back',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user clicks the "Download as PDF" button








 *
 * @param {PdfDownloadStatus} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pdfDownloadStatus(
	props: PdfDownloadStatus,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when user clicks the "Download as PDF" button\n\n\n\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					campaign_status: {
						description:
							'The the current status of campaign that is being shown. Samples: "Draft", "In Progress"',
						id: '/properties/properties/properties/campaign_status',
						type: ['string', 'null'],
					},
					download_status: {
						description:
							'The status of the document download request. Samples: "Success", "Fail"',
						enum: ['FAIL', 'SUCCESS', null],
						id: '/properties/properties/properties/download_status',
						type: ['string', 'null'],
					},
					error_type: {
						description:
							'The type of error that led to na error event. If status is Success, then error_type is null.',
						id: '/properties/properties/properties/error_type',
						type: ['string', 'null'],
					},
					file_size: {
						description:
							'The size of the file the user choices generated in KB. If Status is Error, then file_size is null',
						id: '/properties/properties/properties/file_size',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'campaign_id',
					'campaign_status',
					'url',
					'error_type',
					'download_status',
					'file_size',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'PDF Download Status',
		type: 'object',
	}
	const message = {
		event: 'PDF Download Status',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'PDF Download Status',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered  when the user triggers the search by using the search bar ate the default state



 *
 * @param {SearchInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchInteraction(
	props: SearchInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered  when the user triggers the search by using the search bar ate the default state\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					referrer: {
						description:
							'\nFull URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property',
						id: '/properties/properties/properties/referrer',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					search: {
						description: 'The text the user searched for',
						id: '/properties/properties/properties/search',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['referrer', 'url', 'search', 'screen_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Search Interaction',
		type: 'object',
	}
	const message = {
		event: 'Search Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Search Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user sorts a column by clicking on its header





 *
 * @param {SortInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sortInteraction(
	props: SortInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user sorts a column by clicking on its header\n\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					header_label: {
						description:
							'The header label displayed to the user. Samples:\'Campaign Name", "Nome da Campanha"',
						id: '/properties/properties/properties/header_label',
						type: ['string', 'null'],
					},
					header_name: {
						description: 'The header name. Samples: Status, Campaign Name',
						id: '/properties/properties/properties/header_name',
						type: ['string', 'null'],
					},
					referrer: {
						description:
							'\nFull URL of the previous page. Equivalent to document.referrer from the DOM API. Default Page Viewed Event Property',
						id: '/properties/properties/properties/referrer',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					sort_type: {
						description:
							'The type of sorting the user is performing: ASCENDING, DESCENDING or DEFAULT',
						enum: ['ASCENDING', 'DEFAULT', 'DESCENDING', null],
						id: '/properties/properties/properties/sort_type',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: [
					'sort_type',
					'screen_name',
					'referrer',
					'url',
					'header_name',
					'header_label',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Sort Interaction',
		type: 'object',
	}
	const message = {
		event: 'Sort Interaction',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Sort Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "+ Upload New Banner" button at the home banner configuration on the content page




 *
 * @param {UploadNewBannerClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function uploadNewBannerClicked(
	props?: UploadNewBannerClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "+ Upload New Banner" button at the home banner configuration on the content page\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['button_name', 'campaign_id', 'screen_name', 'url'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Upload New Banner Clicked',
		type: 'object',
	}
	const message = {
		event: 'Upload New Banner Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Upload New Banner Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user changes a vendor in the settings page, either by changing it simply or conffirming it on the modal (if there were SKUs previously selected)




 *
 * @param {VendorChange} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vendorChange(
	props?: VendorChange,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when user changes a vendor in the settings page, either by changing it simply or conffirming it on the modal (if there were SKUs previously selected)\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
					vendor_name: {
						description: 'The Vendor name the campaign is for',
						id: '/properties/properties/properties/vendor_name',
						type: ['string', 'null'],
					},
				},
				required: ['vendor_name', 'button_name', 'campaign_id', 'url'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		title: 'Vendor Change',
		type: 'object',
	}
	const message = {
		event: 'Vendor Change',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Vendor Change',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "View all" button at the Campaign Goal bar




 *
 * @param {ViewAllTargetKpIsClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewAllTargetKpIsClicked(
	props: ViewAllTargetKpIsClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "View all" button at the Campaign Goal bar\n\n\n\n',
		properties: {
			context: {},
			properties: {
				properties: {
					button_label: {
						description:
							"The button label displayed to the user. Samples:'See Analytics', 'Ver análises''",
						id: '/properties/properties/properties/button_label',
						type: ['string', 'null'],
					},
					button_name: {
						description: '\nThe button name. Samples: See Analytics',
						id: '/properties/properties/properties/button_name',
						type: ['string', 'null'],
					},
					campaign_id: {
						description: 'The campaign id',
						id: '/properties/properties/properties/campaign_id',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'\nThe name of the screen where the user is taking this action. Samples: Campaign Dashboard Page',
						enum: [
							'Campaign Audiences Page',
							'Campaign Content Page',
							'Campaign Dashboard Page',
							'Campaign Details Page',
							'Campaign Primary Goal Page',
							'Campaign Settings Page',
							'Campaign Summary Page',
							'Campaign Touchpoints Page',
							'Campaign Type Page',
							null,
						],
						id: '/properties/properties/properties/screen_name',
						type: ['string', 'null'],
					},
					url: {
						description:
							'\nFull URL of the page. First we look for the canonical url. If the canonical url is not provided, we use location.href fr...',
						id: '/properties/properties/properties/url',
						type: ['string', 'null'],
					},
				},
				required: ['button_name', 'campaign_id', 'url', 'screen_name'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'View All Target KPIs clicked',
		type: 'object',
	}
	const message = {
		event: 'View All Target KPIs clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'View All Target KPIs clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Triggered when the user clicks on a action at the actions menu (...)
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	 *
	 * @param {ActionInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	actionInteraction,
	/**
	 * Triggered when the user clicks on the "+" button at the fields while configuring a Push notification touchpoint
	
	
	
	
	
	 *
	 * @param {AddPersonalizationClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addPersonalizationClicked,
	/**
	 * Triggered when the user clicks on the "Confirm" button at the Add pesonalization modal while configuring a Push notification touchpoint
	
	
	
	
	
	
	
	
	 *
	 * @param {AddPersonalizationConfirmed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addPersonalizationConfirmed,
	/**
	 * Triggered when the user clicks on the "Go back" or the "X" button at the Add pesonalization modal while configuring a Push notification touchpoint
	
	
	
	
	
	 *
	 * @param {AddPersonalizationExited} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addPersonalizationExited,
	/**
	 * Triggered when the user clicks in the ‘Connect’ button to associate an ads package
	
	
	
	 *
	 * @param {AdsPackageConnectionInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adsPackageConnectionInteraction,
	/**
	 * Triggered when the user clicks in a package
	
	
	
	
	
	 *
	 * @param {AdsPackageSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adsPackageSelected,
	/**
	 * Triggered when the user clicks on a button that moves him backwards on the creation flow
	
	
	
	
	
	 *
	 * @param {BackwardStepperNavigation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	backwardStepperNavigation,
	/**
	 * 
	Triggered when the user loads the Campaign Audiences page on the creation flow
	 *
	 * @param {CampaignAudiencesPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignAudiencesPageViewed,
	/**
	 * Triggered when the user loads the Campaign type page on the creation flow
	
	
	 *
	 * @param {CampaignContentPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignContentPageViewed,
	/**
	 * 
	Triggered when the user publishes a campaign by clicking on the "Publish" button on the Summary page
	 *
	 * @param {CampaignCreationFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignCreationFinished,
	/**
	 * Triggered when the user publishes a campaign by clicking on the "Publish" button on the Summary page
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	 *
	 * @param {CampaignCreationFinishedV2} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignCreationFinishedV2,
	/**
	 * Triggered  when the user starts the creation of a new Campaign by clicking on the "Create new Campaign" button
	 *
	 * @param {CampaignCreationStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignCreationStarted,
	/**
	 * 
	Triggered when the user loads the Campaign Details page
	 *
	 * @param {CampaignDetailsPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignDetailsPageViewed,
	/**
	 * Triggered when the user loads the Campaign Details page
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	 *
	 * @param {CampaignDetailsPageViewedV2} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignDetailsPageViewedV2,
	/**
	 * 
	Triggered  when the user starts the creation of a new Campaign by clicking on the "Duplicate" button at the "..." drop down menu
	 *
	 * @param {CampaignDuplicateCreationStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignDuplicateCreationStarted,
	/**
	 * 
	Triggered when the user begings to edit a Campaign by clicking on the "Edit" button at the "..." drop down menu
	 *
	 * @param {CampaignEditionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignEditionStarted,
	/**
	 * Triggered when the user publishes a campaign by clicking on the "Publish" button on the Summary page
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	 *
	 * @param {CampaignEditionStartedV2} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignEditionStartedV2,
	/**
	 * Triggered when the user filters the campaign list by clicking on the "Apply" button at the filter menu
	 *
	 * @param {CampaignFilterApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignFilterApplied,
	/**
	 * 
	Triggered when the user loads the Campaign Listing page/Campaign Dashboard page
	 *
	 * @param {CampaignListingPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignListingPageViewed,
	/**
	 * Triggered when the user loads the Campaign Primary Goal page on the creation flow
	 *
	 * @param {CampaignPrimaryGoalPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignPrimaryGoalPageViewed,
	/**
	 * 
	Triggered when the user access Campaigns' analytics tool by clicking on the "See Analytics" button
	 *
	 * @param {CampaignSeeAnalyticsClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignSeeAnalyticsClicked,
	/**
	 * Triggered when the user loads the Campaign Settings page on the creation flow
	 *
	 * @param {CampaignSettingsPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignSettingsPageViewed,
	/**
	 * 
	Triggered when the user loads the Campaign Summary page on the creation flow
	 *
	 * @param {CampaignSummaryPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignSummaryPageViewed,
	/**
	 * Triggered when the user loads the Campaign Summary page on the creation flow
	
	
	
	
	
	
	
	
	 *
	 * @param {CampaignSummaryPageViewedV2} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignSummaryPageViewedV2,
	/**
	 * 
	Triggered when the user loads the Campaign Touchpoints page on the creation flow
	 *
	 * @param {CampaignTouchpointsPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignTouchpointsPageViewed,
	/**
	 * Triggered when the user loads the Campaign Settings page on the creation flow
	 *
	 * @param {CampaignTypePageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignTypePageViewed,
	/**
	 * Triggered when the user clicks the  "Download as PDF" button
	
	
	
	
	
	
	
	 *
	 * @param {DownloadPdfButtonClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	downloadPdfButtonClicked,
	/**
	 * 
	Triggered when the user saves a campaign by clicking on one of the "Save and exit" buttons
	 *
	 * @param {ExitStepperNavigation} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exitStepperNavigation,
	/**
	 * Triggered when the user clicks on a button that moves him forward on the creation flow
	
	
	
	
	
	 *
	 * @param {ForwardStepperNavigation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	forwardStepperNavigation,
	/**
	 * Triggered  when the user changes the current items per page option
	
	
	
	
	
	 *
	 * @param {ItemsPerPageChange} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	itemsPerPageChange,
	/**
	 * Triggered  when the user interacts with any menu in the application
	
	 *
	 * @param {MenuInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuInteraction,
	/**
	 * Triggered when the user is creating a no SKUs Drive Behavior campaign and clicks on the "Yes, proceed" button at confirmation modal
	
	
	
	
	 *
	 * @param {NoSkUsModalConfirmation} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	noSkUsModalConfirmation,
	/**
	 * Triggered when the user is creating a no SKUs Drive Behavior campaign and clicks on the "No, go back" button at confirmation modal
	
	
	
	
	 *
	 * @param {NoSkUsModalTurnBack} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	noSkUsModalTurnBack,
	/**
	 * Triggered when user clicks the "Download as PDF" button
	
	
	
	
	
	
	
	
	 *
	 * @param {PdfDownloadStatus} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pdfDownloadStatus,
	/**
	 * Triggered  when the user triggers the search by using the search bar ate the default state
	
	
	
	 *
	 * @param {SearchInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchInteraction,
	/**
	 * Triggered when the user sorts a column by clicking on its header
	
	
	
	
	
	 *
	 * @param {SortInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sortInteraction,
	/**
	 * Triggered when the user clicks on the "+ Upload New Banner" button at the home banner configuration on the content page
	
	
	
	
	 *
	 * @param {UploadNewBannerClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	uploadNewBannerClicked,
	/**
	 * Triggered when user changes a vendor in the settings page, either by changing it simply or conffirming it on the modal (if there were SKUs previously selected)
	
	
	
	
	 *
	 * @param {VendorChange} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	vendorChange,
	/**
	 * Triggered when the user clicks on the "View all" button at the Campaign Goal bar
	
	
	
	
	 *
	 * @param {ViewAllTargetKpIsClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewAllTargetKpIsClicked,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_2JUm8RH3xWN2q2cg55zCNqOc8Ba`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
