import {
  useAuthenticationService,
  useFeatureToggleServiceV2,
  useFeatureToggleV2
} from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';
import {
  bannerFT,
  inAppFT,
  optimizelyProjectName,
  pushNotificationFT,
  sponsoredBDRFT
} from '../../constants';
import SupportedCountriesStore from '../../stores/supportedCountries/SupportedCountriesStore';
import { setTouchpointsToggles } from '../../stores/touchpoint/TouchpointEvents';
import { Loader } from '../Loader';
import { LoadingRender } from '../LoadingRender';
import { WithTouchpointsToggleProps } from './WithTouchpointsToggle.d';

export default function WithTouchpointsToggle({ children }: WithTouchpointsToggleProps) {
  const [isLoading, setLoading] = useState(true);

  const [showBanner, isLoadingBanner] = useFeatureToggleV2(bannerFT, optimizelyProjectName);
  const [showInApp, isLoadingInApp] = useFeatureToggleV2(inAppFT, optimizelyProjectName);
  const [showPushNotification, isLoadingPushNotfication] = useFeatureToggleV2(
    pushNotificationFT,
    optimizelyProjectName
  );
  const featureToggleInstance = useFeatureToggleServiceV2(optimizelyProjectName);

  const [showSponsoredBDRTask, setShowSponsoredBDRTask] = useState<boolean | undefined>(undefined);
  const userId = useAuthenticationService().getUserId();
  const handleBDRFeatureToggle = () => {
    const flagEnable = featureToggleInstance.onReady().then(() => {
      featureToggleInstance
        .isFeatureEnabledAudiences(sponsoredBDRFT, userId, {
          country: SupportedCountriesStore.getState().selectedCountry.code
        })
        .then((res) => {
          setShowSponsoredBDRTask(res);
        })
        .catch(() => setShowSponsoredBDRTask(false));
    });

    return flagEnable;
  };

  useEffect(() => {
    handleBDRFeatureToggle();
  }, []);

  useEffect(() => {
    if (
      !isLoadingBanner &&
      !isLoadingInApp &&
      !isLoadingPushNotfication &&
      showSponsoredBDRTask !== undefined
    ) {
      setTouchpointsToggles({
        HOMEPAGE_BANNER: showBanner,
        IN_APP_NOTIFICATION: showInApp,
        PUSH_NOTIFICATION: showPushNotification,
        BEES_FORCE: showSponsoredBDRTask
      });

      setLoading(false);
    }
  }, [
    isLoadingBanner,
    isLoadingInApp,
    isLoadingPushNotfication,
    showSponsoredBDRTask,
    showBanner,
    showInApp,
    showPushNotification,
    showSponsoredBDRTask
  ]);

  return (
    <LoadingRender isLoading={isLoading} fallback={<Loader />}>
      {children}
    </LoadingRender>
  );
}
