import { Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { liquidToString } from '../../../../../utils/liquidLogic/parser';
import LiquidHighlightText from '../../../../LiquidHighlight/LiquidHighlightText';
import Duration from '../../Duration/Duration';
import Label from '../../Label/Label';
import handleDate from '../utils';
import { PushProps } from './Push.d';
import pushStyles from './Push.styles';

export default function Push({ content }: PushProps) {
  const styles = pushStyles();
  const { formatMessage } = useIntl();

  return (
    <div data-testid="push-container">
      <Duration start={content.start} end={content.end} />

      <div className={styles.row}>
        <Label text={formatMessage({ id: 'pushCard.timePicker.label' })}>
          <Paragraph>{handleDate(content.timebox)}</Paragraph>
        </Label>
        <Label text={formatMessage({ id: 'pushCard.frequencyInput.label' })}>
          <Paragraph>{content.counter}</Paragraph>
        </Label>
      </div>

      <Label text={formatMessage({ id: 'pushCard.titleInput.label' })}>
        <Paragraph>
          <LiquidHighlightText text={liquidToString(content.title)} />
        </Paragraph>
      </Label>
      <Label text={formatMessage({ id: 'pushCard.bodyTextArea.label' })}>
        <Paragraph>
          <LiquidHighlightText text={liquidToString(content.description)} />
        </Paragraph>
      </Label>
    </div>
  );
}
