import { useStore } from 'effector-react';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import CampaignGoalBar from '../../components/CampaignGoalBar';
import ContentCard from '../../components/ContentCard/ContentCard';
import DetailsCard from '../../components/DetailsCard/DetailsCard';
import DownloadPdfButton from '../../components/DownloadPdfButton/DownloadPdfButton';
import { Header } from '../../components/Header';
import { Loader } from '../../components/Loader';
import { LoadingRender } from '../../components/LoadingRender';
import SummarySkuCard from '../../components/SummarySkuCard/SummarySkuCard';
import WithTouchpointsToggle from '../../components/WithTouchpointsToggle';
import { SCREEN_NAMES_ENUM } from '../../constants';
import useFeatureToggle from '../../hooks/useFeatureToggle/useFeatureToggle';
import EngageToggles from '../../hooks/useFeatureToggle/useFeatureToggle.types';
import useNavigate from '../../hooks/useNavigate/useNavigate';
import useSegment from '../../hooks/useSegment/useSegment';
import CampaignStore from '../../stores/campaign/CampaignStore';
import { onSelectCountry } from '../../stores/supportedCountries/SupportedCountriesEvents';
import $SupportedCountriesStore from '../../stores/supportedCountries/SupportedCountriesStore';
import CampaignsUseCase from '../../useCases/campaigns/CampaignsUseCase';
import { campaignToCardMapper } from '../../utils/Mappers/campaignToCardMapper';
import transformTargetKpis from '../../utils/transformTargetKpis/transformTargetKpis';
import { Container, Divider, DownloadContainer, HeaderContainer } from './DetailsPage.styles';

export default function DetailsPage() {
  const [showDownloadPdfButtonToggle] = useFeatureToggle(EngageToggles.downloadPdfButton);
  const { content: campaignStore } = useStore(CampaignStore);
  const { campaignId } = useParams<{ campaignId: string; primaryGoalName: string }>();
  const { campaignTypeName, primaryGoalName, targetKpiName, targetsKpi } =
    campaignToCardMapper(campaignStore);
  const { triggerEvent } = useSegment();
  const { formatMessage } = useIntl();
  const { push, location } = useNavigate();

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [segmentTrigger, setSegmentTrigger] = useState(true);

  const { data } = useStore($SupportedCountriesStore);

  const DOCUMENT_LOCATION = document.location.href;

  const getCampaignById = async () => {
    await CampaignsUseCase.executeGetCampaignById(campaignId);
    setIsLoadingPage(false);
  };

  const handleRedirect = useCallback(() => {
    if (!data.length) return;
    const sliceInitial = 0;
    const sliceFinal = 2;
    const supposedCountry = campaignId?.slice(sliceInitial, sliceFinal);
    const countryFound = data.find((item) => item.code === supposedCountry);

    if (countryFound) {
      onSelectCountry({ selectedCountry: countryFound });
      getCampaignById();
    } else {
      push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  const dataOrFallback = (value: string, fallback = null) => {
    return value || fallback;
  };

  useEffect(() => {
    const canDispatchEvent = campaignStore.id !== '' && segmentTrigger;

    if (canDispatchEvent) {
      setSegmentTrigger(false);
      const targetKPIs = transformTargetKpis(campaignStore);
      triggerEvent({
        type: 'CAMPAIGN_DETAILS_PAGE_VIEWED_V2',
        payload: {
          button_label: location?.key ? formatMessage({ id: 'popoverList.details' }) : '',
          button_name: location?.key ? 'Details' : '',
          destination_url: `${DOCUMENT_LOCATION}`,
          screen_name: SCREEN_NAMES_ENUM.DETAILS_PAGE,
          campaign_id: campaignStore.id,
          vendor_id: campaignStore.vendor,
          vendor_name: null,
          manufacturer_id: dataOrFallback(campaignStore.package?.manufacturerId),
          manufacturer_name: dataOrFallback(campaignStore.package?.partnerLegalName),
          country: campaignStore.country,
          start_date: campaignStore.start,
          end_date: campaignStore.end,
          campaign_type: campaignStore?.campaign_goal?.goal_name,
          campaign_goal: dataOrFallback(campaignStore?.campaign_goal?.primary_category?.name, ''),
          KPIs: targetKPIs,
          campaign_KPIs_count: campaignStore?.campaign_goal?.primary_category?.target_kpi?.length,
          package_id: campaignStore.package?.id,
          partner_name: campaignStore.package?.partnerLegalName,
          package_start_date: campaignStore.package?.start,
          package_end_date: campaignStore.package?.end
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignStore.id]);

  return (
    <LoadingRender isLoading={isLoadingPage} fallback={<Loader />}>
      <WithTouchpointsToggle>
        <HeaderContainer>
          <Header title={formatMessage({ id: 'campaignDetailsPage.title' })} />
        </HeaderContainer>
        <Container data-testid="multi-audience-details-page">
          <DownloadContainer>
            {showDownloadPdfButtonToggle && (
              <DownloadPdfButton
                dataSegmentPDF={{
                  button_name: 'PDF Details button',
                  screen_name: SCREEN_NAMES_ENUM.DETAILS_PAGE,
                  destination_url: DOCUMENT_LOCATION
                }}
              />
            )}
          </DownloadContainer>

          <CampaignGoalBar
            campaignTypeName={campaignTypeName}
            primaryGoalName={primaryGoalName}
            targetKpiName={targetKpiName}
            targetsKpi={targetsKpi}
          />
          <DetailsCard isFullWidth isEditEnable={false} />

          <SummarySkuCard isFullWidth isEditEnable={false} />

          <ContentCard isEditable={false} />

          <Divider />
        </Container>
      </WithTouchpointsToggle>
    </LoadingRender>
  );
}
