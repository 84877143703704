import { Heading, Paragraph, TextButton } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { formatExpressions } from '../../../../useCases/audienceManagement/TraitExpressionsUseCase';
import { AudienceInfoProps } from './AudienceInfo.d';
import { audienceInfoStyles } from './AudienceInfo.styles';

const MAX_VISIBLE_FILTERS = 2;

export default function AudienceInfo({ title, filters, handleViewAllClick }: AudienceInfoProps) {
  const { formatMessage } = useIntl();

  const styles = audienceInfoStyles();

  const operatorsTranslation = {
    GREATER_THAN: formatMessage({ id: 'audience.operators.greaterThan' }),
    LOWER_THAN: formatMessage({ id: 'audience.operators.lowerThan' }),
    EQUALS: formatMessage({ id: 'audience.operators.equals' }),
    YES: formatMessage({ id: 'audience.operators.yes' }),
    NO: formatMessage({ id: 'audience.operators.no' })
  };

  const firstFilter = filters[0];

  const expressions = firstFilter ? formatExpressions(firstFilter, operatorsTranslation) : [];

  const hasMoreTraits = filters.length > 1;

  const hasMoreExpressions = expressions.length > MAX_VISIBLE_FILTERS;

  const traitValuesToRender = expressions.slice(0, MAX_VISIBLE_FILTERS);

  return (
    <div className={styles.infoContainer} data-testid="audience-info-container">
      <Heading className={styles.title}>{title}</Heading>

      {traitValuesToRender.map((data) => (
        <Paragraph className={styles.item} key={uuid()} data-testid="trait-value-item">
          {formatMessage({ id: `audience.chipCopywrite.${firstFilter.traitId}` }, { ...data })}
        </Paragraph>
      ))}

      {hasMoreExpressions && (
        <Paragraph className={styles.item} data-testid="has-more-badge">{`+${
          expressions.length - MAX_VISIBLE_FILTERS
        } filters`}</Paragraph>
      )}

      {(hasMoreExpressions || hasMoreTraits) && (
        <TextButton
          onClick={handleViewAllClick}
          className={styles.viewAllButton}
          data-testid="view-all-button"
          role="button"
        >
          {formatMessage({ id: 'touchpoint.viewAll' })}
        </TextButton>
      )}
    </div>
  );
}
