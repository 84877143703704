import DesignTokensProvider from '@admin-portal-shared-components/design-tokens-provider';
import { LoadingBuzz } from '@hexa-ui/components';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import {
  createFeatureToggleServiceV2,
  useAnalyticsService,
  useAuthenticationService
} from 'admin-portal-shared-services';

import { useStore } from 'effector-react';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import LoadingContainer, { globalStyles } from './App.styles';
import { optimizelyProjectName } from './constants';
import { IntlProvider } from './i18n';
import { EnvConfig, EnvProvider, useEnvContext } from './providers/EnvProvider';
import Routes from './Routes/Routes';
import getSupportedCountriesFx from './services/supportedCountries/GetSupportedCountries';
import './stores/init';
import $SupportedCountriesStore from './stores/supportedCountries/SupportedCountriesStore';

export default function App(props: EnvConfig) {
  useEnvContext();
  const generateClassName = createGenerateClassName({
    productionPrefix: 'audience-management-audman-mfe',
    seed: 'audience-management-audman-mfe'
  });

  const authentication = useAuthenticationService();

  const jwtDecoded = authentication.parseJwt();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const analyticsService = useAnalyticsService();
  const { selectedCountry } = useStore($SupportedCountriesStore);
  const isLoading = useStore(getSupportedCountriesFx.pending);

  const localCountry = JSON.parse(localStorage.getItem('vendor'));

  const hasCodeCountry = localCountry && localCountry.code;
  const hasCodeCountrySelected = selectedCountry && selectedCountry.code;
  const country = hasCodeCountry || hasCodeCountrySelected;

  analyticsService.load(props[`segmentKey${country || userCountry}`] || props.segmentKeyBR);

  const sdkKey = props.optimizelyKey;

  createFeatureToggleServiceV2(optimizelyProjectName, sdkKey);

  const traits = {
    country: jwtDecoded?.country,
    email: jwtDecoded?.email,
    first_name: jwtDecoded?.given_name,
    last_name: jwtDecoded?.family_name,
    scope: jwtDecoded?.scopes
  };

  analyticsService.identify(traits);

  React.useEffect(() => {
    localStorage.setItem('cmsHost', props.cmsHost);
  }, [userCountry]);

  React.useEffect(() => {
    const currentState = window.history.state;
    getSupportedCountriesFx(
      currentState?.fromPartner ? currentState?.selectedCountry : userCountry
    );
  }, []);

  React.useEffect(() => {
    globalStyles();
  }, []);


  return isLoading ? (
    <LoadingContainer data-testId="loading">
      <LoadingBuzz />
    </LoadingContainer>
  ) : (
    <DesignTokensProvider>
      <EnvProvider env={props}>
        <StylesProvider generateClassName={generateClassName}>
          <IntlProvider>
            <BrowserRouter basename="/engage">
              <Routes />
            </BrowserRouter>
          </IntlProvider>
        </StylesProvider>
      </EnvProvider>
    </DesignTokensProvider>
  );
}
