/* eslint-disable effector/no-getState */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios from 'axios';
import { v4 as uuid } from 'uuid';
import {
  HEADER_VENDOR_ID,
  HEADER_X_BEES_CALLER,
  HEADER_X_BEES_ORIGIN,
  HEADER_X_VENDOR
} from '../constants';
import { getApiHost } from '../services/host/HostService';
import $SupportedCountriesStore from '../stores/supportedCountries/SupportedCountriesStore';
import { IRequest } from './Api.d';

Axios.interceptors.request.use((config) => {
  const { headers } = config;
  const authentication = useAuthenticationService();
  const token = authentication.getAuthHeader();
  const { selectedCountry } = $SupportedCountriesStore.getState();
  const xVendor = selectedCountry && selectedCountry.abiVendorId;

  headers.Authorization = token;
  headers.requestTraceId = uuid();

  if (selectedCountry && selectedCountry.code) {
    headers.country = selectedCountry.code;
  } else {
    delete headers.country;
  }

  headers[HEADER_VENDOR_ID] = xVendor;
  headers[HEADER_X_VENDOR] = xVendor;

  headers[HEADER_X_BEES_CALLER] = 'admin-portal-audman-mfe';
  headers[HEADER_X_BEES_ORIGIN] = 'admin-portal-audman-mfe';

  return { ...config, headers };
});

const Api = {
  post: ({ url, body, config }: IRequest): Promise<any> =>
    Axios.post(`${getApiHost()}${url}`, body, config).catch((error) => ({
      hasError: true,
      ...error
    })),

  delete: ({ url, config }: IRequest): Promise<any> =>
    Axios.delete(`${getApiHost()}${url}`, config).catch((error) => ({ hasError: true, ...error })),

  get: ({ url, config }: IRequest): Promise<any> =>
    Axios.get(`${getApiHost()}${url}`, config).catch((error) => ({ hasError: true, ...error })),

  put: ({ url, body, config }: IRequest): Promise<any> =>
    Axios.put(`${getApiHost()}${url}`, body, config).catch((error) => ({
      hasError: true,
      ...error
    })),

  patch: ({ url, body, config }: IRequest): Promise<any> =>
    Axios.patch(`${getApiHost()}${url}`, body, config).catch((error) => ({
      hasError: true,
      ...error
    }))
};

export default Api;
