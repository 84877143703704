import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core/styles';

export const CampaignGoalBarStyles = makeStyles((theme: Theme) => ({
  CampaignGoalBarContainer: {
    margin: '24px auto 12px',
    display: 'flex',
    padding: '0 24px 0 0',
    flexWrap: 'wrap',
    maxWidth: '1080px',
    width: '100%',
    background: 'white',
    boxShadow: '0 0 0 1px rgb(0 0 0 / 16%)',
    minHeight: '74px',
    alignItems: 'center',
    fontFamily: 'Work Sans',
    borderRadius: '8px'
  },
  LeftContainer: {
    gap: '12px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    height: '74px',
    backgroundColor: '#FAFAFA',
    marginRight: '24px',
    borderRadius: '8px 0 0 8px'
  },
  RightContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1 1'
  },
  InfoContainer: {
    display: 'flex',
    flex: '1 1'
  },
  InfoTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: '#808080'
  },
  InfoContent: {
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '20px'
  },
  InfoColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '48px'
  },
  ViewAllColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  ViewAllButton: {
    color: theme.bzColorSemanticInfoText,
    fontFamily: 'Work Sans',
    textDecoration: 'none',
    fontSize: '0.9rem',
    '& span': {
      cursor: 'pointer'
    }
  },
  DialogInfoTitle: {
    fontSize: '12px',
    fontFamily: 'Barlow',
    fontWeight: theme.bzFontWeightSemibold,
    lineHeight: '16px',
    color: theme.bzColorInterfaceLabelPrimary
  },
  DialogInfoContent: {
    fontSize: '12px',
    fontFamily: 'Work Sans',
    lineHeight: '16px',
    color: theme.bzColorInterfaceLabelSecondary
  },
  DialogInfoColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 16px 0'
  }
}));
