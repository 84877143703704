import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import { useIntl } from 'react-intl';
import Barlow from '../../assets/pdf/Barlow-SemiBold.ttf';
import AudicenceIcon from '../../assets/pdf/audience_icon.jpg';
import BeesLogo from '../../assets/pdf/bees.png';
import SkuIcon from '../../assets/pdf/sku_icon.jpg';
import TargetIcon from '../../assets/pdf/target-icon.jpg';
import TouchpointsIcon from '../../assets/pdf/touchpoints-icon.jpg';
import WorkSans from '../../assets/pdf/work_sans.ttf';
import { Banner as BannerType } from '../../domain/campaign';
import BannerPDF from './BannerPDF';
import { ICampaignPDFProps } from './CampaignPDF.d';
import { GetTraits } from './GetTraits';
import { styles } from './styles';
import { handleDateFormat } from './utils';

Font.register({
  family: 'Work Sans',
  src: WorkSans,
  format: 'truetype'
});

Font.register({
  family: 'Barlow',
  src: Barlow,
  format: 'truetype'
});

export default function CampaignPDF({
  date,
  campaign,
  audienceData,
  bannerImages
}: ICampaignPDFProps) {
  const { formatMessage } = useIntl();

  const kpis = campaign.campaign_goal.primary_category.target_kpi.map((kpi) => {
    return `${kpi.name}; `;
  });
  const skus = campaign.campaign_goal.goal_inputs;
  const { engagements } = campaign;

  return (
    <Document>
      <Page style={styles.body}>
        <View style={{ marginBottom: 17.5 }} fixed>
          <View style={styles.pageHeader}>
            <Text
              data-testid="pageNumber"
              style={styles.pageNumber}
              render={
                /* istanbul ignore next */ ({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages}`
              }
            />
            <Image src={BeesLogo} style={styles.image} />
          </View>
          <View style={{ ...styles.mainDivider, color: '#ddd' }} />
          <Text style={styles.downloadDate}>Downloaded on: {date}</Text>
        </View>

        <View>
          <Text style={styles.campaignName}>{campaign.name}</Text>
          <View style={styles.container}>
            <View style={[styles.tableCol]}>
              <Text style={styles.columnTitle}>Campaign ID</Text>
              <Text style={styles.columnValue}>{campaign.id}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.columnTitle}>Period</Text>
              <Text style={styles.columnValue}>
                {handleDateFormat(campaign.start)} to {handleDateFormat(campaign.end)}
              </Text>
            </View>
            <View style={{ ...styles.column, marginRight: 7 }}>
              <Text style={styles.columnTitle}>Status</Text>
              <Text style={styles.columnValue}>
                {formatMessage({ id: `statuses.${campaign.status?.toLowerCase()}` })}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ ...styles.card, marginTop: 6 }}>
          <View style={styles.iconContainer}>
            <Image style={styles.icon} src={TargetIcon} />
            <Text style={styles.cardTitle}>Campaign goal</Text>
          </View>

          <View style={styles.container}>
            <View style={[styles.tableCol]}>
              <Text style={styles.columnTitle}>Type</Text>
              <View style={styles.mainDivider} />
              <Text style={styles.columnValue}>{campaign.campaign_goal.goal_name}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.columnTitle}>Primary goal</Text>
              <View style={styles.mainDivider} />
              <Text style={styles.columnValue}>{campaign.campaign_goal.primary_category.name}</Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.columnTitle}>Target KPIs</Text>
              <View style={styles.mainDivider} />
              <Text style={styles.columnValue}>{kpis}</Text>
            </View>
          </View>
        </View>

        <View style={styles.card}>
          <View style={styles.iconContainer}>
            <Image style={styles.icon} src={SkuIcon} />
            <Text style={styles.titleCard}>SKUs</Text>
          </View>
          <Text style={styles.items}>{skus.length === 1 ? '1 item' : `${skus.length} items`}</Text>
          <View style={styles.table}>
            <View style={{ ...styles.tableRow, ...styles.bottom }}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Name</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>SKU Code</Text>
              </View>
            </View>

            {skus.map((sku) => {
              return (
                <View style={styles.tableRow} key={sku.goal_input_id}>
                  <View style={{ ...styles.divider, ...styles.tableCol }}>
                    <Text style={styles.tableContent}>{sku.sub_description}</Text>
                  </View>
                  <View style={{ ...styles.divider, ...styles.tableCol }}>
                    <Text style={styles.tableContent}>{sku.value}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>

        <View style={styles.card}>
          <View style={styles.iconContainer}>
            <Image style={styles.icon} src={AudicenceIcon} />
            <Text style={styles.titleCard}>Audiences</Text>
          </View>
          <Text style={styles.items}>
            {engagements.length === 1 ? '1 item' : `${engagements.length} items`}
          </Text>

          <View style={styles.table}>
            <View style={{ ...styles.tableRow, ...styles.bottom }}>
              <View style={styles.contentAudience}>
                <Text style={styles.tableCell}>Audience</Text>
              </View>
              <View style={styles.contentImpact}>
                <Text style={styles.tableCell}>Impact</Text>
              </View>
              <View style={{ width: 280 }}>
                <Text style={styles.tableCell}>Trait</Text>
              </View>
            </View>

            {engagements.map((audience) => {
              return (
                <View style={styles.tableRow} key={audience.audience_id}>
                  <View style={{ ...styles.divider, ...styles.contentAudience }}>
                    <Text style={{ ...styles.tableContent }}>{audience.audience_name}</Text>
                  </View>
                  <View style={{ ...styles.divider, ...styles.contentImpact }}>
                    <Text style={{ ...styles.tableContent }}>{`${
                      audienceData?.estimatedPoc || 0
                    } Pocs`}</Text>
                  </View>
                  <GetTraits filters={audienceData?.filters || []} />
                </View>
              );
            })}
          </View>
        </View>

        <View style={styles.card}>
          <View style={styles.iconContainer}>
            <Image style={styles.icon} src={TouchpointsIcon} />
            <Text style={styles.titleCard}>Touchpoints and content</Text>
          </View>
          {campaign.engagements.map((engagement) => (
            <View key={engagement.audience_id}>
              <Text style={styles.items}>
                {engagement.touchpoints.length === 1
                  ? '1 item'
                  : `${engagement.touchpoints.length} items`}
              </Text>
              <View style={styles.tableCol}>
                <Text style={styles.tableContent}>{engagement.audience_name}</Text>
              </View>
            </View>
          ))}
          <View style={styles.mainDivider} />
          <View style={styles.table}>
            <View style={{ ...styles.tableRow, ...styles.bottom }}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Touchpoints</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Start date</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>End date</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Image Preview</Text>
              </View>
            </View>

            {campaign.engagements.map((engagement) => (
              <View key={engagement.audience_id}>
                {engagement.touchpoints.map((touchpoint: BannerType) => (
                  <View style={styles.tableRow} key={touchpoint.touchpoint}>
                    <View style={styles.contentTouchpoint}>
                      <Text style={styles.tableContent}>
                        {formatMessage({
                          id: `touchpointName.${touchpoint.touchpoint.toLocaleLowerCase()}`
                        })}
                      </Text>
                    </View>
                    <View style={styles.contentTouchpoint}>
                      <Text style={styles.tableContent}>
                        {touchpoint.start && handleDateFormat(touchpoint.start)}
                      </Text>
                    </View>
                    <View style={styles.contentTouchpoint}>
                      <Text style={styles.tableContent}>
                        {touchpoint.end && handleDateFormat(touchpoint.end)}
                      </Text>
                    </View>
                    <View style={styles.contentTouchpoint}>
                      <View style={styles.imageContainer}>
                        {bannerImages[touchpoint.touchpoint_id]
                          ? bannerImages[touchpoint.touchpoint_id].map((banner) => {
                              return (
                                <BannerPDF key={touchpoint.touchpoint_id} bannerImages={banner} />
                              );
                            })
                          : null}
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
        <View style={styles.footerContainer}>
          <View style={styles.footerLine} />
          <View style={styles.footerLine} />
          <View style={styles.footerLine} />
          <View style={styles.footerLine} />
        </View>
      </Page>
    </Document>
  );
}
