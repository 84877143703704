import { createStore, sample } from 'effector';
import { getSupportedCountriesFx } from '../../services/supportedCountries/GetSupportedCountries';
import { resetDashboard } from '../dashboard/DashboardEvents';
import { onSelectCountry, resetSupportedCountries } from './SupportedCountriesEvents';
import { ISupportedCountriesProps } from './SupportedCountriesState';

const initialState: ISupportedCountriesProps = {
  data: [],
  hasError: false,
  selectedCountry: JSON.parse(localStorage.getItem('vendor'))
};

const $SupportedCountriesStore = createStore<ISupportedCountriesProps>(initialState)
  .reset(resetSupportedCountries)
  .on(getSupportedCountriesFx.doneData, (_, payload) => ({
    ...payload,
    hasError: false
  }))
  .on(getSupportedCountriesFx.fail, () => ({ ...initialState, hasError: true }))
  .on(onSelectCountry, (state, { selectedCountry }) => {
    localStorage.setItem('vendor', JSON.stringify(selectedCountry));

    return {
      ...state,
      selectedCountry: selectedCountry || null
    };
  });

sample({
  clock: onSelectCountry,
  target: resetDashboard
});

export default $SupportedCountriesStore;
