const CAMPAIGN_ROUTES = {
  '/campaign': 0,
  '/campaign/audience': 1,
  '/campaign/channel': 2,
  '/campaign/content': 3,
  '/campaign/summary': 4,
  '/package/:packageId/campaign': 5,
  '/package/:packageId/campaign/audience': 6,
  '/package/:packageId/campaign/channel': 7,
  '/package/:packageId/campaign/content': 8,
  '/package/:packageId/campaign/summary': 9
};

export default CAMPAIGN_ROUTES;
