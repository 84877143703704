import { createStore } from 'effector';
import { cloneDeep } from 'lodash';
import {
  changeValidDate,
  changeValidName,
  failingNewCampaign,
  loadingNewCampaign,
  validateAudience,
  validateChannel,
  validateContent,
  validateSettings
} from './NewCampaignEvents';
import { NewCampaignStorageProps } from './NewCampaignState';

const initialState: NewCampaignStorageProps = {
  isLoading: false,
  settings: {
    isValidName: false,
    isValidDate: false
  },
  validations: {
    settings: false,
    audience: false,
    channel: false,
    content: false
  }
};

const NewCampaignStore = createStore(initialState)
  .on(validateSettings, (state, { isValid }) =>
    cloneDeep({
      ...state,
      validations: {
        ...state.validations,
        settings: isValid
      }
    })
  )
  .on(validateAudience, (state, { isValid }) => {
    return cloneDeep({
      ...state,
      validations: {
        ...state.validations,
        audience: isValid
      }
    });
  })
  .on(validateChannel, (state, { isValid }) => {
    return cloneDeep({
      ...state,
      validations: {
        ...state.validations,
        channel: isValid
      }
    });
  })
  .on(validateContent, (state, { isValid }) => {
    return cloneDeep({
      ...state,
      validations: {
        ...state.validations,
        content: isValid
      }
    });
  })
  .on(loadingNewCampaign, (state, { isLoading }) => {
    return cloneDeep({
      ...state,
      isLoading
    });
  })
  .on(failingNewCampaign, (state) => {
    return cloneDeep({
      ...state,
      isLoading: false
    });
  })
  .on(changeValidName, (state, { isValid }) => {
    return cloneDeep({
      ...state,
      settings: {
        ...state.settings,
        isValidName: isValid
      }
    });
  })
  .on(changeValidDate, (state, { isValid }) => {
    return cloneDeep({
      ...state,
      settings: {
        ...state.settings,
        isValidDate: isValid
      }
    });
  });

export default NewCampaignStore;
