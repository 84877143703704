import { createStore } from 'effector';
import { cloneDeep } from 'lodash';
import {
  resetTouchpointsActive,
  resetTouchpointToggles,
  selectTouchpoint,
  setTouchpointsToggles
} from './TouchpointEvents';
import { TouchpointsPageTogglesProps, TouchpointsStorageProps } from './TouchpointState';

const initialState: TouchpointsStorageProps[] = [];

export const $touchpointsActive =
  createStore<TouchpointsStorageProps[]>(initialState).reset(resetTouchpointsActive);

$touchpointsActive.on(selectTouchpoint, (state, payload) => {
  const clone: TouchpointsStorageProps[] = cloneDeep(state);

  const { audienceId, touchpoints } = payload;

  const newObject: TouchpointsStorageProps = { audienceId, touchpoints };

  const index = clone.findIndex((data) => data.audienceId === audienceId);

  const alreadyOnArray = index !== -1;

  if (alreadyOnArray) {
    clone[index] = newObject;
  } else {
    clone.push(newObject);
  }

  return clone;
});

const initialStateToggle: TouchpointsPageTogglesProps = {
  HOMEPAGE_BANNER: false,
  PUSH_NOTIFICATION: false,
  IN_APP_NOTIFICATION: false,
  BEES_FORCE: false
};

export const $touchpointsPageToggles =
  createStore<TouchpointsPageTogglesProps>(initialStateToggle).reset(resetTouchpointToggles);

$touchpointsPageToggles.on(setTouchpointsToggles, (_, payload) => payload);
