import { ImageProps } from '../Image.d';

export function MarketplaceImage({ size = '64px' }: ImageProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 64 64" version="1.1">
      <defs>
        <linearGradient x1="50%" y1="176.976256%" x2="50%" y2="0%" id="linearGradientMarketplace">
          <stop stopColor="#DE5052" offset="0%" />
          <stop stopColor="#FF6F71" offset="100%" />
        </linearGradient>
      </defs>
      <g
        id="-Prototype-v2-(Hub-layout)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="4.1---Goal-selection-Copy-2"
          transform="translate(-356.000000, -352.000000)"
          fillRule="nonzero"
        >
          <g id="Group-2-Copy" transform="translate(332.000000, 328.000000)">
            <g id="marketplace64x64" transform="translate(24.000000, 24.000000)">
              <circle id="Oval" fill="url(#linearGradientMarketplace)" cx="32" cy="32" r="32" />
              <g id="Group" transform="translate(9.000000, 0.000000)">
                <g>
                  <g id="Path">
                    <g id="Group">
                      <path
                        d="M38.0481618,60.0257578 L38.0481618,3.97411724 C38.0481618,1.77924652 36.2628571,0 34.0606385,0 L3.98764874,0 C1.78530469,0 0,1.77924652 0,3.97411724 L0,60.0258828 C0,62.2206285 1.78530469,64 3.98764874,64 L34.0606385,64 C36.2628571,64 38.0481618,62.2206285 38.0481618,60.0257578 Z"
                        id="Path"
                        fill="#F2F2F2"
                      />
                      <path
                        d="M41.726637,0 L33.0049331,0 C35.2072772,0 36.9925819,1.77924652 36.9925819,3.97411724 L36.9925819,60.0258828 C36.9925819,62.2206285 35.2072772,64 33.0049331,64 L41.726637,64 C43.928981,64 45.7142857,62.2207535 45.7142857,60.0258828 L45.7142857,3.97411724 C45.7142857,1.77924652 43.928981,0 41.726637,0 L41.726637,0 Z"
                        id="Path"
                        fill="#DFDEDE"
                      />
                      <path
                        d="M38.0481618,5.28098969 L7.15137823,5.28098969 C6.12840892,5.28098969 5.29909639,6.10748807 5.29909639,7.12698608 L5.29909639,52.5002725 C5.29909639,53.5197705 6.12840892,54.3462689 7.15137823,54.3462689 L38.0481618,54.3462689 L38.0481618,5.28098969 Z"
                        id="Path"
                        fill="#D1F7F6"
                      />
                      <path
                        d="M38.5629075,5.28098969 L36.9924564,5.28098969 L36.9924564,54.3463939 L38.5629075,54.3463939 C39.5858768,54.3463939 40.4151893,53.5198955 40.4151893,52.5003975 L40.4151893,7.12698608 C40.4153148,6.10748807 39.5860022,5.28098969 38.5629075,5.28098969 Z"
                        id="Path"
                        fill="#A8DAD9"
                      />
                      <path
                        d="M38.0482872,5.28098969 L7.15137823,5.28098969 C6.12840892,5.28098969 5.29909639,6.10748807 5.29909639,7.12698608 L5.29909639,11.782602 L38.0482872,11.782602 L38.0482872,5.28098969 L38.0482872,5.28098969 Z"
                        id="Path"
                        fill="#78DDDB"
                      />
                      <path
                        d="M38.5629075,5.28098969 L36.9924564,5.28098969 L36.9924564,11.782727 L40.4151893,11.782727 L40.4151893,7.12698608 C40.4151893,6.10748807 39.5858768,5.28098969 38.5629075,5.28098969 Z"
                        id="Path"
                        fill="#4FBBB9"
                      />
                    </g>
                    <path
                      d="M24.9891233,59.879133 L20.7251624,59.879133 C20.1899724,59.879133 19.756126,59.4467589 19.756126,58.9133849 C19.756126,58.380011 20.1899724,57.9476368 20.7251624,57.9476368 L24.9891233,57.9476368 C25.5243133,57.9476368 25.9581597,58.380011 25.9581597,58.9133849 C25.9581597,59.4467589 25.5243133,59.879133 24.9891233,59.879133 Z"
                      fill="#007A78"
                    />
                  </g>
                  <g id="Oval" transform="translate(9.678966, 7.531858)" fill="#007A78">
                    <circle cx="1" cy="1" r="1" />
                    <circle cx="5.42550869" cy="1" r="1" />
                    <circle cx="9.85101739" cy="1" r="1" />
                  </g>
                </g>
                <g id="Path" transform="translate(0.000000, 11.782727)">
                  <g id="Group" transform="translate(12.873284, 17.641607)">
                    <g id="Path" transform="translate(0.000000, 7.232344)">
                      <path
                        d="M16.1268176,12.283601 L3.84077563,12.283601 C2.91990258,12.283601 2.13072616,11.6277273 1.9652901,10.7248541 L0,0 L19.9675932,0 L18.0023031,10.7248541 C17.8369925,11.6276023 17.0476906,12.283601 16.1268176,12.283601 L16.1268176,12.283601 Z"
                        fill="#FFB042"
                      />
                      <g id="Group" transform="translate(4.237273, 4.208201)" fill="#D1F7F6">
                        <path
                          d="M1.50984266,5.26028088 C1.04300944,5.26028088 0.631614231,4.92315654 0.554477629,4.44903246 L0.0126400376,1.12041396 C-0.073025489,0.593914991 0.28581649,0.0977909596 0.813982716,0.0124161264 C1.34227437,-0.0722087084 1.84008765,0.284665595 1.92575318,0.811039567 L2.46759077,4.13965807 C2.5532563,4.66615704 2.19441432,5.16228107 1.66624809,5.2476559 C1.61369486,5.25615588 1.56139249,5.26028088 1.50984266,5.26028088 Z"
                          id="Path"
                        />
                        <path
                          d="M9.9833297,5.26028088 C9.93177987,5.26028088 9.87947749,5.25615588 9.82692426,5.2476559 C9.29863261,5.16228107 8.93991606,4.66615704 9.02558158,4.13965807 L9.56741918,0.811039567 C9.65321013,0.284540595 10.1511488,-0.0718337091 10.6791896,0.0124161264 C11.2074813,0.0977909596 11.5661978,0.593914991 11.4805323,1.12041396 L10.9386947,4.44903246 C10.8615581,4.92315654 10.4501629,5.26028088 9.9833297,5.26028088 L9.9833297,5.26028088 Z"
                          id="Path"
                        />
                      </g>
                      <path
                        d="M9.98385932,9.46835651 C9.44866928,9.46835651 9.01482293,9.03598235 9.01482293,8.50260839 L9.01482293,5.17398989 C9.01482293,4.64049094 9.44866928,4.20824178 9.98385932,4.20824178 C10.5190494,4.20824178 10.9528957,4.64061594 10.9528957,5.17398989 L10.9528957,8.50260839 C10.9528957,9.03598235 10.5190494,9.46835651 9.98385932,9.46835651 Z"
                        fill="#D1F7F6"
                      />
                    </g>
                    <path
                      d="M16.6139697,6.59147019 C16.3550918,6.59147019 16.0967155,6.48872039 15.9059435,6.28522078 L11.5339915,1.62547989 C11.1685019,1.23585565 11.1890717,0.624731841 11.5798971,0.260357552 C11.970848,-0.103891736 12.5841781,-0.0835167763 12.9496676,0.306232463 L17.3216196,4.96597336 C17.6871092,5.3555976 17.6665394,5.96672141 17.275714,6.3310957 C17.0888302,6.50522036 16.8510237,6.59147019 16.6139697,6.59147019 L16.6139697,6.59147019 Z"
                      id="Path"
                      fill="#FFB042"
                    />
                    <path
                      d="M3.35374893,6.59147019 C3.11656955,6.59147019 2.87888848,6.50522036 2.69200468,6.3310957 C2.30105381,5.96684641 2.28048405,5.3555976 2.646099,4.96597336 L7.01805106,0.306232463 C7.38354059,-0.0836417761 7.99687064,-0.103766737 8.38782151,0.260357552 C8.77877238,0.624606841 8.79934214,1.23585565 8.4337272,1.62547989 L4.06177513,6.28522078 C3.87100314,6.48872039 3.61262689,6.59147019 3.35374893,6.59147019 L3.35374893,6.59147019 Z"
                      id="Path"
                      fill="#FFB042"
                    />
                  </g>
                  <path
                    d="M32.4648515,26.5320732 L13.2494342,26.5320732 C12.2642179,26.5320732 11.4655092,25.7360747 11.4655092,24.7542017 C11.4655092,23.7723286 12.2642179,22.9763301 13.2494342,22.9763301 L32.4649769,22.9763301 C33.4501932,22.9763301 34.2489019,23.7723286 34.2489019,24.7542017 C34.2489019,25.7360747 33.4500678,26.5320732 32.4648515,26.5320732 Z"
                    fill="#EE9D2B"
                  />
                  <g id="Group">
                    <g id="Path">
                      <polygon
                        fill="#FF6F71"
                        points="0 7.27423579 9.14288223 7.27423579 12.3222899 0 5.29897096 0"
                      />
                      <polygon
                        fill="#FAFAFA"
                        points="9.14288223 7.27423579 18.2857645 7.27423579 19.3454834 0 12.3222899 0"
                      />
                      <polygon
                        fill="#FF6F71"
                        points="18.2857645 7.27423579 27.4285213 7.27423579 26.3688023 0 19.3454834 0"
                      />
                      <polygon
                        fill="#FAFAFA"
                        points="27.4285213 7.27423579 36.5714035 7.27423579 33.3919958 0 26.3688023 0"
                      />
                      <polygon
                        fill="#FF6F71"
                        points="36.5714035 7.27423579 45.7142857 7.27423579 40.4151893 0 33.3919958 0"
                      />
                    </g>
                    <g id="Path" transform="translate(0.000000, 7.274236)">
                      <path
                        d="M4.57150383,6.2984877 C7.09619107,6.2984877 9.14288223,4.25874168 9.14288223,1.7426216 L9.14288223,0 L0,0 L0,1.7426216 C0,4.25874168 2.04669116,6.2984877 4.57150383,6.2984877 L4.57150383,6.2984877 Z"
                        fill="#C9201D"
                      />
                      <path
                        d="M13.7142606,6.2984877 C16.2389479,6.2984877 18.285639,4.25874168 18.285639,1.7426216 L18.285639,0 L9.1427568,0 L9.1427568,1.7426216 C9.14288223,4.25874168 11.1895734,6.2984877 13.7142606,6.2984877 Z"
                        fill="#DFDEDE"
                      />
                      <path
                        d="M22.8571429,6.2984877 C25.3818301,6.2984877 27.4285213,4.25874168 27.4285213,1.7426216 L27.4285213,0 L18.285639,0 L18.285639,1.7426216 C18.2857645,4.25874168 20.3324556,6.2984877 22.8571429,6.2984877 Z"
                        fill="#C9201D"
                      />
                      <path
                        d="M32.0000251,6.2984877 C34.5247123,6.2984877 36.5714035,4.25874168 36.5714035,1.7426216 L36.5714035,0 L27.4285213,0 L27.4285213,1.7426216 C27.4285213,4.25874168 29.4752124,6.2984877 32.0000251,6.2984877 Z"
                        fill="#DFDEDE"
                      />
                      <path
                        d="M41.1427819,6.2984877 C43.6674691,6.2984877 45.7141603,4.25874168 45.7141603,1.7426216 L45.7141603,0 L36.5712781,0 L36.5712781,1.7426216 C36.5714035,4.25874168 38.6180946,6.2984877 41.1427819,6.2984877 Z"
                        fill="#C9201D"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
