import { ImageProps } from '../Image.d';

export function ProductImage({ size = '64px' }: ImageProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 64 64" version="1.1">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradientProduct">
          <stop stopColor="#FEFE55" offset="0%" />
          <stop stopColor="#F2F209" offset="100%" />
        </linearGradient>
      </defs>
      <g
        id="-Prototype-v2-(Hub-layout)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="4.1---Goal-selection-Copy-2"
          transform="translate(-356.000000, -480.000000)"
          fillRule="nonzero"
        >
          <g id="Group-2" transform="translate(332.000000, 456.000000)">
            <g id="loudspeaker64x64" transform="translate(24.000000, 24.000000)">
              <circle id="Oval" fill="url(#linearGradientProduct)" cx="32" cy="32" r="32" />
              <g id="surface1" transform="translate(0.000000, 1.000000)">
                <path
                  d="M25.762156,58.4704238 L20.8002518,57.4548036 L17.9291637,60.4241398 L8.14207375,52.7106916 L14.6694046,48.262538 L21.6098204,40.6782975 C22.7729041,39.4071875 24.8290523,39.5905159 25.7479959,41.0478798 L33.9706358,50.4644301 C35.2133091,51.8876637 34.8617473,54.0876055 33.2367505,55.0539805 L28.3925219,57.9345782 C27.5468204,58.4450699 26.7416463,58.4884642 25.762156,58.4704238 Z"
                  id="Path"
                  fill="#FFE7CF"
                />
                <path
                  d="M33.9706358,50.4644301 L31.9642922,48.1664857 L23.5971213,53.1456091 C22.7514198,53.656101 21.9462455,53.6994952 20.9662671,53.6814549 L16.6205726,53.2143573 L12.8666738,56.43382 L17.929652,60.4241398 L20.8002518,57.454316 L25.762156,58.4704238 C26.7416463,58.4879765 27.5473087,58.4450699 28.3925219,57.9345782 L33.2367505,55.0539805 C34.8617473,54.0876055 35.2133091,51.8876637 33.9706358,50.4644301 Z"
                  id="Path"
                  fill="#EFD5BD"
                />
                <path
                  d="M33.7289371,50.6653113 C32.760189,49.1650408 31.5800156,49.9241962 30.6112675,48.4234381 C29.6425194,46.9231676 30.8226928,46.1635245 29.8539447,44.663254 C28.8847084,43.1624958 27.7050232,43.9221388 26.7362752,42.4213806 C25.7670388,40.920135 26.9472122,40.1604919 25.9779759,38.6597337 C25.385692,37.7426038 24.3129401,35.8488596 23.6596211,35.5884942 L18.4306275,38.1682574 L27.530219,52.4990836 C28.4179125,53.8745349 30.2543346,54.270934 31.6317733,53.3840336 L33.3729808,52.2635847 C33.904718,51.9208189 34.0775693,51.2109086 33.7367496,50.6779883 C33.7343082,50.6736001 33.7318668,50.6696995 33.7289371,50.6653113 Z"
                  id="Path"
                  fill="#424242"
                />
                <path
                  d="M18.687463,40.3867271 L14.579561,42.9177328 C13.8002657,43.3979949 12.7782949,43.1561573 12.2973389,42.3779864 L9.97507787,38.6197526 C9.49412182,37.8415817 9.73630884,36.8210857 10.5156042,36.3408236 L14.6235062,33.8098179 C15.1059271,33.5128843 15.7382501,33.6620824 16.036101,34.1438073 L19.0219349,38.9761705 C19.319786,39.4578953 19.1698839,40.089306 18.687463,40.3867271 Z"
                  id="Path"
                  fill="#424242"
                />
                <path
                  d="M21.2079657,26.9603539 L12.8700918,32.0979395 C11.7724377,32.7737194 11.4321062,34.2101176 12.1088628,35.3057003 L16.220671,41.960134 C16.8979158,43.0557166 18.3359012,43.3960446 19.4335553,42.719777 L27.7714294,37.582679 L54.1439358,31.850251 L38.1678931,5.99508447 L21.2079657,26.9603539 Z"
                  id="Path"
                  fill="#FFC981"
                />
                <path
                  d="M50.379783,25.7584797 L24.1430186,31.7112919 C24.1430186,31.7112919 15.1098333,37.2764814 13.818332,38.0726927 L16.220671,41.960134 C16.8974275,43.0557166 18.3359012,43.3960446 19.433067,42.719777 L27.7714294,37.582679 L54.1439358,31.850251 L50.379783,25.7584797 Z"
                  id="Path"
                  fill="#FFB042"
                />
                <path
                  d="M27.7714294,37.582679 L19.433067,42.719777 C18.3359012,43.3960446 16.8974275,43.0557166 16.220671,41.960134 L12.1088628,35.3057003 C11.431618,34.2101176 11.7724377,32.7737194 12.8696034,32.0979395 L21.2079657,26.9603539 L27.7714294,37.582679 Z"
                  id="Path"
                  fill="#FF6F71"
                />
                <path
                  d="M24.1430186,31.7112919 C24.1430186,31.7112919 15.1098333,37.2764814 13.818332,38.0726927 L16.220671,41.960134 C16.8974275,43.0557166 18.3359012,43.3960446 19.433067,42.719777 L23.602004,40.151228 L27.7714294,37.582679 L24.1430186,31.7112919 Z"
                  id="Path"
                  fill="#C9201D"
                />
                <path
                  d="M55.7259639,33.6874364 L54.5877825,34.3885702 C53.9979399,34.7518143 53.224504,34.5689733 52.8607352,33.9799818 L35.6859644,6.18523907 C35.3217074,5.59575991 35.5048124,4.8239276 36.0951434,4.46019603 L37.2333248,3.75906217 C37.8231673,3.39533061 38.5966033,3.57817154 38.9603721,4.16765058 L56.1351427,31.9623933 C56.4993998,32.5513848 56.3158065,33.3237048 55.7259639,33.6874364 Z"
                  id="Path"
                  fill="#FF6F71"
                />
                <path
                  d="M56.1351427,31.9623933 L53.309953,27.3894205 L50.0350571,29.4074964 L52.8607352,33.9799818 C53.224504,34.5689733 53.9979399,34.7518143 54.5877825,34.3885702 L55.7259639,33.6874364 C56.3158065,33.3237048 56.4993998,32.5513848 56.1351427,31.9623933 Z"
                  id="Path"
                  fill="#C9201D"
                />
                <path
                  d="M31.1410516,54.4493866 C31.7616558,55.4640318 33.0888017,55.7843691 34.1049129,55.1641727 L37.2469965,53.2460498 C38.2626195,52.626341 38.5829313,51.3020853 37.9623272,50.2879277 C37.3417229,49.2732825 36.0145772,48.9534328 34.9989542,49.5731416 L31.8563822,51.4912647 C30.8412475,52.1109735 30.5209357,53.4352291 31.1410516,54.4493866 Z"
                  id="Path"
                  fill="#FFE7CF"
                />
                <path
                  d="M37.9623272,50.2879277 C37.7079331,49.8720257 37.3339104,49.5750919 36.9125245,49.4098037 C37.0228759,50.2294187 36.653736,51.0753625 35.9012961,51.5346589 L32.7587241,53.4527819 C32.1591161,53.8184637 31.4525745,53.8545443 30.845642,53.6166074 C30.8842162,53.9033018 30.9804074,54.1870709 31.1410516,54.4498743 C31.7616558,55.4645194 33.0888017,55.7843691 34.1049129,55.1641727 L37.2469965,53.2460498 C38.2626195,52.626341 38.5824431,51.3020853 37.9623272,50.2879277 Z"
                  id="Path"
                  fill="#EFD5BD"
                />
                <path
                  d="M29.7953511,50.2138162 C30.4252327,51.2440637 31.7723981,51.5687893 32.803646,50.9393289 L35.8900657,49.0553363 C36.9208254,48.4263635 37.24602,47.0821172 36.6166267,46.0523572 C35.9867451,45.0225973 34.6395799,44.6978718 33.6083319,45.3273321 L30.5214239,47.2113247 C29.4906642,47.8402975 29.1654696,49.1845438 29.7953511,50.2138162 Z"
                  id="Path"
                  fill="#FFE7CF"
                />
                <path
                  d="M36.6166267,46.0523572 C36.3656506,45.6423061 36.0004169,45.3463476 35.5868435,45.1752084 C35.6918238,46.0021369 35.3173128,46.8519814 34.5590135,47.3146908 L31.4721057,49.1986835 C30.8515014,49.5775298 30.1176161,49.6087346 29.4931056,49.3508071 C29.5307033,49.6477407 29.6288476,49.9417489 29.7953511,50.2138162 C30.4252327,51.2440637 31.7723981,51.5687893 32.803646,50.9393289 L35.8900657,49.0553363 C36.9208254,48.4263635 37.24602,47.0821172 36.6166267,46.0523572 Z"
                  id="Path"
                  fill="#EFD5BD"
                />
                <path
                  d="M27.4945744,46.5097033 C28.124456,47.5394633 29.4716214,47.8646762 30.5028693,47.235216 L33.5897772,45.3507357 C34.6205369,44.7217631 34.9452433,43.3775168 34.31585,42.3482443 C33.6859684,41.3179968 32.3388032,40.9932713 31.3075552,41.6227316 L28.2206472,43.5067243 C27.1898875,44.135697 26.8646929,45.4799434 27.4945744,46.5097033 Z"
                  id="Path"
                  fill="#FFE7CF"
                />
                <path
                  d="M34.31585,42.3482443 C34.070245,41.9464819 33.714777,41.6534489 33.3119458,41.4808471 C33.4027658,42.2975365 33.0287432,43.1312909 32.2802096,43.5881495 L29.1937898,45.4721422 C28.5643965,45.8563517 27.8187925,45.8831684 27.1893993,45.6140266 C27.2235788,45.9216869 27.3217233,46.227397 27.4940862,46.5092157 C28.124456,47.5394633 29.471133,47.8641887 30.5028693,47.2347284 L33.5897772,45.3507357 C34.6205369,44.7217631 34.9452433,43.3775168 34.31585,42.3482443 Z"
                  id="Path"
                  fill="#EFD5BD"
                />
                <path
                  d="M25.3256335,42.7105131 C25.955515,43.7402731 27.3026803,44.0649985 28.3339283,43.4355382 L31.4208363,41.5515455 C32.4515958,40.9225729 32.7767906,39.5783266 32.146909,38.5485666 C31.5170274,37.5188066 30.1698621,37.1940811 29.1386142,37.8235414 L26.0517061,39.7075341 C25.0209466,40.3365068 24.6962401,41.6807532 25.3256335,42.7105131 Z"
                  id="Path"
                  fill="#FFE7CF"
                />
                <path
                  d="M32.146909,38.5485666 C31.8573588,38.0746429 31.4149768,37.7523553 30.9218138,37.6012069 C31.1102899,38.4827438 30.7387086,39.4242525 29.9252337,39.9210922 L26.8383257,41.8050849 C26.2816862,42.1449253 25.63325,42.2044095 25.0551261,42.0269319 C25.105419,42.262431 25.1933095,42.4935418 25.3256335,42.7105131 C25.955515,43.7402731 27.3026803,44.0649985 28.3339283,43.4355382 L31.4208363,41.5515455 C32.4515958,40.9225729 32.7767906,39.5783266 32.146909,38.5485666 Z"
                  id="Path"
                  fill="#EFD5BD"
                />
                <path
                  d="M29.9564837,45.3536612 L29.3842192,45.7027655 C28.4833421,46.2527509 27.3192819,45.9889723 26.7831501,45.1142615 L25.8349098,43.5657209 C25.3705552,42.8080283 24.3603032,42.5827682 23.5834494,43.0640055 C22.8036658,48.7793683 14.6694046,48.262538 14.6694046,48.262538 L19.709922,40.6817104 C20.0424408,40.1073462 20.5146079,39.6217208 21.082966,39.269691 L23.927687,37.5080799 C25.3104967,36.6518969 27.1083448,37.051709 27.9359798,38.4003436 L30.6171269,42.7695098 C31.1542352,43.6456834 30.8583374,44.8031881 29.9564837,45.3536612 Z"
                  id="Path"
                  fill="#FFE7CF"
                />
                <path
                  d="M30.6166386,42.7699973 L30.5946659,42.7339168 C30.4393928,42.9830679 30.2255261,43.2019895 29.9564837,43.3663025 L29.3837308,43.7158943 C28.4833421,44.2653922 27.3192819,44.0021013 26.7831501,43.1269028 L25.8349098,41.5788498 C25.3705552,40.8206694 24.3603032,40.5958971 23.5834494,41.0766468 C23.2563016,43.4735692 21.7885311,45.7705384 20.1503507,47.3439453 C21.8075741,46.6169699 23.2738797,45.3307451 23.5834494,43.0640055 C24.3603032,42.5827682 25.3705552,42.8080283 25.8349098,43.5657209 L26.7831501,45.1142615 C27.3192819,45.9889723 28.4833421,46.2527509 29.3837308,45.703253 L29.9564837,45.3536612 C30.8583374,44.8031881 31.1542352,43.6456834 30.6166386,42.7699973 Z"
                  id="Path"
                  fill="#EFD5BD"
                />
                <path
                  d="M18.0868782,60.6108814 L10.0693161,51.0973035 L12.7094475,48.8788338 C12.7924552,48.8091104 12.9169667,48.8198371 12.987279,48.902725 L20.7509354,58.1154687 C20.8207595,58.1983566 20.8100174,58.3226883 20.7270097,58.3928992 L18.0868782,60.6108814 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <path
                  d="M20.7504471,58.1154687 L16.6205726,53.2143573 L13.8295625,55.5595967 L18.0868782,60.6113689 L20.7265214,58.3928992 C20.8100174,58.3226883 20.8207595,58.1983566 20.7504471,58.1154687 Z"
                  id="Path"
                  fill="#DFDEDE"
                />
                <path
                  d="M51.1624963,15.9923375 C50.8426728,15.9923375 50.5306617,15.8314376 50.3509745,15.5398672 C50.0755844,15.0927603 50.2152325,14.5076694 50.6629857,14.2326767 L55.8202019,11.0648971 C56.2679549,10.7899043 56.8538913,10.929351 57.1287931,11.3764579 C57.4041832,11.8230773 57.2645351,12.4081682 56.8172702,12.6831609 L51.6600539,15.8509406 C51.5042925,15.946993 51.3324178,15.9923375 51.1624963,15.9923375 Z"
                  id="Path"
                  fill="#424242"
                />
                <path
                  d="M63.0496799,23.7482049 C63.0189182,23.7482049 62.9876682,23.7467421 62.9564183,23.7438166 L55.395398,23.0100151 C54.8724499,22.9593073 54.4896382,22.4951352 54.5404193,21.972454 C54.5912005,21.450748 55.0565316,21.0675134 55.5794798,21.1191965 L63.1404999,21.8525104 C63.6634481,21.9032183 64.0462599,22.3678779 63.9954787,22.8900716 C63.9476272,23.3810603 63.5335655,23.7482049 63.0496799,23.7482049 Z"
                  id="Path"
                  fill="#424242"
                />
                <path
                  d="M46.8348682,8.96978405 C46.7201223,8.96978405 46.6034233,8.94881826 46.4901423,8.90493651 C46.0003971,8.7147819 45.7582101,8.16382138 45.9486395,7.67527054 L48.7025403,0.605422142 C48.8929695,0.116383765 49.4447263,-0.124966259 49.9339831,0.0647006746 C50.4237282,0.254855277 50.6664035,0.805328259 50.4759743,1.29436676 L47.7220735,8.36372737 C47.5755894,8.7396483 47.2157267,8.96978405 46.8348682,8.96978405 Z"
                  id="Path"
                  fill="#424242"
                />
                <path
                  d="M18.6591428,60.5221425 C17.5746169,59.2517823 16.7612224,58.2990121 16.2189595,57.663832 C14.8355535,56.0433784 12.7604445,53.612698 9.99363258,50.3717908 C9.92478509,50.2908531 9.80564458,50.2747632 9.71726588,50.334735 L0.488280283,57.6093651 C0.180175393,57.8521778 0,58.223223 0,58.6157214 L0,61.5753061 C0,62.2832661 0.574705862,62.8571429 1.28417721,62.8571429 L16.3813152,62.8571429 C16.7699863,62.8571429 17.1381497,62.6811279 17.3822898,62.3783434 L18.6503537,60.8020111 C18.7250605,60.724974 18.7284785,60.6035677 18.6591428,60.5221425 Z"
                  id="Path"
                  fill="#585858"
                />
                <path
                  d="M18.6591428,60.5221425 L14.5502642,55.7087948 L8.50095966,62.8571429 L16.3813152,62.8571429 C16.7699863,62.8571429 17.1381497,62.6811279 17.3822898,62.3783434 L18.6503537,60.8020111 C18.7250605,60.724974 18.7284785,60.6035677 18.6591428,60.5221425 Z"
                  id="Path"
                  fill="#424242"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
