import { useStoreMap } from 'effector-react';
import { useEffect } from 'react';

import { Outlet } from 'react-router-dom';
import useNavigate from '../../hooks/useNavigate/useNavigate';
import { $campaignTypeSelectStore } from '../../stores/CampaignType/CampaignTypeStore';
import CampaignStore from '../../stores/campaign/CampaignStore';

export default function GoalRoute() {
  const { location, push } = useNavigate();

  const redirectToGoal = useStoreMap({
    keys: [location.pathname],
    store: $campaignTypeSelectStore,
    fn: ({ id }) => !id && location.pathname === '/goal/primary'
  });
  const redirectToDashboard = useStoreMap({
    keys: [location.pathname],
    store: CampaignStore,
    fn: ({ content: { id } }) => !!id && location.pathname.includes('/goal')
  });

  useEffect(() => {
    if (redirectToDashboard) push('/');

    if (redirectToGoal) push('/goal');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <Outlet />;
}
