import { Paragraph } from '@hexa-ui/components';
import { BannerImage } from '../../../../../../assets/img/BannerImage';
import { MessageImage } from '../../../../../../assets/img/MessageImage';
import { PushImage } from '../../../../../../assets/img/PushImage';
import { useTouchpointsList } from '../../../../../../hooks/useTouchpointList';
import { findOrDefault } from '../../../../../../utils/objectLiterals/objectLiteralsUtils';
import { TouchpointItemProps } from './TouchpointItem.d';
import touchpointItemStyles from './TouchpointItem.styles';

export default function TouchpointItem({ type, prefix }: TouchpointItemProps) {
  const styles = touchpointItemStyles();

  const touchpointMeta = useTouchpointsList().find((data) => data.type === type);

  const icon = findOrDefault<React.ReactNode>(type, {
    HOMEPAGE_BANNER: <BannerImage color="#B7E205" width="30px" height="51px" />,
    IN_APP_NOTIFICATION: <MessageImage color="#B7E205" width="30px" height="51px" />,
    PUSH_NOTIFICATION: <PushImage color="#B7E205" width="30px" height="51px" />,
    BEES_FORCE: null,
    default: null
  });

  return (
    <div className={styles.container}>
      <div data-testid="touchpoint-item-container" className={styles.content}>
        <div data-testid="icon-container" className={styles.icon}>
          {icon}
        </div>
        <Paragraph className={styles.title}>{`${prefix} ${touchpointMeta?.name}`}</Paragraph>
      </div>
    </div>
  );
}
