import { useStore } from 'effector-react';
import { useCallback, useEffect, useState } from 'react';
import { FiltersResponse } from '../../../../../../domain/filter';
import { useInterval } from '../../../../../../hooks/useInterval';
import { getFiltersFx } from '../../../../../../services/identity/audiences/FiltersService';
import { getTraitsFx } from '../../../../../../services/identity/audiences/TraitsService';
import { selectAudienceSummaryById } from '../../../../../../stores/summary/SummaryEvents';
import $selectedAudienceSummary from '../../../../../../stores/summary/SummaryStore';
import AudienceCardSummary from '../../../../../AudienceCardSummary/AudienceCardSummary';
import { AudienceItemProps } from './AudienceItem.d';

export default function AudienceItem({
  audienceId,
  index,
  filterId,
  title,
  isCsv
}: AudienceItemProps) {
  const [audienceData, setAudienceData] = useState<FiltersResponse | null>(null);
  const selectedAudienceSummary = useStore($selectedAudienceSummary);
  const isLoadingTraits = useStore(getTraitsFx.pending);
  const isSelected = audienceId === selectedAudienceSummary;

  const POLLING_TIME = 5000;

  const getFilters = useCallback(async () => {
    if (audienceId) {
      const filters = await getFiltersFx({ audienceId, filterId });

      setAudienceData(filters);
    }
  }, [audienceId, filterId]);

  useInterval({
    callback: getFilters,
    delay: POLLING_TIME,
    stopCallBack: audienceData?.estimatedPoc !== undefined
  });

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  const isLoadingAudienceData = isLoadingTraits || !audienceData;

  const handleSelectAudience = useCallback(() => {
    selectAudienceSummaryById(audienceId);
  }, [audienceId]);

  if (!audienceData) return null;

  return (
    <div>
      <AudienceCardSummary
        filters={audienceData?.filters}
        estimatedAudience={audienceData?.estimatedPoc}
        isLoading={isLoadingAudienceData}
        index={index}
        title={title}
        handleSelectAudience={handleSelectAudience}
        isSelected={isSelected}
        isCSV={isCsv}
      />
    </div>
  );
}
