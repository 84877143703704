import { createEvent } from 'effector';

export const loadingNewCampaign = createEvent<{ isLoading: boolean }>('loadingNewCampaign');
export const failingNewCampaign = createEvent('failingNewCampaign');

export const validateSettings = createEvent<{ isValid: boolean }>('validateSettings');
export const validateAudience = createEvent<{ isValid: boolean }>('validateAudience');
export const validateChannel = createEvent<{ isValid: boolean }>('validateChannel');
export const validateContent = createEvent<{ isValid: boolean }>('validateContent');

export const changeValidName = createEvent<{ isValid: boolean }>('changeValidName');
export const changeValidDate = createEvent<{ isValid: boolean }>('changeValidDate');
