import { Campaign } from '../../../domain/campaign';

export const campaignToCardMapper = (campaign: Campaign) => {
  const { campaign_goal } = campaign;
  const { goal_name: campaignTypeName, primary_category } = campaign_goal ?? {};
  const { name: primaryGoalName, target_kpi } = primary_category ?? {};
  const targetKpiList = target_kpi ?? [];
  const targetsKpi = targetKpiList.map(({ name, id }) => ({ name, id }));
  const [targetKpi] = targetsKpi;
  const { name: targetKpiName } = targetKpi ?? {};
  return { campaignTypeName, primaryGoalName, targetKpiName, targetsKpi };
};
