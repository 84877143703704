/* eslint-disable react/require-default-props */
import { Paragraph } from '@hexa-ui/components';
import { format, parse } from 'date-fns';
import { useStoreMap } from 'effector-react';
import { useIntl } from 'react-intl';

import CampaignStore from '../../../../stores/campaign/CampaignStore';
import Label from '../Label/Label';
import { DurationProps } from './Duration.d';
import durationStyles from './Duration.styles';

export default function Duration({ start, end }: DurationProps) {
  const styles = durationStyles();

  const { start: startCampaing, end: endCampaing } = useStoreMap({
    store: CampaignStore,
    keys: [],
    fn: (state) => state.content
  });

  const handleDateFormat = (date: string) => {
    const newDate = parse(date, 'yyyy-MM-dd', new Date());
    return format(newDate, 'dd/MM/Y');
  };

  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <Label text={formatMessage({ id: 'campaign.startDate' })}>
        <Paragraph>{handleDateFormat(start ?? startCampaing)} </Paragraph>
      </Label>
      <Label text={formatMessage({ id: 'campaign.endDate' })}>
        <Paragraph>{handleDateFormat(end ?? endCampaing)} </Paragraph>
      </Label>
    </div>
  );
}
