import { pdf } from '@react-pdf/renderer';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { useStoreMap } from 'effector-react';
import { forEach } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import CampaignPDF from '../../components/CampaignPDF/CampaignPDF';
import { getFormattedDate, getStringDate } from '../../components/CampaignPDF/utils';
import { DownloadPdfButtonProps } from '../../components/DownloadPdfButton/DownloadPdfButton.d';
import { PDF_DOWNLOAD_STATUS_ENUM } from '../../constants';
import { Banner, InApp, TouchpointEnum } from '../../domain/campaign';
import { Filter, FiltersResponse } from '../../domain/filter';
import { IntlProvider } from '../../i18n';
import getBannerImages from '../../services/banner/BannerImagesService';
import { GetBannerImages, GetBannerImagesData } from '../../services/banner/BannerImagesService.d';
import { getFiltersFx } from '../../services/identity/audiences/FiltersService';
import CampaignStore from '../../stores/campaign/CampaignStore';
import ChannelUsesCase from '../../useCases/channel/ChannelUseCase';
import useSegment from '../useSegment/useSegment';

export type BannerImages = { [key: string]: GetBannerImagesData[] };

async function handleFiltersPromises(promises: Promise<FiltersResponse>[]) {
  return Promise.allSettled(promises);
}

async function handleImagesPromises(promises: Promise<GetBannerImages>[]) {
  return Promise.allSettled(promises);
}

const useCampaignPDF = ({
  button_name,
  destination_url,
  screen_name,
  button_label
}: DownloadPdfButtonProps) => {
  const { formatMessage } = useIntl();
  const [isDownloading, setIsDownloading] = useState(false);

  const { triggerEvent } = useSegment();

  const toast = useToast();
  const date = new Date();
  const stringDate = getStringDate();
  const stringHours = `${date.getHours()}_${date.getMinutes()}`;

  const campaign = useStoreMap({
    store: CampaignStore,
    keys: [],
    fn: ({ content }) => content
  });

  const displayDownloadFailedToast = (error: Error) => {
    toast.notify({
      message: formatMessage({ id: 'pdfMessage.toastFailure' }),
      type: TypeToast.ERROR
    });

    triggerEvent({
      type: 'PDF_DOWNLOAD_STATUS',
      payload: {
        screen_name,
        campaign_id: campaign.id,
        campaign_status: formatMessage({ id: `statuses.${campaign.status.toLowerCase()}` }),
        download_status: PDF_DOWNLOAD_STATUS_ENUM.FAIL,
        error_type: error.message,
        file_size: null,
        url: destination_url
      }
    });
  };

  const processImages = (
    responseImages: PromiseSettledResult<GetBannerImages>[],
    blobs: BannerImages
  ) => {
    forEach(responseImages, (item) => {
      const result = item as { status: string; value: GetBannerImages };

      if (!result.value?.data) {
        throw new Error('NO DATA');
      }

      if (Object.keys(blobs).includes(result.value.id)) {
        blobs[result.value.id].push(result.value.data);
      } else {
        // eslint-disable-next-line no-param-reassign
        blobs[result.value.id] = [result.value.data];
      }
    });
  };

  const downloadPdf = async () => {
    triggerEvent({
      type: 'DOWNLOAD_PDF_CLICKED',
      payload: {
        button_label,
        button_name,
        screen_name,
        destination_url,
        campaign_id: campaign.id,
        campaign_status: formatMessage({ id: `statuses.${campaign.status.toLowerCase()}` }),
        end_date: campaign.end,
        start_date: campaign.start
      }
    });

    if (!navigator.onLine) {
      displayDownloadFailedToast(new Error('CONNECTION_ERROR'));
      return;
    }

    const blobsByIdObject: BannerImages = {};
    const promisesImages: Promise<GetBannerImages>[] = [];

    let filtersList: FiltersResponse = {
      description: '',
      estimatedPoc: '0',
      filters: [],
      id: ''
    };
    setIsDownloading(true);

    const cmsBanners = await ChannelUsesCase.execute();

    try {
      campaign.engagements.forEach((engagement) =>
        engagement?.touchpoints.forEach((touchpoint: Banner | InApp) => {
          if (touchpoint.touchpoint === TouchpointEnum.IN_APP_NOTIFICATION) {
            const inAppObj = {
              url: touchpoint?.image_url,
              name: touchpoint?.deeplink ? touchpoint?.deeplink : touchpoint?.image_url
            };
            if (Object.keys(blobsByIdObject).includes(touchpoint.touchpoint_id)) {
              blobsByIdObject[touchpoint.touchpoint_id].push(inAppObj);
            } else {
              blobsByIdObject[touchpoint.touchpoint_id] = [inAppObj];
            }
          } else {
            touchpoint?.immutable_guid?.map((immutableGuid: string) => {
              promisesImages.push(
                getBannerImages(touchpoint.touchpoint_id, cmsBanners, immutableGuid)
              );
            });
          }
        })
      );

      const responseImages = await handleImagesPromises(promisesImages);
      processImages(responseImages, blobsByIdObject);
      const promisesFilters = [];

      campaign.engagements.forEach((audience) => {
        promisesFilters.push(
          getFiltersFx({ audienceId: audience.audience_id, filterId: audience.filter_id })
        );
      });

      const responseFilters = await handleFiltersPromises(promisesFilters);
      forEach(responseFilters, (item) => {
        const result = item as {
          status: string;
          value: {
            estimatedPoc: string;
            filters: Filter[];
            id: string;
            description: string;
          };
        };

        filtersList = result.value;
      });

      const formattedDate = getFormattedDate();

      const responseBlob = await pdf(
        <IntlProvider>
          <CampaignPDF
            campaign={campaign}
            date={formattedDate}
            audienceData={filtersList}
            bannerImages={blobsByIdObject}
          />
        </IntlProvider>
      ).toBlob();

      const hiddenElement = document.createElement('a');
      hiddenElement.href = window.URL.createObjectURL(responseBlob);
      hiddenElement.download = `${campaign.name}_${stringDate}_${stringHours}.pdf`;
      hiddenElement.click();

      toast.notify({
        message: formatMessage({ id: 'pdfMessage.toastSuccess' }),
        type: TypeToast.SUCCESS
      });

      triggerEvent({
        type: 'PDF_DOWNLOAD_STATUS',
        payload: {
          screen_name,
          campaign_id: campaign.id,
          campaign_status: formatMessage({
            id: `statuses.${campaign.status.toLowerCase()}`
          }),
          download_status: PDF_DOWNLOAD_STATUS_ENUM.SUCCESS,
          error_type: null,
          file_size: responseBlob.size,
          url: destination_url
        }
      });

      setIsDownloading(false);
    } catch (_) {
      displayDownloadFailedToast(new Error('LOADING_PDF_ERROR'));
      setIsDownloading(false);
    }
  };

  return { downloadPdf, isDownloading };
};

export default useCampaignPDF;
