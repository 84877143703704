import { Card } from '@admin-portal-shared-components/card';
import AudienceSection from './components/AudienceSection/AudienceSection';
import ContentSection from './components/ContentSection/ContentSection';
import { ContentCardProps } from './ContentCard.d';
import contentCardStyles from './ContentCard.styles';

export default function ContentCard({ isEditable = true }: ContentCardProps) {
  const styles = contentCardStyles();

  return (
    <Card className={styles.content} border="large" elevated="large">
      <AudienceSection isEditable={isEditable} />
      <ContentSection isEditable={isEditable} />
    </Card>
  );
}
