import { GoalTypeIcons } from './goalType';
import { Sku } from './sku';

export type TCampaignStatus =
  | 'CANCELLED'
  | 'DRAFT'
  | 'ENDED'
  | 'ERROR'
  | 'IN_PROGRESS'
  | 'LOADING'
  | 'NONE'
  | 'SCHEDULED'
  | 'SUBMITTED_FOR_APPROVAL'
  | 'EXECUTION_REJECTED';

export interface Campaign {
  id: string;
  name: string;
  status: TCampaignStatus;
  campaign_goal: CampaignGoal;
  period?: string;
  duration: number;
  start: string;
  end: string;
  results?: string;
  engagements?: Engagement[];
  publishing_id?: string | null;
  country?: string;
  vendor?: string;
  updated_by?: string;
  created_at?: string;
  updated_at?: string;
  package?: Package;
}

export interface CampaignGoal {
  goal_id?: string;
  goal_name?: string;
  goal_icon?: GoalTypeIcons;
  goal_inputs: Sku[] | null;
  primary_category: PrimaryCategory;
}

type TouchpointsTypes = Banner | Push | InApp | SponsoredBDR;

export interface Engagement {
  audience_id: string;
  audience_name: string;
  estimatedPoc?: string;
  engagement_id?: string;
  filter_id?: string;
  filter_description?: string;
  touchpoints: TouchpointsTypes[];
  isCsv: boolean;
}

export enum TouchpointEnum {
  HOMEPAGE_BANNER = 'HOMEPAGE_BANNER',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  IN_APP_NOTIFICATION = 'IN_APP_NOTIFICATION',
  BEES_FORCE = 'BEES_FORCE'
}

interface ITouchpoint {
  touchpoint: TouchpointEnum;
  touchpoint_id?: string;
  start?: string | null;
  end?: string | null;
}

export type Content = Banner | InApp | Push | SponsoredBDR;

export interface Banner extends ITouchpoint {
  immutable_guid: string[];
  touchpoint: TouchpointEnum.HOMEPAGE_BANNER;
}

export interface LiquidInput {
  text: string;
  params: { variableName: string; defaultValue?: string }[];
}

export interface Push extends ITouchpoint {
  title: LiquidInput;
  description: LiquidInput;
  touchpoint: TouchpointEnum.PUSH_NOTIFICATION;
  counter: number;
  deeplink: string;
  timebox: string;
}

export interface InApp extends ITouchpoint {
  title?: string;
  description?: string;
  touchpoint: TouchpointEnum.IN_APP_NOTIFICATION;
  counter?: number;
  timebox?: string;
  deeplink?: string;
  image_url: string;
}
export interface SponsoredBDR extends ITouchpoint {
  title?: string;
  touchpoint: TouchpointEnum.BEES_FORCE;
  counter?: number;
  name?: string;
  templateTitle?: string;
  taskType?: string;
  category?: string;
  frequency?: string;
  duration?: string;
  templateSource?: string;
  taskSuccessCriteria?: string;
  minimumQuantity?: number;
  skuList?: Array<string>;
  submissionName?: string;
}

export interface BeesForce extends ITouchpoint {
  title?: string;
  touchpoint: TouchpointEnum.BEES_FORCE;
  counter?: number;
  name?: string;
  templateTitle?: string;
  taskType?: string;
  category?: string;
  frequency?: string;
  duration?: string;
  templateSource?: string;
  taskSuccessCriteria?: string;
  minimumQuantity?: number;
  skuList?: Array<string>;
  submissionName?: string;
}

export interface Package {
  id: string;
  name: string;
  partnerLegalName: string;
  manufacturerId: string;
  start: string;
  end: string;
}

export interface PrimaryCategory {
  id: string;
  name: string;
  description: string;
  target_kpi: TargetKPI[];
}

export interface TargetKPI {
  id: string;
  name: string;
  description: string;
}
