/* eslint-disable import/prefer-default-export */
import { Campaign } from '../../../domain/campaign';
import { GoalType } from '../../../domain/goalType';
import { PackageStorageProps } from '../../../stores/package/PackageState';
import { findGoalIcon } from '../../findGoalIcon';

export function campaignStoreMapper(
  campaignStore: Campaign,
  goalTypes: GoalType[],
  packageStore: PackageStorageProps
) {
  const { packageId, packageName, partnerLegalName, vendorId } = packageStore;
  const { name, start, end, campaign_goal, status, package: rawPackage } = campaignStore;
  const {
    id,
    manufacturerId,
    name: rawPackageName,
    partnerLegalName: rawLegalName
  } = rawPackage ?? {};

  return {
    name,
    start,
    end,
    status,
    goalName: campaign_goal?.goal_name,
    goalIcon: findGoalIcon(goalTypes, campaign_goal),
    package: {
      id: id || packageId,
      name: rawPackageName || packageName,
      partnerLegalName: rawLegalName || partnerLegalName,
      manufacturerId: manufacturerId || vendorId
    }
  };
}
