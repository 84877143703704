const getFrequency = (startDate: string, endDate: string) => {
  const milliseconds = 1000;
  const seconds = 60;
  const minutes = 60;
  const hoursInDay = 24;
  const oneDay = 1;

  const formatStartDate = new Date(startDate);
  const formatEndDate = new Date(endDate);

  const frequencyInMilliseconds = Number(formatEndDate) - Number(formatStartDate);

  const frequencyInDays = frequencyInMilliseconds / (milliseconds * seconds * minutes * hoursInDay);

  return frequencyInDays + oneDay;
};

export default getFrequency;
