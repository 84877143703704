import { createEffect } from 'effector';
import { cloneDeep } from 'lodash';
import { Campaign, Content } from '../../domain/campaign';
import {
  CreateCampaignRequest,
  CreatePackageCampaignRequest
} from '../../services/identity/campaigns/CampaignService.d';
import {
  createCampaign,
  createPackageCampaign,
  getCampaignById
} from '../../services/identity/campaigns/CampaignsService';
import getFrequency from '../../utils/getFrequency/getFrequency';
import { resetChannel } from '../channel/ChannelEvents';
import { packageReset } from '../package/PackageEvents';
import { resetCampaign, resetCanResetDates } from './CampaignEvents';
import { CampaignStorageProps } from './CampaignState';

export const fetchCreateCampaign = createEffect(
  ({ goalId, goalName, goalIcon, primaryCategory }: CreateCampaignRequest): Promise<Campaign> => {
    packageReset();
    resetCampaign();
    resetChannel();

    return createCampaign<Campaign>({
      goalId,
      goalName,
      goalIcon,
      primaryCategory
    });
  }
);

export const fetchCreatePackageCampaign = createEffect(
  (params: {
    packageCampaign: CreatePackageCampaignRequest;
    vendor: string;
  }): Promise<Campaign> => {
    resetCampaign();
    resetChannel();
    return createPackageCampaign<Campaign>(params.packageCampaign, params.vendor);
  }
);

export const fetchCampaignById = createEffect(
  ({ campaignId }: { campaignId: string }): Promise<Campaign> => {
    packageReset();
    resetCampaign();
    resetChannel();
    return getCampaignById<Campaign>(campaignId);
  }
);

interface CampaignDateChangedFxProps {
  campaign: CampaignStorageProps;
  canResetDatesOnTouchpoints: boolean;
}

const handleValidateDates = (startStore: string, endStore: string, touchpoint: Content) => {
  const date = {
    start: startStore,
    end: endStore
  };

  const hasFrequency =
    touchpoint.touchpoint === 'IN_APP_NOTIFICATION' ||
    touchpoint.touchpoint === 'PUSH_NOTIFICATION';

  if (hasFrequency) {
    const newFrequency = getFrequency(startStore, endStore);
    Object.assign(date, { counter: newFrequency });
  }

  return date;
};

export const campaignDateChangedFx = createEffect(
  ({ campaign, canResetDatesOnTouchpoints }: CampaignDateChangedFxProps) => {
    const copy = cloneDeep(campaign);
    const { start, end } = copy.content;

    if (canResetDatesOnTouchpoints) {
      const newEngagements = copy.content?.engagements?.map((engage) => ({
        ...engage,
        touchpoints: engage.touchpoints.map((touch) => {
          return {
            ...touch,
            ...handleValidateDates(start, end, touch)
          };
        })
      }));

      resetCanResetDates();

      return { ...copy, content: { ...copy.content, engagements: newEngagements } };
    }

    return copy;
  }
);
