import { createEvent } from 'effector';
import { EstimatedAudienceResponse } from '../../domain/audience';
import {
  Filter,
  TComparison,
  TraitListObjectExp,
  TraitOperation,
  TraitPrioritizationRange
} from '../../domain/filter';
import { Trait, TraitMetadata, TraitToggle } from '../../domain/trait';
import { FilterAvailable, TraitValue } from '../../domain/traitValue';

export const openCloseTraitsList = createEvent();
export const resetIsTraitsListOpen = createEvent();

export const setTraitMetadata = createEvent<TraitMetadata[] | undefined>();
export const resetTraitMetadata = createEvent();

export const selectTrait = createEvent<{ trait: Trait }>();
export const resetTraits = createEvent();

export const resetFiltersAvailable = createEvent();

export const removeFilter = createEvent<{ id: string }>();
export const resetFilters = createEvent();

export const openCloseTraitValuesContainer = createEvent<Filter>();
export const resetIsTraitsValuesOpen = createEvent();

export const selectTraitValue = createEvent<string>();
export const selectListOfTraitValue = createEvent<string[]>();
export const selectSingleTraitValue = createEvent<string>();

export const changeTraitNumericValue = createEvent<number>();

export const deselectTraitValue = createEvent<string>();
export const resetSelectedTraitValues = createEvent();

export const selectTraitComparison = createEvent<TraitOperation>();
export const changeTraitComparisonValue = createEvent<number>();

export const changeTraitPrioritizationValue = createEvent<number>();
export const clearTraitPrioritizationInputValues = createEvent();

export const selectTraitBooleanValue = createEvent<boolean>();

export const changeTraitFlag = createEvent<boolean>();

export const changeSearchString = createEvent<string>();

export const estimatedAudienceValue = createEvent<EstimatedAudienceResponse>();
export const calculateEstimatedAudience = createEvent<Filter[]>();
export const resetEstimatedAudience = createEvent();

export const clearTraitListObject = createEvent();
export const resetTraitsToggles = createEvent();
export const setTraitsToggles = createEvent<TraitToggle>();

export const selectTraitListObject = createEvent<TraitListObjectExp>();
export const selectedModel = createEvent<FilterAvailable>();

export interface SelectedTraitParamsProps {
  values?: (TraitValue | number)[];
  onFilter: Filter;
  comparison?: TComparison;
  flag?: boolean;
  priority?: string | number;
  range?: TraitPrioritizationRange;
}

export const applySelectedTraitValues = createEvent<SelectedTraitParamsProps>();
export const removeAppliedTraitValue = createEvent<{ index: number; fromFilter: Filter }>();
export const resetTraitValues = createEvent();
