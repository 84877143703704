import { makeStyles } from '@material-ui/core/styles';

const spaceItem4 = 4;

export const useHeaderStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: theme.spacing(spaceItem4),
    width: '100%'
  }
}));
