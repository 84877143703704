import { useRef, useState } from 'react';
import { UseScrollReturn } from './useScroll.d';

const DELAY_IN_MS = 200;

export default function useScroll(): UseScrollReturn {
  const [isScrolling, setIsScrolling] = useState(false);
  const timer = useRef<NodeJS.Timeout>(null);

  const handleScroll = () => {
    if (timer.current !== null) {
      setIsScrolling(true);
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      setIsScrolling(false);
    }, DELAY_IN_MS);
  };

  return { isScrolling, handleScroll };
}
