import { LegacyAudiencesProps } from './formatLegacyAudiences.d';

const serializedCustomFilters = {
  allowRemoval: false,
  estimatedPoc: '',
  filterId: '',
  filters: [],
  id: '',
  isCsv: false,
  name: ''
};

export default function formatLegacyAudiences(dataFilter: LegacyAudiencesProps) {
  serializedCustomFilters.filterId = dataFilter.id;
  serializedCustomFilters.filters = dataFilter.filters.map((filter) => {
    return {
      expressions: filter?.expressions,
      source: filter?.source,
      traitId: filter?.trait_id,
      traitType: filter?.trait_type
    };
  });
  serializedCustomFilters.id = dataFilter.id;
  serializedCustomFilters.name = dataFilter.description;
  serializedCustomFilters.estimatedPoc = dataFilter.estimatedPocCount.toString();

  return serializedCustomFilters;
}
