import { useAnySidebar } from 'ce-mfe-shared-features';
import { useLocation } from 'react-router-dom';

function useSidebarWithParam() {
  const location = useLocation();
  const QUERY_NAME = 'origin';

  const value = new URLSearchParams(location.search).get(QUERY_NAME);

  useAnySidebar(value || 'engage');

  return null;
}

export default useSidebarWithParam;
