import { useLogService as logService } from 'admin-portal-shared-services';
import Api from '../../../Api/Api';
import { PARTNER_PACKAGES_ENDPOINT, SUCCESS_CODE } from '../../../constants';
import { PackageResponse } from './PartnerPackagesService.d';

export const getPackageById = async (packageId: string): Promise<PackageResponse> => {
  const log = logService();
  const url = `${PARTNER_PACKAGES_ENDPOINT}/${packageId}`;

  let resp: any;
  try {
    resp = await Api.get({ url });
  } catch (error) {
    log.error('get-package-by-id-error', error);
    resp = error;
  }

  return handlePromise(resp);
};

const handlePromise = (resp): Promise<any> =>
  new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(resp.status)) {
      resolve(resp.data);
    } else {
      reject(resp);
    }
  });
