import { useLogService as logService } from 'admin-portal-shared-services';
import CmsApi from '../../../Api/custom/CmsApi';
import { GET_ALL_MODULES, GET_CMS_REDIRECT_URL, SUCCESS_CODE } from '../../../constants';

export const handleCountryToZone = (country: string): string => `zone_${country}`;
export const getAllModules = async <Response>(country: string): Promise<Response> => {
  const log = logService();
  let resp: any;
  try {
    const response = await CmsApi.get({
      url: `${GET_ALL_MODULES}/${handleCountryToZone(country)}`,
      config: {
        headers: {
          zone: country
        }
      }
    });
    resp = response;
  } catch (error) {
    log.error('get-all-modules-error', error);
    resp = error;
  }

  return handlePromise(resp);
};

export const getCmsRedirectUrl = async <Response>(country: string): Promise<Response> => {
  const log = logService();
  let resp: any;
  try {
    const response = await CmsApi.get({
      url: `${GET_CMS_REDIRECT_URL}/${handleCountryToZone(country)}`,
      config: {
        headers: {
          zone: country
        }
      }
    });
    resp = response;
  } catch (error) {
    log.error('get-all-modules-error', error);
    resp = error;
  }

  return handlePromise(resp);
};

const handlePromise = (resp): Promise<any> =>
  new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(resp.status)) {
      resolve(resp.data);
    } else {
      reject(resp);
    }
  });
