import {
  Expression,
  Filter,
  TraitListBooleanExp,
  TraitListComparisonExp,
  TraitListExp,
  TraitListObjectExp,
  TraitNumericExp,
  TraitPrioritization,
  TraitPrioritizationRange
} from '../../domain/filter';
import { ExpressionParams } from './TraitExpressionsUseCase.d';

const formatExpression = (expression: Expression, operatorsTranslation?) => {
  return {
    LIST: () => {
      const { value } = expression as TraitListExp;
      return { value };
    },
    BOOLEAN: () => {
      const { value } = expression as TraitListExp;
      const { YES, NO } = operatorsTranslation;
      return { value: value ? YES : NO };
    },
    COMPARISON: () => {
      const comparisonExp = expression as TraitListComparisonExp;
      const { comparison } = comparisonExp;
      return {
        operator: operatorsTranslation[comparison.operator],
        value: comparison.value
      };
    },
    LIST_COMPARISON: () => {
      const comparisonExp = expression as TraitListComparisonExp;
      const { comparison } = comparisonExp;
      return {
        operator: operatorsTranslation[comparison.operator],
        operatorValue: comparison.value,
        value: comparisonExp.value
      };
    },
    LIST_BOOLEAN: () => {
      const comparisonExp = expression as TraitListBooleanExp;
      return {
        operator: comparisonExp.flag ? 'Yes' : 'No',
        value: comparisonExp.value
      };
    },
    NUMERIC: () => {
      const comparisonExp = expression as TraitNumericExp;
      return { value: comparisonExp.value };
    },
    LIST_PRIORITY: () => {
      const prioritizationExp = expression as TraitPrioritization;
      return {
        value: prioritizationExp.value,
        number: prioritizationExp.number
      };
    },
    LIST_PRIORITY_RANGE: () => {
      const prioritizationExp = expression as TraitPrioritizationRange;
      return {
        ...prioritizationExp
      };
    },
    LIST_OBJECT: () => {
      const { filterDisplayName, displayName } = expression as TraitListObjectExp;
      return {
        model: displayName,
        vendor: filterDisplayName
      };
    }
  };
};

export const formatExpressions = (filter: Filter, operatorsTranslation?): ExpressionParams[] =>
  filter.expressions.map((expression) =>
    formatExpression(expression, operatorsTranslation)[filter.traitType]()
  );
