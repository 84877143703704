import { createEffect } from 'effector';
import CAMPAIGN_ROUTES from '../../pages/CampaignPage/constants/CampaignRoutes';

export const canLeavePage = createEffect((url: string) => {
  if (!Object.keys(CAMPAIGN_ROUTES).includes(url)) {
    return {
      isOpen: true,
      url
    };
  }
  return {
    isOpen: false,
    url: 'engage'
  };
});
