import { isAfter, parse } from 'date-fns';
import { createEffect } from 'effector';
import { isEqual } from 'lodash';
import CampaignUseCase from '../../useCases/campaigns/CampaignsUseCase';
import { isDatesRangeValid } from '../../utils/date-utils';
import {
  onChangeCampaignNameErrorText,
  onChangeIsCampaignNameValid,
  onChangeIsDatesValid
} from './SettingsEvents';

const minCampaignNameLength = 3;

export const validateCampaignNameFx = createEffect(async (campaignName) => {
  if (!campaignName) return onChangeIsCampaignNameValid(false);

  if (campaignName.length < minCampaignNameLength) {
    onChangeCampaignNameErrorText('campaign.campaignNameLength');
    return onChangeIsCampaignNameValid(false);
  }

  const result = await CampaignUseCase.executeValidateName(campaignName);
  if (!result) {
    onChangeCampaignNameErrorText('campaign.campaignInUse');
    return onChangeIsCampaignNameValid(false);
  }
  return onChangeIsCampaignNameValid(true);
});

export const verifyIsSaveAndExitDisabledFx = createEffect(
  ({ settingsStore, campaignNameValidation }) => {
    const { campaignName, startDate, endDate } = settingsStore;
    const { isCampaignNameValid } = campaignNameValidation;
    const validatingCampaign = !campaignName && !isCampaignNameValid;
    const validatingStartDate = !startDate && !isDatesRangeValid(startDate);
    const validingEndDate = !endDate && !isDatesRangeValid(endDate);

    if (validatingCampaign || validatingStartDate || validingEndDate) return true;

    return false;
  }
);

export const validateDatesFx = createEffect(({ startDate, endDate }) => {
  if (!startDate || !endDate) onChangeIsDatesValid(false);
  const dateRangeValid = isDatesRangeValid(startDate) && isDatesRangeValid(endDate);
  const _startDate = parse(startDate, 'yyyy-MM-dd', new Date());
  const _endDate = parse(endDate, 'yyyy-MM-dd', new Date());
  const isStartBeforeEnd = isAfter(_endDate, _startDate) || isEqual(_startDate, _endDate);
  return onChangeIsDatesValid(dateRangeValid && isStartBeforeEnd);
});
