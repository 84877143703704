import { createEffect } from 'effector';
import Api from '../../Api/Api';
import { GET_PACKAGES_LIST } from '../../constants';
import { GetPackagesProps } from './getPackages.d';

const pageSizeNumber = 10;

export const getPackageListFx = createEffect(
  async ({ search, pageSize = pageSizeNumber, page }: GetPackagesProps) => {
    const response = await Api.get({
      url: `${GET_PACKAGES_LIST}`,
      config: { params: { pageSize, page, search } }
    });

    return {
      data: response.data.content,
      pagination: response.data.pagination
    };
  }
);

export default getPackageListFx;
