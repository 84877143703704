/* eslint-disable jsx-a11y/anchor-is-valid */
import { Image, Link, View } from '@react-pdf/renderer';
import { GifObject } from '../../services/banner/BannerImagesService.d';
import { BannerProps } from './BannerPDF.d';
import { styles } from './styles';

function BannerPDF({ bannerImages }: BannerProps) {
  const banner = bannerImages as GifObject;

  if (banner?.url) {
    return (
      <View data-testid="pdf-link" style={styles.pdfLink}>
        <Link src={banner.url}>{banner.name} </Link>
      </View>
    );
  }

  const blob = bannerImages as Blob;
  const urlCreator = window.URL;
  const imageUrl = urlCreator.createObjectURL(blob);
  return (
    <View>
      <View data-testid="banner-container">
        <View>
          <View>
            <Image src={imageUrl} style={styles.imageBanner} />
          </View>
        </View>
      </View>
    </View>
  );
}

export default BannerPDF;
