import { Grid } from '@hexa-ui/components';
import { setAppHeaderConfig } from 'admin-portal-shared-services';
import { lazy, Suspense } from 'react';
import { Routes as ReactRoutes, Route, useLocation } from 'react-router-dom';
import { Loader } from '../components/Loader';

import MainTemplate from '../components/MainTemplate';
import useSidebarWithParam from '../hooks/useSidebarWithParam/useSidebarWithParam';
import DetailsPage from '../pages/DetailsPage/DetailsPage';
import GoalRoute from './GoalRoutes/GoalRoute';

const Dashboard = lazy(() => import('../pages/DashboardPage/DashboardPage'));
const Campaign = lazy(() => import('../pages/CampaignPage'));
const PackageCampaignPage = lazy(() => import('../pages/PackageCampaignPage'));
const PackageCampaignEditPage = lazy(
  () => import('../pages/PackageCampaignEditPage/PackageCampaignEditPage')
);
const GoalTypePagePageRouter = lazy(() => import('../pages/GoalTypePage/GoalTypePagePageRouter'));
const PrimaryGoalPage = lazy(() =>
  import('../pages/PrimaryGoalPage').then((module) => ({ default: module.PrimaryGoalPage }))
);

function Routes() {
  useSidebarWithParam();
  const campaingRoutes = ['/campaign/*', '/package/:packageId/campaign/*'];

  const { pathname } = useLocation();

  if (pathname !== '/') {
    setAppHeaderConfig({
      breadcrumbConfig: undefined,
      defaultCountry: undefined,
      countryOptions: [],
      countrySelect: false,
      optionalButtons: [],
      pageTitle: '',
      vendorSelect: false
    });
  }

  return (
    <Suspense fallback={<Loader />}>
      <Grid.Container type="fluid" sidebar>
        <ReactRoutes>
          <Route element={<Dashboard />} index />
          <Route path="/" element={<MainTemplate />}>
            <Route element={<GoalRoute />} path="/goal">
              <Route index element={<GoalTypePagePageRouter />} />
              <Route path="/goal/primary" element={<PrimaryGoalPage />} />
            </Route>
            <Route path="/package/:packageId" element={<PackageCampaignPage />} />
            <Route path="/campaign/:campaignId/details" element={<DetailsPage />} />
            <Route path="/campaign/:campaignId/edit" element={<PackageCampaignEditPage />} />
            {campaingRoutes.map((path) => (
              <Route element={<Campaign />} key={path} path={path} />
            ))}
          </Route>
        </ReactRoutes>
      </Grid.Container>
    </Suspense>
  );
}

export default Routes;
