import { ImageProps } from '../Image.d';

export function PushImage({ width = '29px', height = '51px', color = '#4ABEFF' }: ImageProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 51"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Push notifications icon</title>
      <defs>
        <path
          d="M1.60343382,0.0298995161 C1.64023322,0.0666989205 1.64306395,0.124603757 1.61192599,0.164650152 L1.60343382,0.174267151 L0.961,0.816 L1.60343382,1.45906618 C1.64329984,1.4989322 1.64329984,1.5635678 1.60343382,1.60343382 C1.56663441,1.64023322 1.50872958,1.64306395 1.46868318,1.61192599 L1.45906618,1.60343382 L0.816,0.961 L0.174267151,1.60343382 C0.134401129,1.64329984 0.0697655375,1.64329984 0.0298995161,1.60343382 C-0.00689988833,1.56663441 -0.00973061174,1.50872958 0.0214073458,1.46868318 L0.0298995161,1.45906618 L0.672,0.816 L0.0298995161,0.174267151 C-0.00996650536,0.134401129 -0.00996650536,0.0697655375 0.0298995161,0.0298995161 C0.0666989205,-0.00689988833 0.124603757,-0.00973061174 0.164650152,0.0214073458 L0.174267151,0.0298995161 L0.816,0.672 L1.45906618,0.0298995161 C1.4989322,-0.00996650536 1.5635678,-0.00996650536 1.60343382,0.0298995161 Z"
          id="path-1"
        />
      </defs>
      <g
        id="-Prototype-v2--Ready-for-Copy-Review"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="1.0.1---Touchpoints-Selection---Active"
          transform="translate(-439.000000, -573.000000)"
        >
          <g id="Group-7" transform="translate(112.000000, 344.000000)">
            <g id="Group-6" transform="translate(303.000000, 24.000000)">
              <g id="Group-3-Copy-6" transform="translate(16.000000, 197.000000)">
                <g id="phone" transform="translate(8.000000, 8.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#808080"
                    fill="#FFFFFF"
                    x="0.5"
                    y="0.5"
                    width="28"
                    height="50"
                    rx="5"
                  />
                  <text
                    id="9:41"
                    fontFamily="Barlow-Regular, Barlow"
                    fontSize="8"
                    fontWeight="normal"
                    fill="#000000"
                    fillOpacity="0.55"
                  >
                    <tspan x="7.824" y="15">
                      9:41
                    </tspan>
                  </text>
                  <rect id="Rectangle" fill={color} x="3" y="19" width="23" height="6" rx="1" />
                  <rect
                    id="Rectangle"
                    fill="#808080"
                    x="11"
                    y="1.95"
                    width="7"
                    height="1"
                    rx="0.5"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
