import { createStore } from 'effector';
import { getLiquidLogicAttributesFx } from '../../services/liquidLogic/GetLiquidLogicAttributesFx';
import { resetLiquidLogicAttributes } from './LiquidLogicAttributesEvents';
import { ILiquidLogicAttributesProps } from './LiquidLogicAttributesState';

const initialState: ILiquidLogicAttributesProps = {
  data: [],
  hasError: false
};

const $LiquidLogicAttributesStore = createStore<ILiquidLogicAttributesProps>(initialState)
  .reset(resetLiquidLogicAttributes)
  .on(getLiquidLogicAttributesFx.doneData, (_, payload) => ({
    data: payload,
    hasError: false
  }))
  .on(getLiquidLogicAttributesFx.fail, () => ({ ...initialState, hasError: true }));

export default $LiquidLogicAttributesStore;
