import { useIntl } from 'react-intl';

export const useFormatEstimatedAudience = (
  estimatedAudience: string | undefined
): string | null => {
  const { formatMessage } = useIntl();
  if (estimatedAudience === undefined) return null;

  const messageId = estimatedAudience === '1' ? 'audience.poc' : 'audience.pocs';
  const estimatedAudienceMessage = `${estimatedAudience} ${formatMessage({ id: messageId })}`;

  return estimatedAudienceMessage;
};
