import { makeStyles } from '@material-ui/core';

export const pushStyles = makeStyles(() => ({
  row: {
    display: 'grid',
    width: 'min(100%, 368px)',
    gap: 32,
    gridTemplateColumns: '1fr 1fr'
  }
}));

export default pushStyles;
