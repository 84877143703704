import { InfoDataProps } from './InfoData.d';
import { ContentData, Info, Title } from './InfoData.styles';

export default function InfoData({ title, value }: InfoDataProps) {
  return (
    <ContentData>
      <Title>{title}</Title>
      <Info>{value}</Info>
    </ContentData>
  );
}
