import { useLogService as logService } from 'admin-portal-shared-services';
import Api from '../../Api/Api';
import { GET_BANNER_IMAGES } from '../../constants';
import { CmsBanners } from '../../domain/channel';
import { GetBannerImages } from './BannerImagesService.d';

const getBannerImages = async (
  id: string,
  cmsBanners: CmsBanners[],
  immutableGuid?: string
): Promise<GetBannerImages> => {
  const log = logService();

  try {
    const response = await Api.get({
      url: `${GET_BANNER_IMAGES}`,
      config: {
        params: { immutable_guid: immutableGuid },
        responseType: 'blob'
      }
    });

    const responseData: GetBannerImages = {
      data: { url: '', name: '' },
      id
    };

    if (response.data.type === 'image/gif') {
      const gif = cmsBanners.find((banner) => banner.immutable_guid === immutableGuid);
      responseData.data = { url: gif.preview_images[0], name: gif.module_name[0] };
    } else {
      responseData.data = response.data;
    }

    return responseData;
  } catch (error) {
    log.error('get banner preview images', error);
    return error;
  }
};

export default getBannerImages;
