import { Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import Duration from '../../Duration/Duration';
import Label from '../../Label/Label';
import { SponsoredBDRProps } from './SponsoredBDR.d';

export default function SponsoredBDR({ content }: SponsoredBDRProps) {
  const { formatMessage } = useIntl();

  return (
    <div data-testid="sponsored-bdr-container">
      <Duration start={content.start} end={content.end} />
      <Label text={formatMessage({ id: 'sponsoredBDR.describeInput.label' })}>
        <Paragraph>{content.name}</Paragraph>
      </Label>
    </div>
  );
}
