import { useEffect, useMemo, useState } from 'react';

export const intersectionObserverCallback =
  (callback: (...args: any[]) => any) => (entries: IntersectionObserverEntry[]) => {
    const first = entries[0];

    if (first.isIntersecting) {
      callback();
    }
  };

export const useInfiniteScroll = (callback: (...args: any[]) => any, dependency?: any) => {
  const [lastElement, setLastElement] = useState(null);

  const observer = useMemo(
    () => new IntersectionObserver(intersectionObserverCallback(callback)),
    [callback]
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement, dependency, observer]);

  return setLastElement;
};
