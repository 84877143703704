import { makeStyles } from '@material-ui/core/styles';

export const breadCrumbStyles = makeStyles(() => ({
  breadCrumbContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '24px',
    marginBottom: '16px'
  }
}));
