export const AudienceColor = [
  '#B070FF',
  '#3B13AA',
  '#0F55BA',
  '#940079',
  '#D17A00',
  '#0084D1',
  '#949400',
  '#006664',
  '#654C29',
  '#FF6105'
];

export const getAudienceColorByIndex = (index: number) => {
  if (index >= AudienceColor.length) {
    return AudienceColor[index % AudienceColor.length];
  }
  return AudienceColor[index];
};
