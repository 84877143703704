import { makeStyles } from '@material-ui/core';

export const inAppStyles = makeStyles(() => ({
  row: {
    display: 'grid',
    width: 'min(100%, 368px)',
    gap: 32,
    gridTemplateColumns: '1fr 1fr'
  },
  preview: {
    width: '236px',
    height: '100%',
    objectFit: 'cover',
    marginBottom: 32
  }
}));

export default inAppStyles;
