import { ImageProps } from '../Image.d';

export function BannerImage({ width = '29px', height = '51px', color = '#4ABEFF' }: ImageProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 51"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Home banner icon</title>
      <g
        id="-Prototype-v2--Ready-for-Copy-Review"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="1.0.1---Touchpoints-Selection---Active"
          transform="translate(-439.000000, -440.000000)"
        >
          <g id="Group-7" transform="translate(112.000000, 344.000000)">
            <g id="Group-6" transform="translate(303.000000, 24.000000)">
              <g id="Group-3-Copy-5" transform="translate(16.000000, 64.000000)">
                <g id="phone" transform="translate(8.000000, 8.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#808080"
                    fill="#FFFFFF"
                    x="0.5"
                    y="0.5"
                    width="28"
                    height="50"
                    rx="5"
                  />
                  <rect id="Rectangle" fill={color} x="1" y="5" width="27" height="12" />
                  <rect
                    id="Rectangle"
                    fill="#808080"
                    x="11"
                    y="1.95"
                    width="7"
                    height="1"
                    rx="0.5"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
