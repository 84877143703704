import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core';

export const contentSectionStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '24px',
    width: 'min(100%, 800px)',
    overflow: 'hidden',
    maxWidth: '1080px',
    '@global': {
      '*::-webkit-scrollbar': {
        width: '8px'
      },
      '*::-webkit-scrollbar-track': {},
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.15)',
        borderRadius: '10px'
      }
    }
  },

  grid: {
    display: 'grid',
    width: 'max(75%, 800px)',
    justifyContent: 'center',
    gridTemplateColumns: 'max(30%, 240px) 1fr',
    gridTemplateRows: 'min-content',
    boxShadow: 'none',
    transition: 'box-shadow 0.2s'
  },

  shadow: {
    boxShadow: '0px 0px 24px 6px rgba(0, 0, 0, 0.24)',
    transition: 'box-shadow 0.2s',
    zIndex: 10
  },

  info: {
    overflowY: 'auto',
    paddingLeft: '24px'
    // marginRight: '2px'
  },

  content: {
    gridColumnStart: 2,
    borderBottom: `1px solid ${theme.bzColorInterfaceSurfaceSecondary}`,
    marginTop: 24,

    '&:nth-last-child(1) ': {
      border: 0
    }
  }
}));

export default contentSectionStyles;
