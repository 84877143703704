import { combine, createStore, sample } from 'effector';
import { cloneDeep, uniqBy } from 'lodash';
import { Sku } from '../../domain/sku';
import { getSkus } from '../../services/identity/sku/load/LoadSkuService';
import getPackageSkusFx from '../adsPackage/AdsPackageEffects';
import { $isPackageValid } from '../adsPackage/AdsPackageStore';
import {
  validateCampaignNameFx,
  validateDatesFx,
  verifyIsSaveAndExitDisabledFx
} from './SettingsEffects';
import {
  disabledSkuButton,
  DisabledSkuButtonProps,
  onAddSku,
  onChangeCampaignName,
  onChangeCampaignNameErrorText,
  onChangeEndDate,
  onChangeIsCampaignNameValid,
  onChangeIsDatesValid,
  onChangeIsSkuModalVisible,
  onChangeIsSkuSectionValid,
  onChangeStartDate,
  onRemoveSku,
  resetDisabledButton,
  resetPackageValues,
  resetSettingsStore,
  resetSkuList
} from './SettingsEvents';

interface SkuListPaginate {
  pagination: {
    totalElements: number;
    totalPages: number;
    hasMore: boolean;
  };
}

const initialSkuListPaginate: SkuListPaginate = {
  pagination: {
    totalElements: 0,
    totalPages: 0,
    hasMore: false
  }
};

const initialState: DisabledSkuButtonProps = {
  isDisabled: false
};

const $campaignName = createStore<string>('').reset(resetSettingsStore);
$campaignName.on(onChangeCampaignName, (_, payload) => payload);

export const $isCampaignNameValid = createStore<boolean>(false).reset(resetSettingsStore);
$isCampaignNameValid.on(onChangeIsCampaignNameValid, (_, payload) => payload);

const $campaignNameErrorText = createStore<string>('').reset(resetSettingsStore);
$campaignNameErrorText.on(onChangeCampaignNameErrorText, (_, payload) => payload);

const $startDate = createStore<string>('').reset([resetSettingsStore, resetPackageValues]);
$startDate.on(onChangeStartDate, (_, payload) => payload || '');

const $endDate = createStore<string>('').reset([resetSettingsStore, resetPackageValues]);
$endDate.on(onChangeEndDate, (_, payload) => payload || '');

const $isSkuModalVisible = createStore<boolean>(false).reset(resetSettingsStore);
$isSkuModalVisible.on(onChangeIsSkuModalVisible, (_, payload) => payload);

const $skuList = createStore<Sku[]>([]).reset([resetSkuList, resetSettingsStore]);

$skuList
  .on(getSkus.doneData, (state, { content }) => [...state, ...content])
  .on(getSkus.fail, () => [])
  .on(getPackageSkusFx.doneData, (_, payload) => payload)
  .on(getPackageSkusFx.fail, () => []);

export const $skuPaginate = createStore<SkuListPaginate>(initialSkuListPaginate).reset([
  resetSkuList,
  onChangeIsSkuModalVisible,
  resetSettingsStore
]);
$skuPaginate
  .on(getSkus.doneData, (_, { pagination: { totalElements, totalPages, page } }) => ({
    pagination: {
      totalElements,
      totalPages,
      hasMore: page + 1 < totalPages
    }
  }))
  .on(getSkus.fail, () => initialSkuListPaginate);

export const $selectedSkus = createStore<Sku[]>([]).reset([resetSettingsStore, resetPackageValues]);
$selectedSkus
  .on(onAddSku, (state, payload) => {
    return payload ? cloneDeep(uniqBy([...state, ...payload], 'goal_input_id')) : state;
  })
  .on(onRemoveSku, (state, payload) =>
    cloneDeep(state.filter((sku) => sku.goal_input_id !== payload))
  );

export const $isDatesValid = createStore<boolean>(false).reset(resetSettingsStore);
$isDatesValid.on(onChangeIsDatesValid, (_, payload) => payload);

export const $isSkuSectionValid = createStore<boolean>(false).reset(resetSettingsStore);
$isSkuSectionValid.on(onChangeIsSkuSectionValid, (_, payload) => payload);

export const $isDetailsValid = combine(
  $isCampaignNameValid,
  $isSkuSectionValid,
  $isDatesValid,
  $isPackageValid,
  (isCampaignNameValid, isSkuSectionValid, isDatesValid, isPackageValid) =>
    isCampaignNameValid && isSkuSectionValid && isDatesValid && isPackageValid
);

export const $campaignNameValidation = combine(
  $isCampaignNameValid,
  $campaignNameErrorText,
  (isCampaignNameValid, campaignNameErrorText) => ({ isCampaignNameValid, campaignNameErrorText })
);

export const $settingsStore = combine(
  $campaignName,
  $startDate,
  $endDate,
  $isSkuModalVisible,
  $skuList,
  $selectedSkus,
  (campaignName, startDate, endDate, isSkuModalVisible, skuList, selectedSkus) => ({
    campaignName,
    startDate,
    endDate,
    isSkuModalVisible,
    skuList,
    selectedSkus
  })
);

export const $isSaveAndExitDisabled = createStore(false).reset(resetSettingsStore);
$isSaveAndExitDisabled.on(verifyIsSaveAndExitDisabledFx.doneData, (_, payload) => payload);

sample({
  clock: onChangeCampaignName,
  source: $campaignName,
  fn: (campaignName) => campaignName,
  target: validateCampaignNameFx
});

sample({
  source: {
    settingsStore: $settingsStore,
    campaignNameValidation: $campaignNameValidation
  },
  target: verifyIsSaveAndExitDisabledFx
});

sample({
  source: $selectedSkus,
  fn: (skus) => skus.length > 0,
  target: onChangeIsSkuSectionValid
});

sample({
  source: { $startDate, $endDate },
  fn: ({ $startDate, $endDate }) => ({ startDate: $startDate, endDate: $endDate }),
  target: validateDatesFx
});

export const $disabledSkuButtonStore = createStore(initialState).reset(resetDisabledButton);
$disabledSkuButtonStore.on(disabledSkuButton, (state, payload) =>
  cloneDeep({
    ...state,
    ...payload
  })
);
