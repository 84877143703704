import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core';
import { StylesProps } from './AudienceCardSummary.d';

const audienceCardSummaryStyles = makeStyles<any, StylesProps>((theme: Theme) => ({
  container: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
    width: 'min(100%, 232px)',
    borderRadius: '8px',
    border: ({ isCardActive }) =>
      isCardActive ? `1px solid ${theme.bzColorSemanticInfoBasis}` : '1px solid transparent'
  },

  pocs: {
    fontFamily: theme.bzFontFamilyHeading,
    fontWeight: theme.bzFontWeightSemibold,
    fontSize: '22px',
    margin: '0px 16px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '8px',
    marginRight: '16px'
  },
  title: {
    fontFamily: theme.bzFontFamilyHeading,
    fontSize: theme.bzFontSizeBasis,
    fontWeight: theme.bzFontWeightSemibold,
    lineHeight: '20px'
  },
  audienceColor: {
    alignSelf: 'center',
    borderRadius: '5px',
    minWidth: '16px',
    minHeight: '16px'
  },

  divider: {
    backgroundColor: theme.bzColorInterfaceSurfaceSecondary,
    height: '1px',
    width: '100%',
    margin: '12px 0'
  },

  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: theme.bzSpaceMedium
  },

  dots: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '24px',
    paddingTop: '8px'
  }
}));

export default audienceCardSummaryStyles;
