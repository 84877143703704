import { Divider } from '@hexa-ui/components';
import { useFeatureToggleV2 } from 'admin-portal-shared-services';
import { useStore, useStoreMap } from 'effector-react';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { campaignGoalTaxonomy, optimizelyProjectName } from '../../constants';
import useFeatureToggle from '../../hooks/useFeatureToggle/useFeatureToggle';
import EngageToggles from '../../hooks/useFeatureToggle/useFeatureToggle.types';
import useNavigate from '../../hooks/useNavigate/useNavigate';
import { DateFormatter } from '../../presentation/formatters/DateFormatter';
import CampaignStore from '../../stores/campaign/CampaignStore';
import GoalTypeStore from '../../stores/goalType/GoalTypeStore';
import PackageStore from '../../stores/package/PackageStore';
import GoalTypeUseCase from '../../useCases/goalType/GoalTypeUseCase';
import { campaignStoreMapper } from '../../utils/Mappers/campaignStoreMapper';
import { CampaignStatusBadge } from '../CampaignStatusBadge';
import { CustomCard } from '../CustomCard';
import InfoData from '../InfoData/InfoData';
import { DetailsCardProps } from './DetailsCard.d';
import detailsCardStyles, { ContentLineInfo, ContentPackageInfo } from './DetailsCard.style';

export default function DetailsCard({
  isEditEnable = true,
  isFullWidth = false
}: DetailsCardProps) {
  const [showPackageAssociation] = useFeatureToggle(EngageToggles.packageAssociation);

  const [showCampaignGoalTaxonomy] = useFeatureToggleV2(
    campaignGoalTaxonomy,
    optimizelyProjectName
  );
  const {
    columnContainer,
    periodHeader,
    periodInfo,
    headerContainerStatus,
    statusCaption,
    bottomCard,
    containerStatus,
    containerCard
  } = detailsCardStyles();
  const { formatMessage } = useIntl();
  const dateFormatter = new DateFormatter();
  const packageStore = useStore(PackageStore);
  const { replace } = useNavigate();

  const goalTypes = useStoreMap({
    keys: [],
    store: GoalTypeStore,
    fn: ({ content }) => content
  });

  const campaignStore = useStoreMap({
    store: CampaignStore,
    keys: [],
    fn: ({ content }) => content
  });

  useEffect(() => {
    const getIcon = async () => {
      await GoalTypeUseCase.execute();
    };
    goalTypes.length < 1 && getIcon();
  }, []);

  const settingsData = useMemo(
    () => campaignStoreMapper(campaignStore, goalTypes, packageStore),
    [goalTypes, campaignStore, packageStore]
  );

  const handleDialogState = () => {
    replace(`${packageStore.packageRoute}/campaign`);
  };

  const formatOptions: Intl.DateTimeFormatOptions = {
    dateStyle: 'medium',
    timeZone: 'UTC'
  };

  return (
    <CustomCard
      title={settingsData.name}
      subtitle={!showCampaignGoalTaxonomy && settingsData.goalName}
      icon={!showCampaignGoalTaxonomy ? settingsData.goalIcon : undefined}
      size={isFullWidth ? 'large' : 'medium'}
      handleEdit={isEditEnable ? handleDialogState : undefined}
    >
      <div className={containerCard} data-testid="details-card">
        <div className={bottomCard}>
          <div className={columnContainer}>
            <span className={periodHeader}>{formatMessage({ id: 'settingsPage.period' })}</span>
            <span className={periodInfo}>
              {dateFormatter.format(settingsData.start?.toString(), formatOptions)} -
              {dateFormatter.format(settingsData.end?.toString(), formatOptions)}
            </span>
          </div>
          <div className={containerStatus}>
            {settingsData.status && (
              <div className={headerContainerStatus}>
                <span className={statusCaption}>{formatMessage({ id: 'customCard.status' })}</span>
                <CampaignStatusBadge status={settingsData.status} />
              </div>
            )}
          </div>
        </div>
      </div>
      {campaignStore.package && showPackageAssociation && (
        <ContentPackageInfo data-testid="package-information">
          <Divider orientation="horizontal" />
          <ContentLineInfo>
            <InfoData
              title={formatMessage({ id: 'campaign.packageName' })}
              value={campaignStore.package.name}
            />
            <InfoData
              title={formatMessage({ id: 'campaign.pageckageId' })}
              value={campaignStore.package.id}
            />
            <InfoData
              title={formatMessage({ id: 'campaign.partnerPackage' })}
              value={campaignStore.package.partnerLegalName}
            />
            <InfoData
              title={formatMessage({ id: 'campaign.packagePeriod' })}
              value={`${dateFormatter.format(
                campaignStore.package.start?.toString(),
                formatOptions
              )} - ${dateFormatter.format(campaignStore.package.end?.toString(), formatOptions)}`}
            />
          </ContentLineInfo>
          <Divider orientation="horizontal" />
        </ContentPackageInfo>
      )}
    </CustomCard>
  );
}
