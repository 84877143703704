export const getApiHost = (): string | undefined => {
  try {
    if (process.env.NODE_ENV === 'development') {
      return process.env.API_HOST;
    }
    return '';
  } catch (error) {
    return '';
  }
};

export const getCmsHost = (): string | undefined => {
  try {
    const cmsUrl = window.localStorage.getItem('cmsHost');
    if (!cmsUrl) throw new Error('');
    return `${window.localStorage.getItem('cmsHost')}/cms-app/api/contentedit/custom/engage`;
  } catch (error) {
    return '';
  }
};
