import { makeStyles } from '@material-ui/core';

const contentCardStyles = makeStyles(() => ({
  content: {
    width: 'min(1080px, 100%)',
    minHeight: 300,
    maxHeight: 800,
    display: 'flex'
  }
}));

export default contentCardStyles;
