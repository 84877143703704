import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core/styles';

export const FullBannerStyles = makeStyles((theme: Theme) => ({
  fullBanner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '4px',
    overflow: 'hidden'
  },

  carouselContainer: {
    width: '100%'
  },

  carouselItem: {
    width: '100%',
    height: '131px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },

  bannerLegend: {
    marginTop: '0px',
    marginBottom: '4px'
  }
}));
