import { Theme } from '@admin-portal-shared-components/types';
import { styled, theme } from '@hexa-ui/theme';
import { makeStyles } from '@material-ui/core/styles';

const spaceItem6 = 6;
const spaceItem20 = 20;

const detailsCardStyles = makeStyles((theme: Theme) => ({
  containerCard: {
    margin: '10px 24px 33px'
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  periodHeader: {
    marginTop: '4px',
    color: theme.bzColorInterfaceLabelSecondary,
    lineHeight: '24px',
    fontSize: '14px',
    fontFamily: 'Work Sans'
  },
  periodInfo: {
    marginTop: '4px',
    lineHeight: '24px',
    fontSize: '16px',
    fontFamily: theme.bzFontFamilyHeading,
    fontWeight: theme.bzFontWeightSemibold
  },
  headerContainerStatus: {
    display: 'flex',
    flexDirection: 'column'
  },
  statusCaption: {
    fontFamily: '"Work Sans", "San Francisco", Roboto, "Segoe UI", Helvetica, sans-serif',
    color: theme.bzColorInterfaceLabelSecondary,
    fontSize: theme.bzFontSizeSmall,
    lineHeight: theme.bzFontLineHeightLarge
  },
  bottomCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  containerStatus: {
    display: 'flex',
    justifyContent: 'start'
  }
}));

export default detailsCardStyles;

export const ContentPackageInfo = styled('div', {
  padding: `0px ${theme.space[spaceItem6]} ${theme.space[spaceItem6]}`
});

export const ContentLineInfo = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  paddingTop: theme.space[spaceItem6],
  gap: theme.space[spaceItem20],
  rowGap: '0px'
});
