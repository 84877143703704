import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core';

const sectionHeaderStyles = makeStyles<Theme>((theme) => ({
  header: {
    width: '100%',
    display: 'flex',
    minHeight: ' 64px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '24px'
  },

  title: {
    marginRight: 8,
    color: theme.bzColorInterfaceLabelPrimary,
    fontSize: theme.bzFontSizeMedium,
    fontWeight: theme.bzFontWeightSemibold,
    lineHeight: theme.bzFontLineHeightMedium,
    fontFamily: theme.bzFontFamilyHeading
  },
  edit: {
    cursor: 'pointer',
    color: theme.bzColorSemanticInfoText,
    fontSize: theme.bzFontSizeSmall,
    fontWeight: theme.bzFontWeightMedium
  }
}));

export default sectionHeaderStyles;
