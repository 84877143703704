import { useLogService as logService } from 'admin-portal-shared-services';
import Api from '../../../Api/Api';
import { GOALS_ENDPOINT, SUCCESS_CODE } from '../../../constants';

export const getAllGoalType = async <Response>(): Promise<Response> => {
  const log = logService();
  let resp: any;
  try {
    const response = await Api.get({ url: GOALS_ENDPOINT });
    resp = response;
  } catch (error) {
    log.error('get-all-goal-type', error);
    resp = error;
  }

  return new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(resp.status)) {
      resolve(resp.data);
    } else {
      reject(resp);
    }
  });
};
