import { isAfter, parse } from 'date-fns';
import { Sku } from '../../../domain/sku';
import {
  GoalInputResponse,
  PackageResponse
} from '../../../services/partner/packages/PartnerPackagesService.d';
import { PackageStorageProps } from '../PackageState';

export default function createPackageStorageFromPackageResponse(
  packageResponse: PackageResponse
): PackageStorageProps {
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  const startDate = parse(packageResponse.startDate, 'yyyy-MM-dd', todayDate);
  const endDate = parse(packageResponse.endDate, 'yyyy-MM-dd', todayDate);

  return {
    packageId: packageResponse.id,
    packageName: packageResponse.name,
    vendorId: packageResponse.vendor.id,
    partnerLegalName: packageResponse.vendor.legalName,
    packageDetailsUrl: `/ad-campaigns/${packageResponse.id}`,
    packageRoute: `/package/${packageResponse.id}`,
    skus: createSkus(packageResponse.goalInputs),
    minStartDate: isAfter(startDate, todayDate) ? startDate : todayDate,

    maxEndDate: endDate,
    goal: {
      id: packageResponse.goal.id,
      name: packageResponse.goal.name,
      icon: packageResponse.goal.icon
    }
  };
}

export const createSkus = (skusResponse: GoalInputResponse[]): Sku[] => {
  return skusResponse.map((response) => ({
    icon: response.icon,
    description: response.description,
    goal_input_id: response.id,
    value: response.externalId,
    sub_description: response.name
  }));
};
