import { useLogService } from 'admin-portal-shared-services';
import { createEffect } from 'effector';
import Api from '../../../Api/Api';
import { GOALS_ENDPOINT } from '../../../constants';
import { GoalTypePayload } from '../../../domain/goalType';

export const getAllGoalTypeFx = createEffect<void, GoalTypePayload>(async () => {
  const log = useLogService();

  try {
    const { data } = await Api.get({
      url: GOALS_ENDPOINT
    });
    return data;
  } catch (error) {
    log.error('get-goal-type', error);
    return error;
  }
});
