import { Campaign } from '../../../domain/campaign';
import { DateFormatter } from '../../../presentation/formatters/DateFormatter';

export class CampaignDomainAdapter {
  static adapt(item: Campaign): Campaign {
    const dateFormatter = new DateFormatter();
    const formatOptions: Intl.DateTimeFormatOptions = {
      dateStyle: 'medium',
      timeZone: 'UTC'
    };

    let period = '';
    if (item.start || item.end) {
      const start = dateFormatter.format(item.start, formatOptions);
      const end = dateFormatter.format(item.end, formatOptions);
      period = `${start} - ${end}`;
    }

    return {
      ...item,
      period
    };
  }
}
