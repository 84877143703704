import { isAfter, isEqual, parse } from 'date-fns';

export function getDateWithoutTime(date: Date): Date {
  return date ? new Date(date.getFullYear(), date.getMonth(), date.getDate()) : undefined;
}

export function parseDateFromAPI(date: string): Date {
  return parse(date, 'yyyy-MM-dd', getDateWithoutTime(new Date()));
}

export function isDatesRangeValid(date: string): boolean {
  let isValid = false;
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  const todayYear = todayDate.getFullYear();
  const todayMonth = todayDate.getMonth();
  const todayDay = todayDate.getDate();

  const lastMomentOfTheDay = {
    hours: 23,
    minutes: 59,
    seconds: 59,
    milliseconds: 999
  };

  const { hours, minutes, seconds, milliseconds } = lastMomentOfTheDay;

  const dateLimit = new Date(
    todayYear + 1,
    todayMonth,
    todayDay,
    hours,
    minutes,
    seconds,
    milliseconds
  );
  // eslint-disable-next-line no-underscore-dangle
  const _date = parse(date, 'yyyy-MM-dd', todayDate);
  if ((isAfter(_date, todayDate) || isEqual(_date, todayDate)) && isAfter(dateLimit, _date)) {
    isValid = true;
  }
  return isValid;
}
