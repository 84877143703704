import { useCallback } from 'react';
import { BreadcrumbItemTextProps } from './BreadcrumbItemText.d';
import { breadCrumbItemTextStyles } from './styles';

export function BreadcrumbItemText({ text, handleClick, url }: BreadcrumbItemTextProps) {
  const { breadItem, breadItemHome } = breadCrumbItemTextStyles();

  const onClick = useCallback(() => {
    if (typeof url === 'string') handleClick(url);
  }, [url, handleClick]);
  return (
    <span
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onClick}
      className={url === 'hub' ? breadItemHome : breadItem}
    >
      {text}
    </span>
  );
}
