import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core';

const touchpointItemStyles = makeStyles<Theme>((theme) => ({
  container: {
    height: '100%',
    gridColumnStart: 1,
    borderBottom: `1px solid ${theme.bzColorInterfaceSurfaceSecondary}`,

    '&:nth-last-child(2) ': {
      border: 0
    }
  },
  content: {
    marginTop: 16,
    display: 'flex',
    height: 'fit-content'
  },
  icon: {
    width: 48,
    height: 64,
    display: 'flex'
  },
  title: {
    maxWidth: '100px',
    fontSize: theme.bzFontSizeBasis,
    fontWeight: theme.bzFontWeightSemibold,
    color: theme.bzColorInterfaceLabelPrimary,
    lineHeight: theme.bzFontLineHeightMedium,
    marginTop: '8px'
  }
}));

export default touchpointItemStyles;
