import { createStore, merge, Store } from 'effector';
import { Trait, TraitMetadata } from '../../domain/trait';
import { getTraitsFx } from '../../services/identity/audiences/TraitsService';
import { onTraitsChangedUseCase } from '../../useCases/audienceManagement/TraitsUseCase';
import {
  openCloseTraitsList,
  openCloseTraitValuesContainer,
  removeFilter,
  resetIsTraitsListOpen,
  resetTraitMetadata,
  resetTraits,
  selectTrait,
  setTraitMetadata
} from './Events';

export const $traits: Store<Trait[]> = createStore([]).reset(resetTraits);

$traits.on(getTraitsFx.doneData, onTraitsChangedUseCase).on(getTraitsFx.fail, () => []);

export const $isTraitsListOpen = createStore(false).reset(resetIsTraitsListOpen);

$isTraitsListOpen.on(openCloseTraitsList, (state) => !state).on(selectTrait, () => false);

export const $traitOpenedMetadata = createStore<TraitMetadata[] | undefined>(null).reset(
  resetTraitMetadata
);
$traitOpenedMetadata.on(setTraitMetadata, (_, payload) => payload);

const closeAll = merge([openCloseTraitValuesContainer, removeFilter]);
closeAll.watch(() => resetIsTraitsListOpen());
