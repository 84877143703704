import { CustomCard } from '../CustomCard';
import { InfiniteList } from '../InfiniteList';
import { ListCardProps, ViewItemProps } from './ListCard.d';
import { listCardStyles } from './ListCard.styles';
import ListCardItem from './components/ListCardItem';

export default function ListCard({ contentList, cardInfo }: ListCardProps) {
  const { listItemContainer } = listCardStyles();

  return (
    <CustomCard
      title={cardInfo.title}
      subtitle={cardInfo.subTitle}
      size={cardInfo.size}
      handleEdit={cardInfo.handleEdit}
    >
      <InfiniteList
        items={contentList}
        containerClassName={listItemContainer}
        renderItem={(item: ViewItemProps) => <ListCardItem key={item.title} item={item} />}
        itemsPerLoad={10}
      />
    </CustomCard>
  );
}
