import { combine, createStore, guard, sample } from 'effector';
import { getPackageListFx } from '../../services/packages/getPackages';
import { resetSettingsStore } from '../settings/SettingsEvents';
import getPackageSkusFx from './AdsPackageEffects';
import {
  onChangeConnected,
  onChangeInputAdsPackage,
  onResetAdsPackageFilteredData,
  onSelectAdsPackage,
  resetAdsPackageStore
} from './AdsPackageEvents';
import { IAdsPackageProps } from './AdsPackageState';

const initialState: IAdsPackageProps = {
  data: [],
  filteredData: [],
  hasError: false,
  selected: null,
  query: '',
  pagination: {
    page: 0,
    pageSize: 10,
    totalElements: 0,
    totalPages: 0
  }
};

export const $AdsPackages = createStore<IAdsPackageProps>(initialState)
  .reset(resetAdsPackageStore)
  .on(getPackageListFx.doneData, (state, payload) => ({
    ...state,
    data: !state.query ? state.data.concat(payload.data) : state.data,
    filteredData: state.query ? state.filteredData.concat(payload.data) : state.filteredData,
    pagination: payload.pagination,
    hasError: false
  }))
  .on(getPackageListFx.fail, () => ({ ...initialState, hasError: true }))
  .on(onChangeInputAdsPackage, (state, payload) => ({
    ...state,
    query: payload.search
  }))
  .on(onResetAdsPackageFilteredData, (state) => ({
    ...state,
    filteredData: []
  }))
  .on(onSelectAdsPackage, (state, payload) => ({
    ...state,
    selected: payload
  }));

export const $isConnected = createStore<boolean>(false).reset(resetSettingsStore);
$isConnected.on(onChangeConnected, (_, payload) => payload);

export const $isPackageValid = combine(
  $isConnected,
  $AdsPackages,
  (isConnected, adsPackage) => !isConnected || !!adsPackage.selected
);

sample({
  clock: onSelectAdsPackage,
  source: $AdsPackages,
  fn: ({ selected }) => {
    return selected?.id;
  },
  target: [getPackageSkusFx]
});

guard({
  clock: onChangeConnected,
  source: $isConnected,
  filter: (state) => !state,
  target: resetAdsPackageStore
});
