import { styled } from '@hexa-ui/theme';

export const Wrapper = styled('div', {
  '& > div:nth-child(2) > div': {
    minWidth: 'fit-content'
  }
});

export const EllipsisContainer = styled('div', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});
