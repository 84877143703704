import { createEvent } from 'effector';
import { Sku } from '../../domain/sku';

export interface DisabledSkuButtonProps {
  isDisabled: boolean;
}

export const resetSettingsStore = createEvent();
export const onChangeCampaignName = createEvent<string>();
export const onChangeStartDate = createEvent<string>();
export const onChangeEndDate = createEvent<string>();
export const onChangeIsSkuModalVisible = createEvent<boolean>();
export const onAddSku = createEvent<Sku[]>();
export const onRemoveSku = createEvent<string>();
export const onChangeIsCampaignNameValid = createEvent<boolean>();
export const onChangeCampaignNameErrorText = createEvent<string>();
export const onChangeIsSkuSectionValid = createEvent<boolean>();
export const onChangeIsDatesValid = createEvent<boolean>();
export const resetSkuList = createEvent();
export const onChangeConnected = createEvent<boolean>();
export const resetConnected = createEvent();
export const disabledSkuButton = createEvent<Partial<DisabledSkuButtonProps>>('disabledSkuButton');
export const resetDisabledButton = createEvent('resetDisabledButton');
export const resetPackageValues = createEvent();
