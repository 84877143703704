/* eslint-disable effector/no-getState */
/* eslint-disable no-cond-assign */
import { LiquidInput } from '../../domain/campaign';
import $LiquidLogicAttributesStore from '../../stores/liquidLogicAttributes/LiquidLogicAttributesStore';

const GET_MARK_TAG_REGEX = /(<mark>|<\/mark>)/gm;

function getVariableNameByDisplayName(displayName: string): string | undefined {
  const attributes = $LiquidLogicAttributesStore.getState();
  const originalValue = attributes.data.find((el) => el.displayName === displayName);
  return originalValue?.variableName;
}

function getDisplayNameByVariableName(variableName: string): string | undefined {
  const attributes = $LiquidLogicAttributesStore.getState();
  const originalValue = attributes.data.find((el) => el.variableName === variableName);
  return originalValue?.displayName;
}

function buildLiquidRegex() {
  const attributes = $LiquidLogicAttributesStore.getState();

  const displayNames = attributes.data.map((el) => el.displayName).join('|');

  const highlightRegex = new RegExp(`{(${displayNames})( / "([^"<>])+"){0,1}}`, 'g');
  const displayNameRegex = new RegExp(`(${displayNames})`, 'g');
  const defaultValueRegex = / \/ ".*"/g;

  return {
    highlightRegex,
    displayNameRegex,
    defaultValueRegex
  };
}

function stringToLiquid(text: string) {
  const data: LiquidInput = {
    text,
    params: []
  };
  let match: RegExpExecArray | null;

  const { defaultValueRegex, displayNameRegex, highlightRegex } = buildLiquidRegex();

  while ((match = new RegExp(highlightRegex).exec(data.text)) !== null) {
    const endPosition = Number(match?.index) + match[0].length;
    const expression = data.text.slice(match.index, endPosition);

    const defaultValue: undefined | string = expression
      .match(defaultValueRegex)
      ?.toString()
      .replace('/', '')
      .replaceAll('"', '')
      .trim();

    const displayName = expression.match(displayNameRegex)?.toString().trim();
    const start = data.text.slice(0, match.index);
    const end = data.text.slice(endPosition);
    const variableName = getVariableNameByDisplayName(displayName);

    data.text = `${start}{{${variableName}}}${end}`;
    data.params.push({
      variableName,
      defaultValue
    });
  }

  return data;
}

function liquidToString(field: LiquidInput) {
  let { text } = field;

  field.params.forEach((el) => {
    const { variableName } = el;
    let textToInclude = getDisplayNameByVariableName(variableName);
    if (el.defaultValue) {
      textToInclude += ` / "${el.defaultValue}"`;
    }

    const regex = new RegExp(`{{${variableName}}}`, 'g');
    text = text.replace(regex, `{${textToInclude}}`);
  });

  return text;
}

function addMarkTagAroundLiquidStatement(text: string) {
  const { highlightRegex } = buildLiquidRegex();
  let newText = text.replace(/\n$/g, '\n\n');
  newText = newText.replace(GET_MARK_TAG_REGEX, '');
  newText = newText.replace(highlightRegex, '<mark>$&</mark>');

  return newText;
}

export {
  addMarkTagAroundLiquidStatement,
  buildLiquidRegex,
  getDisplayNameByVariableName,
  getVariableNameByDisplayName,
  liquidToString,
  stringToLiquid
};
