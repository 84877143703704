/* eslint-disable import/prefer-default-export */
/* eslint-disable react-hooks/rules-of-hooks */
import { useLogService } from 'admin-portal-shared-services';
import { createEffect } from 'effector';
import Api from '../../../Api/Api';
import {
  CREATE_FILTERS_ENDPOINT,
  GET_AUDIENCE_BY_ID,
  SUCCESS_CODE,
  UPDATE_FILTERS_ENDPOINT
} from '../../../constants';
import { Engagement } from '../../../domain/campaign';
import { Filter, FiltersResponse } from '../../../domain/filter';
import CampaignStore from '../../../stores/campaign/CampaignStore';
import formatLegacyAudiences from '../../../utils/Mappers/formatLegacyAudiences/formatLegacyAudiences';

const handlePromise = (resp): Promise<any> =>
  new Promise((resolve, reject) => {
    SUCCESS_CODE.includes(resp.status) ? resolve(resp.data) : reject(resp);
  });

const createFilter = async (filters: Filter[], campaignId: string): Promise<FiltersResponse> => {
  let resp: any;
  try {
    const response = await Api.post({
      url: CREATE_FILTERS_ENDPOINT,
      body: {
        filters,
        campaignId
      }
    });
    resp = response;
  } catch (error) {
    useLogService().error('post-filters', error);
    resp = error;
  }

  return handlePromise(resp);
};

const updateFilter = async (
  engagement: Engagement,
  filters: Filter[],
  campaignId: string
): Promise<FiltersResponse> => {
  let resp: any;
  try {
    const response = await Api.put({
      url: UPDATE_FILTERS_ENDPOINT.replace(':id', engagement.filter_id),
      body: {
        description: engagement.filter_description,
        filters,
        campaignId
      }
    });
    resp = response;
  } catch (error) {
    useLogService().error('put-filters', error);
    resp = error;
  }

  return handlePromise(resp);
};

const upsertFilter = async (
  engagement: Engagement,
  filters: Filter[],
  campaignId: string
): Promise<FiltersResponse> => {
  return !engagement?.filter_id
    ? createFilter(filters, campaignId)
    : updateFilter(engagement, filters, campaignId);
};

export const getLegacyFilters = async (filterId: string) => {
  const response = await Api.get({
    url: UPDATE_FILTERS_ENDPOINT.replace(':id', filterId)
  });

  if (response.hasError) {
    return response;
  }

  const serializedData = formatLegacyAudiences(response.data);

  response.data = serializedData;

  return response;
};

export const get = async (audienceId: string, filterId: string): Promise<FiltersResponse> => {
  let resp: any;

  try {
    const response = await Api.get({
      url: GET_AUDIENCE_BY_ID.replace(':id', audienceId)
    });

    resp = response;

    if (response.hasError) {
      const responseLegacy = await getLegacyFilters(filterId);

      resp = responseLegacy;
    }
  } catch (error) {
    useLogService().error('get-filters', error);
    resp = error;
  }

  return handlePromise(resp);
};

export const upsertAudienceFilterFx = async (
  filters: Filter[],
  campaignId: string
): Promise<FiltersResponse> => {
  const { engagements } = CampaignStore.getState().content;
  return upsertFilter(engagements[0], filters, campaignId);
};

export const getFiltersFx = createEffect<{ audienceId: string; filterId: string }, FiltersResponse>(
  async ({ audienceId, filterId }) => {
    return get(audienceId, filterId);
  }
);

export const duplicateFilters = async (
  audienceId: string,
  campaignId: string,
  filterId: string
): Promise<string> => {
  const originalFilters = await getFiltersFx({ audienceId, filterId });
  return (await createFilter(originalFilters.filters, campaignId)).id;
};
