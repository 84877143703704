enum EngageToggles {
  campaignCampaignGoalTaxonomyCampaignGoalTaxonomyEnable = 'campaing-campaign_goal_taxonomy-campaign_goal_taxonomy_enable',
  customAudience = 'custom-audience',
  redirectCms = 'redirect-cms',
  touchpointPushNotification = 'touchpoint-push-notification',
  touchpointBanner = 'touchpoint-banner',
  touchpointInApp = 'touchpoint-in-app',
  touchpointBdrTasks = 'touchpoint-bdr-tasks',
  bannerReordering = 'banner-reordering',
  traitSkuPrioritizationArrayValue = 'trait-sku-prioritization-array-value',
  campaignFilter = 'campaign-filter',
  newAudiences = 'new-audiences',
  liquidLogic = 'liquid-logic',
  downloadPdfButton = 'download-pdf-button',
  packageAssociation = 'package-association'
}

export default EngageToggles;
