import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core';

const audienceSectionStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    width: 'max(25%, 280px)',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.bzColorNeutral10,
    overflowY: 'auto',
    paddingBottom: '24px',
    paddingRight: '2px'
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    overflowY: 'auto',
    padding: '0 24px'
  },
  header: {
    width: '100%',
    transition: 'box-shadow 0.2s',
    clipPath: 'polygon(0% 0, 100% 0, 100% 200%, 0% 200%)',
    boxShadow: 'none'
  },
  shadow: {
    boxShadow: '0px 0px 24px 6px rgba(0, 0, 0, 0.24)',
    clipPath: 'polygon(0% 0, 100% 0, 100% 200%, 0% 200%)',
    transition: 'box-shadow 0.2s'
  }
}));

export default audienceSectionStyles;
