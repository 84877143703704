import useTrackingService from '../useTrackingService';
import { TriggerEventParam, UseSegmentReturnType } from './useSegment.d';

const useSegment = (): UseSegmentReturnType => {
  const segmentAnalytics = useTrackingService().getAnalyticsTypewriter();

  const triggerEvent = ({ type, payload }: TriggerEventParam) => {
    const SEGMENT_CASES = {
      CAMPAIGN_LISTING_PAGE_VIEWED: () => segmentAnalytics.campaignListingPageViewed(payload),
      CAMPAIGN_SEE_ANALYTICS_CLICKED: () => segmentAnalytics.campaignSeeAnalyticsClicked(payload),
      CAMPAIGN_DETAILS_PAGE_VIEWED_V2: () => segmentAnalytics.campaignDetailsPageViewedV2(payload),
      CAMPAIGN_CREATION_STARTED: () => segmentAnalytics.campaignCreationStarted(payload),
      CAMPAIGN_PRIMARY_GOAL_VIEWED: () => segmentAnalytics.campaignPrimaryGoalPageViewed(payload),
      CAMPAIGN_TYPE_PAGE_VIEWED: () => segmentAnalytics.campaignTypePageViewed(payload),
      CAMPAIGN_SUMMARY_PAGE_VIEWED_V2: () => segmentAnalytics.campaignSummaryPageViewedV2(payload),
      CAMPAIGN_DUPLICATE_CREATION_STARTED: () =>
        segmentAnalytics.campaignDuplicateCreationStarted(payload),
      CAMPAIGN_SETTINGS_PAGE_VIEWED: () => segmentAnalytics.campaignSettingsPageViewed(payload),
      CAMPAIGN_EDITION_STARTED_V2: () => segmentAnalytics.campaignEditionStartedV2(payload),
      CAMPAIGN_CREATION_FINISHED_V2: () => segmentAnalytics.campaignCreationFinishedV2(payload),
      CAMPAIGN_AUDIENCES_PAGE_VIEWED: () => segmentAnalytics.campaignAudiencesPageViewed(payload),
      CAMPAIGN_TOUCHPOINTS_PAGE_VIEWED: () =>
        segmentAnalytics.campaignTouchpointsPageViewed(payload),
      CAMPAIGN_CONTENT_PAGE_VIEWED: () => segmentAnalytics.campaignContentPageViewed(payload),
      BACKWARD_STEPPER_NAVIGATION: () => segmentAnalytics.backwardStepperNavigation(payload),
      CAMPAIGN_EXIT_STEPPER_NAVIGATION: () => segmentAnalytics.exitStepperNavigation(payload),
      FORWARD_STEPPER_NAVIGATION: () => segmentAnalytics.forwardStepperNavigation(payload),
      VIEW_ALL_TARGET_KPIS: () => segmentAnalytics.viewAllTargetKpIsClicked(payload),
      ADD_PERSONALIZATION_CLICKED: () => segmentAnalytics.addPersonalizationClicked(payload),
      ADD_PERSONALIZATION_EXITED: () => segmentAnalytics.addPersonalizationExited(payload),
      ADD_PERSONALIZATION_CONFIRMED: () => segmentAnalytics.addPersonalizationConfirmed(payload),
      DOWNLOAD_PDF_CLICKED: () => segmentAnalytics.downloadPdfButtonClicked(payload),
      PDF_DOWNLOAD_STATUS: () => segmentAnalytics.pdfDownloadStatus(payload),
      UPLOAD_NEW_BANNER_CLICKED: () => segmentAnalytics.uploadNewBannerClicked(payload),
      ADS_PACKAGE_SELECTED: () => segmentAnalytics.adsPackageSelected(payload),
      SORT_INTERACTION: () => segmentAnalytics.sortInteraction(payload),
      ADS_PACKAGE_CONNECTION_INTERACTION: () =>
        segmentAnalytics.adsPackageConnectionInteraction(payload),
      SEARCH_INTERACTION: () => segmentAnalytics.searchInteraction(payload),
      ITEMS_PER_PAGE_CHANGE: () => segmentAnalytics.itemsPerPageChange(payload),
      CAMPAIGN_FILTER_APPLIED: () => segmentAnalytics.campaignFilterApplied(payload),
      ACTION_INTERACTION: () => segmentAnalytics.actionInteraction(payload),
      default: () => {
        throw new Error(`${type} is not compatible with triggerEvent declaration`);
      }
    };
    const hasTypeProperty = Object.prototype.hasOwnProperty.call(SEGMENT_CASES, type);
    SEGMENT_CASES[hasTypeProperty ? type : 'default']();
  };

  return { triggerEvent };
};

export default useSegment;
