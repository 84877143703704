import MessageMap from '../i18n.d';

const CREATE_NEW_CAMPAIGN = 'Create new campaign';
const CAMPAIGN_TYPE = 'Campaign type';
const CAMPAIGN_GOAL = 'Campaign goal';
const START_DATE = 'Start date';
const ADS_PACKAGE = 'Ads package';
const SELECT_ONE = 'Select one';
const AMOUNT = 'Amount';
const INPUT_QUANTITY = 'Input quantity';
const SKU_PRIORITY_VALUE_NUMBER = '{value}; Priority {number}';
const SKU_PRIOTIRY_FROM_TO = 'Priority {from} to {to}';
const TIME_OF_NOTIFICATION = 'Time of notification';
const LIMIT_OF_CHARACTERS_REACHED = 'Limit of characters reached.';
const PLEASE_ONLY_URL = 'Please use only an URL in this field.';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available'
  },

  dashboard: {
    title: 'Campaign Dashboard',
    createCampaignButton: CREATE_NEW_CAMPAIGN,
    analytics: 'See analytics'
  },

  table: {
    tableTitle: 'Campaigns',
    campaignName: 'Campaign name',
    campaignGoal: CAMPAIGN_TYPE,
    campaignId: 'Campaign ID',
    period: 'Period',
    duration: 'Duration',
    status: 'Status',
    actions: 'Actions',
    searchPlaceholder: 'Search',
    emptycampaignList: 'There is no information to show',
    loadingCampaignsList: 'Loading items...',
    sizeOption: 'Rows per page',
    quantityIndicator: 'of',
    connected: 'Connected to {value}'
  },

  campaign: {
    audience: 'Audience',
    campaignName: 'Campaign name',
    campaignNameWith: 'Campaign name with',
    touchpoints: 'Touchpoints',
    characters: 'characters',
    details: 'Details',
    goalType: 'Goal type',
    period: 'Period',
    settings: 'Settings',
    summary: 'Summary',
    title: CREATE_NEW_CAMPAIGN,
    startDate: START_DATE,
    endDate: 'End date',
    campaignInUse: 'Campaign name already in use',
    campaignNameLength: 'Campaign name must have at least 3 characters',
    placeholderDate: 'DD/MM/YYYY',
    partner: 'Partner',
    vendor: {
      label: 'Vendor ID',
      hint: 'The number used by BEES to verify partners.'
    },
    package: 'Package',
    packageName: 'Ads Package',
    pageckageId: 'Package ID',
    partnerPackage: 'Partner',
    packagePeriod: 'Package Period'
  },

  contentPage: {
    choose: 'Choose audience',
    chooseInfo: 'Choose the audience for which you want to enter the campaign content.',
    content: 'Enter content',
    contentInfo: 'Make sure you complete entering all the content information.',
    startDate: START_DATE,
    endDate: 'End date',
    inApp: {
      title: 'In-app',
      subtitle: 'A pop-up image will be displayed on the app.'
    },
    sponsoredBDR: {
      title: 'BDR tasks',
      subtitle: 'A task to be completed by the BDR.'
    },
    banner: {
      title: 'Home banner',
      subtitle: 'A banner will be displayed at the top of the app screen.',
      infoMessage:
        "Banners' display order is set in BEES Content. The number before each banner name indicates its current position."
    },
    push: {
      title: 'Push notifications',
      subtitle: 'A customized message will be displayed at the notification bar.'
    }
  },

  stepper: {
    settings: {
      label: 'Settings',
      detail: 'Set campaign configurations'
    },
    audience: { label: 'Audience', detail: 'Select who will see it' },
    touchpoints: { label: 'Touchpoints', detail: 'Where they will see it' },
    content: { label: 'Content', detail: 'What they will see it' },
    summary: { label: 'Summary', detail: 'Review and publish' }
  },

  goalType: {
    title: CAMPAIGN_GOAL,
    createCampaignButton: 'Create',
    nextCampaignButton: 'Next',
    listTitle: CAMPAIGN_TYPE,
    listSubTitle: 'Choose the campaign type that best suits your expected outcome.'
  },
  primaryGoal: {
    title: CAMPAIGN_GOAL,
    createCampaignButton: 'Create',
    previousCampaignButton: 'Back',
    listTitle: 'Primary goal',
    listSubTitle:
      'Choose the primary goal and the target KPI. This information will potentialize your campaigns’ efficiency and effectiveness.'
  },
  campaignGoalBar: {
    campaignGoal: CAMPAIGN_GOAL,
    type: 'Type',
    primaryGoal: 'Primary goal',
    targetKpi: 'Target KPI',
    viewAllButton: 'View All'
  },

  badge: {
    completed: 'Completed',
    pending: 'Pending'
  },

  buttons: {
    create: 'Create',
    goBack: 'Go back',
    next: 'Next',
    save: 'Save and exit',
    publish: 'Publish',
    submitForApproval: 'Submit for Approval',
    rejectRequest: 'Reject request',
    approveRequest: 'Approve request'
  },
  skuTable: {
    header: 'SKUs',
    button: 'Add SKU',
    searchPlaceholder: 'Search name or SKU',
    product: 'Product',
    skuCode: 'SKU Code',
    remove: 'Remove'
  },
  selectSkuModal: {
    confirm: 'Confirm',
    title: 'Select SKUs',
    searchPlaceholder: 'Search by SKU ID or name',
    selectAll: 'Select all',
    clearSelection: 'Clear selection'
  },
  filter: {
    buttonFilter: 'Filter',
    apply: 'Apply',
    cancel: 'Cancel',
    clearAll: 'Clear all',
    period: {
      tabTitle: 'Period',
      startDate: START_DATE,
      endDate: 'End date',
      addDate: 'Add date'
    },
    campaignType: {
      tabTitle: CAMPAIGN_TYPE,
      drivePurchase: 'Drive purchase',
      driveBehavior: 'Drive behavior'
    },
    status: {
      tabTitle: 'Status'
    },
    adsPackage: {
      tabTitle: ADS_PACKAGE,
      connected: 'Connected to ads package',
      notConnected: 'No ads package'
    },
    chips: {
      campaignType: CAMPAIGN_TYPE,
      status: 'Status',
      period: 'Period',
      startDate: START_DATE,
      endDate: 'End date',
      adsPackage: ADS_PACKAGE
    }
  },
  audience: {
    audience: 'Audience',
    audiences: 'Audiences',
    select: 'Select',
    estimatedPocs: 'Estimated targeted POCs:',
    addFilter: 'Click to add Filter',
    customer: 'Add Customer trait filter',
    preDefined: 'Pre defined audiences',
    customAudience: 'Custom audiences',
    estimatedAudienceFailedStatus:
      'Error estimating target POCs. Edit a filter to make a new estimation.',
    search: 'Search',
    add: 'Add',
    poc: 'POC',
    pocs: 'POCs',
    none: 'Not available',
    operators: {
      greaterThan: 'Greater than',
      lowerThan: 'Less than',
      equals: 'Equal to',
      yes: 'Yes',
      no: 'No'
    },
    countSKUPurchaseTrait: {
      selectLabel: SELECT_ONE,
      inputLabel: AMOUNT,
      listTitle: 'Select product',
      placeholder: INPUT_QUANTITY
    },
    valuesListComparison: {
      selectLabel: SELECT_ONE,
      inputLabel: AMOUNT,
      listTitle: 'Select product',
      placeholder: INPUT_QUANTITY
    },
    traitValuesNumeric: {
      inputLabel: AMOUNT,
      placeholder: INPUT_QUANTITY
    },
    traitValuesPrioritization: {
      inputLabel: 'Priority',
      listTitle: 'SKU',
      placeholder: 'e.g.: 1',
      skuPrioritization: 'SKU Prioritization',
      hint: 'Choose a number between 1 to 10',
      error: `Don't use negative numbers or 0`
    },
    traitPrioritizationRange: {
      priority: 'Priority',
      inputLabelFrom: 'From',
      inputLabelTo: 'To',
      placeholderFrom: 'e.g.: 1',
      placeholderTo: 'e.g.: 10',
      skuPrioritization: 'SKU Prioritization',
      from: 'From',
      to: 'To',
      error: 'Only 1 to 10',
      errorRange: 'Invalid range'
    },
    comparisonTrait: {
      selectLabel: SELECT_ONE,
      inputLabel: AMOUNT,
      placeholder: INPUT_QUANTITY
    },
    hasPurchasedtargetSKU: {
      listTitle: 'Purchased?',
      radiolabel: 'Purchased?',
      optionYes: 'Yes',
      optionNo: 'No'
    },
    traitListObject: {
      selectTitle: 'Select model',
      listTitle: 'Select vendor',
      placeholder: 'Search by vendor'
    },
    engagementScoreTooltip:
      "Indicates user's level of usage and adoption of the BEES customer platform and its features in the last 3 months.",
    selectAll: 'Select All',
    chipCopywrite: {
      ddc: '{value}',
      'sales-uen': '{value}',
      'sales-state': '{value}',
      'sales-region-drv': '{value}',
      'sales-region-geo': '{value}',
      'digital-order-since-comparison': '{operator} {value, plural, one {# day} other {# days}}',
      'digital-order-since': 'Last {value, plural, one {# day} other {# days}}',
      'engagement-score': '{value}',
      'orders-count': '{operator} {value}',
      'purchased-marketplace': '{value}',
      'purchased-marketplace-count': '{operator} {value}',
      'purchased-sku-count': '{operator} {operatorValue}: {value}',
      'purchased-sku': '{value}',
      'sku-priority-id': SKU_PRIORITY_VALUE_NUMBER,
      'sku-priority-range-id': SKU_PRIOTIRY_FROM_TO,
      'sku-priority-introduce-id': SKU_PRIORITY_VALUE_NUMBER,
      'sku-priority-introduce-range-id': SKU_PRIOTIRY_FROM_TO,
      'sku-priority-sustain-id': SKU_PRIORITY_VALUE_NUMBER,
      'sku-priority-sustain-range-id': SKU_PRIOTIRY_FROM_TO,
      'sku-priority-generate-id': SKU_PRIORITY_VALUE_NUMBER,
      'sku-priority-generate-range-id': SKU_PRIOTIRY_FROM_TO,
      'service-model': '{model}: {vendor}',
      'list-boolean-trait': '{value}'
    }
  },
  searchSelect: {
    clear: 'Clear',
    apply: 'Apply',
    search: 'Search'
  },
  settingsPage: {
    period: 'Period',
    to: 'to',
    touchpoints: 'Touchpoints',
    skus: 'SKUs',
    audiences: 'Audiences',
    selected: 'selected',
    packageAssociation: {
      ads: ADS_PACKAGE,
      connect: 'Connect',
      title: 'Please select the ads package name',
      hint: 'Only accepted and in progress ads packages can be selected',
      placeholder: 'Select package',
      packageId: 'Package ID',
      partner: 'Partner',
      packagePeriod: 'Package period',
      modal: {
        title: 'Change package?',
        connectTitle: 'Connect package?',
        beforeBold: 'If you do so, you will',
        bold: 'clear',
        afterBold: 'any information on the following:',
        line1: 'campaign period',
        line2: 'touchpoint dates',
        line3: 'SKUs',
        cancelButton: 'Cancel',
        confirmButton: 'Change package',
        confirmConnectButton: 'Connect package'
      }
    },
    modal: {
      title: 'Edit campaign date range?',
      message: 'This action will reset all the dates set inside the touchpoints.',
      confirmBtn: 'Edit',
      cancelBtn: 'Cancel'
    },
    errorMessage: 'Please, select new dates for this campaign.',
    warningSnackbar:
      'The running dates for this campaign have passed. Select new periods to publish this draft.',
    noResultsFound: 'No results found',
    noPackagesAvaliable: 'There are no packages avaliable'
  },
  confirmModal: {
    campaing: 'The campaign',
    cancelBtn: 'Cancel',
    delete: 'Delete campaign?',
    deleted: "will be deleted. This action can't be undone.",
    deleteConfirm: 'Delete campaign',
    deletedConfirmation: 'Campaign deleted!',
    cancel: 'Cancel campaign?',
    cancelled: "will be cancelled. This action can't be undone.",
    cancelConfirm: 'Cancel campaign',
    cancelConfirmation: 'Campaign cancelled!'
  },
  toast: {
    draftSaved: 'Draft Saved!',
    campaignPublished: 'Campaign created!',
    campaignSubmitted: 'Campaign submitted!',
    campaignApproved: 'Campaign approved!',
    campaignRejected: 'Campaign rejected!',
    error: 'Something went wrong. Please try again.'
  },
  breadCrumb: {
    home: 'Home',
    dashboard: 'Campaign dashboard',
    newCampaign: CREATE_NEW_CAMPAIGN,
    packageSummary: 'Package summary',
    campaignDetails: 'Campaign details'
  },
  leaveModal: {
    title: 'Leave campaign creation?',
    content: 'All unsaved changes will be lost.',
    leaveButton: 'Leave',
    cancelButton: 'Cancel'
  },
  bannerCard: {
    message: 'These banners will be placed at the top of the homepage in the customer app.',
    cmsLink: 'Banner selection',
    deleteBanner: 'Delete banner',
    placeholder: 'Choose a banner',
    uploadLink: 'Upload new Banner',
    newBanner: 'Add new banner',
    loading: 'Loading...',
    refreshBannersButton: 'Refresh banner list'
  },
  touchpoint: {
    count: {
      one: 'trait',
      many: 'traits'
    },
    deletion: {
      title: 'Delete touchpoint?',
      message: 'This action will delete this touchpoint and all of its content.',
      confirmBtn: 'Delete',
      cancelBtn: 'Cancel',
      deletedConfirmation: 'Touchpoint deleted!'
    },
    items: {
      banner: {
        name: 'Home banner',
        description: 'A Banner will bel placed at the top of the app screen'
      },
      inApp: {
        name: 'In-app',
        description: 'A pop-up image that will appear for the user at the top'
      },
      push: {
        name: 'Push notifications',
        description: 'A customized message that will appear for users at the notification bar'
      },
      sponsoredBDR: {
        name: 'BDR tasks',
        description: 'A task to be completed by the BDR',
        type: 'sponsoredBDR'
      }
    },
    traits: 'Traits',
    viewAll: 'View all',
    selectTouchpointModal: {
      selectedAudience: 'Selected audience',
      audienceInfoText: 'Check the information about the audience you selected',
      selectTouchpoints: 'Select Touchpoints',
      touchpointsInfoText1: 'Select where your campaign will be displayed.',
      touchpointsInfoText2:
        'Content customization for each touchpoint will be available on the next step.',
      selectAll: 'Select all',
      clearAll: 'Clear selection',
      back: 'Back',
      apply: 'Apply'
    },

    touchPointCard: {
      selectButton: 'Select touchpoints',
      editButton: 'Edit',
      badge: {
        pending: 'Pending',
        singularCount: 'Touchpoint',
        pluralCount: 'Touchpoints'
      },
      placeholder: {
        header: 'No touchpoints selected yet',
        content: 'The touchpoints will be listed here after you select them.'
      }
    },
    comingSoon: 'Coming soon',
    selectBanner: 'Select a Banner to see preview or',
    clickToUpload: 'click to upload',
    aNewOne: 'a new one',
    type: 'Type',
    title: 'Title',
    banner: 'Banner',
    pushNotification: 'Push notification',
    inApp: {
      type: 'In App Message',
      title: 'Title',
      body: 'Body',
      timebox: TIME_OF_NOTIFICATION,
      frequency: 'Frequency',
      deeplink: 'Deeplink',
      url: 'Image URL',
      addPersonalization: 'Add personalization'
    },
    message: 'Message'
  },
  bannerTable: {
    bannerName: 'Banner name'
  },
  popoverList: {
    delete: 'Delete',
    duplicate: 'Duplicate',
    cancel: 'Cancel',
    edit: 'Edit',
    analytics: 'Analytics',
    details: 'Details'
  },
  inAppCard: {
    inAppMessage: 'In App',
    titleInput: {
      placeholder: 'Enter a title',
      label: 'Title',
      error: LIMIT_OF_CHARACTERS_REACHED
    },
    imageURLInput: {
      label: 'Image URL',
      placeholder: 'Enter image URL',
      required: PLEASE_ONLY_URL,
      errorInput: {
        url: 'URL is not valid. Please try again.',
        type: 'The file must be in GIF, PNG, or JPEG format. Try again.',
        size: 'The max. height supported is 1200px. Try again.',
        required: PLEASE_ONLY_URL
      },
      errorPreview: {
        url: 'Enter a valid URL to get a preview.',
        type: 'Please use a valid file format to get a preview.',
        size: 'Please use a valid file to get a preview.',
        required: 'Add a URL to get a preview'
      },
      hint1: 'Formats allowed: GIF, PNG, or JPEG files (max. height 1200px).',
      hint2: 'URL must start with “https://”.'
    },
    bodyTextArea: {
      placeholder: 'Enter a text for the notification body',
      label: 'Body',
      error: LIMIT_OF_CHARACTERS_REACHED
    },
    frequencyInput: {
      label: 'Frequency',
      error: 'Frequency must be between 1 and max duration of the campaign!'
    },
    deeplinkInput: {
      placeholder: 'Enter a deeplink for the notification',
      label: 'Deeplink',
      error: PLEASE_ONLY_URL
    },
    timePicker: {
      label: TIME_OF_NOTIFICATION,
      error: 'Invalid time format'
    }
  },
  addPersonalization: {
    title: 'Add personalization',
    modalLabel:
      'You can personalize the message with custom values. These will be excluded from the character count.',
    selectOption: 'Custom value',
    selectPlaceholder: 'Select a custom value',
    defaultValuePlaceholder: 'Add a default value',
    defaultValue: 'Default value',
    defaultValueOptional: '(Optional)',
    preview: 'Preview',
    emptyPreviewFirst: 'No selected personalization.',
    emptyPreviewSecond: 'Try selecting a custom value to preview.',
    buttonGoBack: 'Go back',
    buttonConfirm: 'Confirm',
    tooltip: 'A backup value to be used when the corresponding custom value is empty.'
  },
  sponsoredBDR: {
    sponsoredBDRTitle: 'BDR tasks',
    describeInput: {
      placeholder: 'Enter a description for this task',
      hint: 'Describe the task to be completed by the BDR. Ex.: “Sell 5 cases of Budweisers”.',
      label: 'Task description'
    },
    settingLabel: `The predefined settings for this BDR task are: (1) Type: Simple; (2) Effectiveness check: SKU;
    (3) Category: Value creation; (4) Frequency: Once a month; (5) Duration: Fast.`
  },

  pushCard: {
    pushNotification: 'Push notification',
    image: 'Image',
    titleInput: {
      placeholder: 'Enter a title',
      label: 'Title',
      error: LIMIT_OF_CHARACTERS_REACHED
    },
    bodyTextArea: {
      placeholder: 'Enter a text for the notification body',
      label: 'Body',
      error: LIMIT_OF_CHARACTERS_REACHED
    },
    deepLinkInput: {
      placeholder: ' ',
      label: 'Deeplink',
      error: PLEASE_ONLY_URL
    },
    timePicker: {
      label: TIME_OF_NOTIFICATION
    },
    frequencyInput: {
      label: 'Frequency',
      error: 'Frequency must be between 1 and max duration of the campaign!'
    }
  },
  customCard: {
    edit: 'Edit',
    status: 'Status'
  },
  customDialog: {
    cancel: 'Cancel',
    save: 'Save'
  },
  summaryPage: {
    touchpointsModalTitle: 'Touchpoints',
    audienceModalTitle: 'Audience',
    detailsModalTitle: 'Details',
    skuModalTitle: 'SKUs',
    sections: {
      audience: 'Audiences',
      touchpoint: 'Touchpoints',
      content: 'Content',
      editButton: 'Edit'
    },
    banner: {
      label: 'Content preview'
    }
  },
  statuses: {
    cancelled: 'Cancelled',
    draft: 'Draft',
    ended: 'Ended',
    in_progress: 'In progress',
    loading: 'Loading',
    none: 'None',
    scheduled: 'Scheduled',
    submitted_for_approval: 'Waiting approval',
    execution_rejected: 'Execution rejected',
    error: 'Error'
  },

  touchpointName: {
    push_notification: 'Push Notification',
    homepage_banner: 'Homepage Banner',
    in_app_notification: 'In app notification',
    bees_force: 'Bees Force'
  },
  campaignDetailsPage: {
    title: 'Campaign details'
  },
  dialogSummary: {
    title: 'Review the banners before publishing',
    message: 'Please make sure to re-select the banners you want to use for this campaign.',
    btnCancel: 'Close'
  },
  listCardItem: {
    sku: 'SKU'
  },
  customSelect: {
    selectTargetKPI: 'Select target KPI'
  },
  genericError: {
    errorTitle: 'Page could not be loaded',
    errorMessage: "We're not sure what happened.",
    action: 'Try again',
    buttonAction: 'Reload list',
    genericErrorTitle: 'List could not be loaded',
    subTitleError: "We're not sure what happened. Try reloading."
  },
  pdfMessage: {
    toastSuccess: 'PDF download has started.',
    toastFailure: 'Something went wrong. Try again.'
  },
  pdfDownload: {
    button: 'Download as PDF',
    loading: 'Downloading file'
  },
  alertPackageSelected: {
    title: 'Displaying only SKUs within the ads package selected.'
  },
  pagination: {
    label: 'Items per page: {newSize}'
  },
  csv: {
    csvFile: 'CSV file',
    csvFileDescription: 'Uploaded by CSV file'
  }
};

export default enUS;
