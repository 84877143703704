import { StyleSheet } from '@react-pdf/renderer';

const FONT_FAMILY = 'Work Sans';

export const styles = StyleSheet.create({
  pageHeader: {
    width: '100%'
  },
  body: {
    paddingTop: 35,
    paddingBottom: 45,
    paddingHorizontal: 35,
    fontSize: '12px',
    fontFamily: FONT_FAMILY,
    fontWeight: 700
  },
  campaignName: {
    marginLeft: '0px',
    fontSize: 12,
    fontFamily: 'Barlow',
    fontWeight: 'bold'
  },
  image: {
    width: '40px',
    marginLeft: 'auto'
  },
  pageNumber: {
    fontSize: 6,
    color: '#0000008c',
    marginLeft: '0',
    fontFamily: FONT_FAMILY,
    width: '70px',
    top: '15px'
  },
  downloadDate: {
    fontSize: 6,
    color: '#0000008c',
    fontFamily: FONT_FAMILY
  },
  mainDivider: {
    width: '100%',
    height: 0.8,
    backgroundColor: '#F2F2F2',
    marginBottom: 6.8,
    marginTop: 3.8
  },
  titleCard: {
    color: '#000000',
    fontFamily: 'Barlow',
    fontSize: 7.5,
    fontWeight: 600
  },
  items: {
    color: 'rgba(0,0,0,0.55)',
    fontSize: 4.5,
    lineHeight: 1.5,
    marginTop: 2.3
  },
  table: {
    width: 'auto'
  },
  tableRow: {
    marginBottom: 3,
    flexDirection: 'row'
  },
  tableCol: {
    width: '50%'
  },
  divider: {
    borderTop: '0.8pt solid #F2F2F2'
  },
  contentTouchpoint: {
    width: '25%',
    borderTop: '0.8pt solid #F2F2F2'
  },
  tableCell: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 6,
    fontFamily: 'Barlow',
    fontWeight: 'bold'
  },
  tableContent: {
    color: 'rgba(0,0,0,0.5)',
    fontFamily: FONT_FAMILY,
    fontSize: 6,
    marginTop: 3
  },
  card: {
    borderRadius: 3,
    border: '1px solid #ddd',
    padding: 9,
    marginBottom: 6
  },
  container: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    width: '25%'
  },
  columnTitle: {
    marginBottom: '5px',
    fontSize: 6,
    fontFamily: 'Barlow'
  },
  columnValue: {
    fontFamily: FONT_FAMILY,
    fontSize: 6,
    color: '#585858'
  },
  cardTitle: {
    fontFamily: 'Barlow',
    fontSize: 7.5
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center'
  },
  icon: {
    width: 6.8,
    height: 6.8,
    marginRight: 2
  },
  subtitleCard: {
    fontFamily: 'Barlow',
    fontSize: 7.5
  },
  footerContainer: {
    marginTop: 'auto',
    width: '100vw',
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0
  },
  footerLine: {
    backgroundColor: '#FFFF00',
    height: '5px',
    marginBottom: '3px'
  },
  bottom: {
    marginBottom: 3.0
  },
  contentAudience: {
    width: 120
  },
  contentImpact: {
    width: 80
  },
  contentTrait: {
    color: 'rgba(0,0,0,0.5)',
    fontFamily: FONT_FAMILY,
    fontSize: 6,
    marginTop: 3
  },
  imageBanner: {
    width: 125,
    height: 50,
    marginBottom: 8
  },
  imageContainer: {
    marginTop: 8,
    marginBottom: 0
  },
  pdfLink: {
    marginTop: 10,
    marginBottom: 10
  }
});
