import { ListProps } from './List.d';

export function List<T>({ items, containerClassName, renderItem, children }: ListProps<T>) {
  return (
    <ul className={containerClassName} data-testid="list-items-container">
      {items.map(renderItem)}
      {children}
    </ul>
  );
}
