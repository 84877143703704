import { Paragraph } from '@hexa-ui/components';
import { styled, theme } from '@hexa-ui/theme';

export const ContentData = styled('div', {
  marginBottom: theme.space[6]
});

export const Title = styled(Paragraph, {
  fontSize: '14px !important',
  color: `${theme.colors.interfaceLabelSecondary} !important`
});

export const Info = styled('span', {
  marginBottom: theme.space[6],
  marginTop: theme.space[1],
  lineHeight: theme.space[6],
  fontSize: theme.space[4],
  fontFamily: theme.fonts.heading,
  fontWeight: theme.fontWeights.semibold
});
