import { FormatToViewItemParams } from './formatToViewItem.d';

export const formatToViewItem = (
  content: any[],
  keyTitle: string,
  keySubTitle: string
): FormatToViewItemParams[] =>
  content.map((item) => ({
    title: item[keyTitle],
    subTitle: item[keySubTitle]
  }));
