import { createEffect } from 'effector';
import TraitPrioritizationRangeStoreProps from './TraitValuesStore.d';

const minNumberTrait = 1;
const maxNumberTrait = 10;

const changeTraitPrioritizationRangeFx = createEffect(
  (values: number[]): TraitPrioritizationRangeStoreProps => {
    const [minValue, maxValue] = values;
    const from = minValue > maxNumberTrait ? maxNumberTrait : minValue;
    const to = maxValue > maxNumberTrait ? maxNumberTrait : maxValue;
    const isInvalidFromInput = minValue < minNumberTrait || minValue > maxNumberTrait;
    const isInvalidToInput = maxValue < minNumberTrait || maxValue > maxNumberTrait;
    const isInvalidRangeInput = minValue > maxValue || maxValue < minValue;

    return {
      from,
      to,
      isInvalidFromInput,
      isInvalidToInput,
      isInvalidRangeInput,
      inputValueFrom: minValue,
      inputValueTo: maxValue
    };
  }
);

export default changeTraitPrioritizationRangeFx;
