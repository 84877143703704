import { getAllGoalType } from '../../services/identity/goalType/GoalTypeService';
import { GoalTypesResponse } from '../../services/identity/goalType/GoalTypesResponse';
import {
  loadGoalTypeData,
  loadGoalTypeDataDone,
  loadGoalTypeDataFail
} from '../../stores/goalType/GoalTypeEvents';

const execute = async (): Promise<void> => {
  try {
    loadGoalTypeData();
    const response: GoalTypesResponse = await getAllGoalType();

    loadGoalTypeDataDone({ content: response.content });
  } catch (error) {
    loadGoalTypeDataFail();
  }
};

const GoalTypeUseCase = {
  execute
};

export default GoalTypeUseCase;
