import { useStore } from 'effector-react';
import { useIntl } from 'react-intl';

import { TouchpointEnum } from '../../domain/campaign';
import { $touchpointsPageToggles } from '../../stores/touchpoint/TouchpointStore';
import { ITouchpointsList } from './useTouchpointList.d';

export const useTouchpointsList = (): ITouchpointsList[] => {
  const { formatMessage } = useIntl();
  const toggles = useStore($touchpointsPageToggles);

  const options = [
    {
      name: formatMessage({ id: 'touchpoint.items.banner.name' }),
      description: formatMessage({ id: 'touchpoint.items.banner.description' }),
      type: TouchpointEnum.HOMEPAGE_BANNER
    },
    {
      name: formatMessage({ id: 'touchpoint.items.inApp.name' }),
      description: formatMessage({ id: 'touchpoint.items.inApp.description' }),
      type: TouchpointEnum.IN_APP_NOTIFICATION
    },
    {
      name: formatMessage({ id: 'touchpoint.items.push.name' }),
      description: formatMessage({ id: 'touchpoint.items.push.description' }),
      type: TouchpointEnum.PUSH_NOTIFICATION
    },
    {
      name: formatMessage({ id: 'touchpoint.items.sponsoredBDR.name' }),
      description: formatMessage({ id: 'touchpoint.items.sponsoredBDR.description' }),
      type: TouchpointEnum.BEES_FORCE
    }
  ];
  const togglesTrue = Object.keys(toggles).filter((key) => toggles[key] === true);

  return options.filter((opt) => togglesTrue.includes(opt.type));
};
