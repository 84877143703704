/* eslint-disable react/require-default-props */
import { Tooltip } from '@hexa-ui/components';
import { TextEllipsisProps } from './TextEllipsis.d';
import { EllipsisContainer, Wrapper } from './TextEllipsis.styles';

export default function TextEllipsis({
  text,
  textClassName = undefined,
  children = undefined,
  minWidth = '100%',
  placement = 'bottom'
}: TextEllipsisProps): JSX.Element {
  return (
    <Wrapper style={{ width: 'fit-content', maxWidth: `min(${minWidth},100%)` }}>
      <Tooltip text={text} placement={placement}>
        {/* The hexa's tooltip is a div, sometimes it can cause the following warning:
         <div> cannot appear as a descendant of <p>. */}
        <EllipsisContainer>
          {children ?? <span className={textClassName}>{text}</span>}
        </EllipsisContainer>
      </Tooltip>
    </Wrapper>
  );
}
