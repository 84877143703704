import { Card, Heading } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { BusinessImage } from '../../assets/img/BusinessImage';
import { MarketplaceImage } from '../../assets/img/MarketplaceImage';
import { ProductImage } from '../../assets/img/ProductImage';
import { CustomCardProps } from './CustomCard.d';
import { customCardStyles } from './styles';

export function CustomCard({
  title,
  subtitle,
  children,
  size,
  handleEdit,
  icon,
  id
}: CustomCardProps) {
  const { formatMessage } = useIntl();
  const {
    customCardMedium,
    customCardSmall,
    customCardLarge,
    headerContainer,
    headerContainerLeft,
    subtitleContainer,
    headerContainerHeading,
    iconContainer,
    editButton
  } = customCardStyles();
  const classNamesSizes = {
    medium: customCardMedium,
    small: customCardSmall,
    large: customCardLarge
  };

  const goalIcons = {
    MarketplaceImage: (
      <div className={iconContainer} data-testid="marketplace">
        <MarketplaceImage size="48px" />
      </div>
    ),
    BusinessImage: (
      <div className={iconContainer} data-testid="business">
        <BusinessImage size="48px" />
      </div>
    ),
    ProductImage: (
      <div className={iconContainer} data-testid="product">
        <ProductImage size="48px" />
      </div>
    )
  };
  return (
    <Card
      id={id}
      border="large"
      elevated="medium"
      className={classNamesSizes[size]}
      data-testid="custom-card"
    >
      <div className={headerContainer}>
        <div className={headerContainerLeft}>
          {icon && goalIcons[icon]}
          <div className={headerContainerHeading}>
            <Heading size="H4">{title}</Heading>
            {subtitle && (
              <Heading size="H5" className={subtitleContainer} data-testid="subtitle-header">
                {subtitle}
              </Heading>
            )}
          </div>
        </div>

        <div className={editButton}>
          {handleEdit && (
            <span role="button" tabIndex={0} onKeyDown={handleEdit} onClick={handleEdit}>
              {formatMessage({ id: 'customCard.edit' })}
            </span>
          )}
        </div>
      </div>
      {children}
    </Card>
  );
}
