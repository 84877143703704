import { createStore, merge } from 'effector';
import { cloneDeep, uniq } from 'lodash';
import { PaginatedResponse } from '../../domain/PaginatedResponse';
import {
  TComparison,
  TraitListObjectExp,
  TraitOperation,
  TraitPrioritization
} from '../../domain/filter';
import { FilterAvailable, TraitValue, TraitValueV2 } from '../../domain/traitValue';
import {
  getFiltersAvailableFx,
  getTraitValuesFx,
  getTraitValuesV2Fx
} from '../../services/identity/audiences/TraitsService';
import {
  executeTraitValuesChangedUseCase,
  executeTraitValuesChangedUseCaseV2
} from '../../useCases/audienceManagement/TraitValuesUseCase';
import changeTraitPrioritizationRangeFx from './Effects';
import {
  changeSearchString,
  changeTraitComparisonValue,
  changeTraitFlag,
  changeTraitNumericValue,
  changeTraitPrioritizationValue,
  clearTraitPrioritizationInputValues,
  deselectTraitValue,
  openCloseTraitValuesContainer,
  openCloseTraitsList,
  removeFilter,
  resetFiltersAvailable,
  resetIsTraitsValuesOpen,
  resetSelectedTraitValues,
  resetTraitMetadata,
  resetTraitValues,
  selectListOfTraitValue,
  selectSingleTraitValue,
  selectTraitBooleanValue,
  selectTraitComparison,
  selectTraitListObject,
  selectTraitValue,
  selectedModel
} from './Events';

import TraitPrioritizationRangeStoreProps from './TraitValuesStore.d';

const initialState = {
  values: [],
  pagination: {
    page: 0,
    has_next: false
  }
};
export const $traitValues =
  createStore<PaginatedResponse<TraitValue>>(initialState).reset(resetTraitValues);

$traitValues
  .on(getTraitValuesFx.doneData, executeTraitValuesChangedUseCase)
  .on(getTraitValuesFx.fail, (_) => initialState);

export const $traitValuesV2 =
  createStore<PaginatedResponse<TraitValueV2>>(initialState).reset(resetTraitValues);

$traitValuesV2
  .on(getTraitValuesV2Fx.doneData, executeTraitValuesChangedUseCaseV2)
  .on(getTraitValuesV2Fx.fail, (_) => initialState);

export const $selectedTraitValues = createStore<TraitValue[]>([]).reset(resetSelectedTraitValues);

$selectedTraitValues
  .on(selectSingleTraitValue, (state, newValue) => uniq([newValue]))
  .on(selectTraitValue, (state, newValue) => uniq([...state, newValue]))
  .on(deselectTraitValue, (state, valueToRemove) =>
    state.filter((value) => value !== valueToRemove)
  )
  .on(selectListOfTraitValue, (_, newValues) => uniq(newValues));

export const $isTraitsValuesOpen = createStore<{ [key: string]: boolean }>({}).reset(
  resetIsTraitsValuesOpen
);

$isTraitsValuesOpen.on(openCloseTraitValuesContainer, (state, filter) => {
  return {
    [filter._id]: !state[filter._id]
  };
});

const closeAll = merge([openCloseTraitsList, removeFilter]);
closeAll.watch(() => {
  resetIsTraitsValuesOpen();
  resetTraitMetadata();
});

const initialStateComparison = {
  operator: TraitOperation.equals,
  value: ''
};

export const $selectedTraitComparison =
  createStore<TComparison>(initialStateComparison).reset(resetSelectedTraitValues);

$selectedTraitComparison
  .on(selectTraitComparison, (state, newOperator) => cloneDeep({ ...state, operator: newOperator }))
  .on(changeTraitComparisonValue, (state, newValue) => cloneDeep({ ...state, value: newValue }));

const initialStatePrioritization = {
  number: '',
  values: ''
};

export const $selectedTraitPrioritization = createStore<TraitPrioritization>(
  initialStatePrioritization
).reset(resetSelectedTraitValues);

$selectedTraitPrioritization.on(changeTraitPrioritizationValue, (state, newValue) =>
  cloneDeep({ ...state, number: newValue })
);

export const $selectedTraitBoolean = createStore<boolean>(true).reset(resetSelectedTraitValues);

$selectedTraitBoolean.on(selectTraitBooleanValue, (_, newValue) => newValue);

export const $selectedTraitFlag = createStore<boolean>(true).reset(resetSelectedTraitValues);
$selectedTraitFlag.on(changeTraitFlag, (_, newValue) => newValue);

export const $selectedTraitNumeric = createStore<number | string>('').reset(
  resetSelectedTraitValues
);
$selectedTraitNumeric.on(changeTraitNumericValue, (_, newValue) => cloneDeep(newValue));

export const $searchString = createStore<string>('').reset(resetSelectedTraitValues);

$searchString.on(changeSearchString, (_, newValue) => newValue);

const initialStatePrioritizationRange: TraitPrioritizationRangeStoreProps = {
  from: 1,
  to: 10,
  inputValueFrom: 1,
  inputValueTo: 10,
  isInvalidFromInput: false,
  isInvalidToInput: false,
  isInvalidRangeInput: false
};
export const $selectedTraitPrioritizationRange = createStore<TraitPrioritizationRangeStoreProps>(
  initialStatePrioritizationRange
).reset(resetSelectedTraitValues);

$selectedTraitPrioritizationRange
  .on(changeTraitPrioritizationRangeFx.doneData, (_, payload) => payload)
  .on(clearTraitPrioritizationInputValues, () => ({
    ...initialStatePrioritizationRange,
    from: 1,
    to: 1,
    inputValueFrom: undefined,
    inputValueTo: undefined
  }));

const initialStateListObject: TraitListObjectExp[] = [];

export const $selectedTraitListObject =
  createStore<TraitListObjectExp[]>(initialStateListObject).reset(resetSelectedTraitValues);

$selectedTraitListObject.on(selectTraitListObject, (state, newValue) => {
  const { traitKey } = newValue;
  const isSelectedItem = state.some((item) => item.traitKey === traitKey);
  const removeItem = state.filter((item) => item.traitKey !== traitKey);

  const newItem = isSelectedItem ? removeItem : [...state, newValue];

  return cloneDeep(newItem);
});

export const $filtersAvailable = createStore<FilterAvailable[]>([]).reset(resetFiltersAvailable);

$filtersAvailable
  .on(getFiltersAvailableFx.doneData, (_, payload) => payload)
  .on(getFiltersAvailableFx.fail, () => []);

const selectedModelInitialState = {
  displayName: '',
  traitKey: ''
};

export const $selectedModel =
  createStore<FilterAvailable>(selectedModelInitialState).reset(resetSelectedTraitValues);

$selectedModel.on(selectedModel, (_, payload) => payload);
