import { styled } from '@hexa-ui/theme';

export const UploadedByCSV = styled('span', {
  fontSize: '14px',
  fontWeight: 600,
  fontFamily: 'Barlow',

  variants: {
    noPaddingTop: {
      false: {
        padding: '10px 15px'
      },
      true: {
        padding: '0px 15px'
      }
    }
  }
});

export const CSVFileLabel = styled('div', {
  fontWeight: 400,
  fontFamily: 'Work Sans',
  fontSize: '16px',
  minWidth: 'fit-content'
});
