/* eslint-disable @typescript-eslint/ban-ts-comment */
import { makeStyles } from '@material-ui/core/styles';

export const listCardStyles = makeStyles((theme) => ({
  listItemContainer: {
    listStyle: 'none',
    // @ts-ignore
    margin: `0 24px ${theme.bzSpaceLarge} 0`,
    // @ts-ignore
    padding: `0 ${theme.bzSpaceMedium} 0 ${theme.bzSpaceLarge}`,
    height: '300px',
    overflow: 'auto',

    '& li': {
      padding: '19px 0 11px',
      borderBottom: '1px solid rgba(0,0,0,0.08)'
    },

    '& li > div > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center'
    },

    '& span': {
      // @ts-ignore
      color: theme.bzColorInterfaceLabelSecondary,
      fontFamily: 'Work Sans',
      fontSize: '12px',
      letterSpacing: '0.03px',
      // @ts-ignore
      lineHeight: theme.bzSpaceLarge
    }
  }
}));
