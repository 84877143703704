import { LoadingBuzz, LoadingDots } from '@hexa-ui/components';
import { LoaderProps } from './Loader.d';
import { useLoaderStyles } from './styles';

export function Loader({ size = 'xxlarge', type = 'buzz' }: LoaderProps) {
  const { container } = useLoaderStyles();
  const loadingRender = {
    buzz: <LoadingBuzz size={size} />,
    dots: <LoadingDots size={size} />
  };
  return (
    <div className={container} data-testid="loading">
      {loadingRender[type]}
    </div>
  );
}
