import { AppHeaderConfigPayload } from 'admin-portal-shared-services';

export const GOALS_ENDPOINT = '/api/audience-management-business-service/v1/goals';
export const GOALS_PRIMARY_CATEGORY =
  '/api/audience-management-business-service/v1/goals-primary-categories';
export const CAMPAIGNS_ENDPOINT = '/api/audience-management-business-service/v1/campaigns';

export const GET_ALL_AUDIENCES = '/api/audience-management-campaign-service/v1/audiences';
export const GET_AUDIENCE_BY_ID = '/api/audience-management-business-service/v1/audiences/:id';
export const GET_CUSTOM_FILTER_TRAITS_URL =
  '/api/audience-management-audience-business-service/v1/custom-filter/traits';
export const GET_CUSTOM_FILTER_TRAIT_VALUES_URL =
  '/api/audience-management-custom-filter/v1/traits/:id/values';
export const GET_CUSTOM_FILTER_TRAIT_VALUES_URL_V2 =
  '/api/audience-management-custom-filter/v2/traits/:id/values';
export const GET_RETRIEVE_FILTERS_AVAILABLE =
  '/api/audience-management-custom-filter/v1/traits/:id/filter';
export const CREATE_FILTERS_ENDPOINT = '/api/audience-management-custom-filter/v1/filters';
export const UPDATE_FILTERS_ENDPOINT = '/api/audience-management-custom-filter/v1/filters/:id';
export const GET_ALL_GOAL_INPUTS = '/api/audience-management-business-service/v2/goal-inputs';
export const PARTNER_PACKAGES_ENDPOINT = '/api/partner-advertising-mgmt-app-tier/v2/campaigns';
export const GET_ALL_MODULES = '/modules';
export const GET_CMS_REDIRECT_URL = '/add-module';
export const CALCULATE_ESTIMATED_AUDIENCE =
  '/api/audience-management-custom-filter/v1/estimated-count-calculation';
export const GET_ESTIMATED_AUDIENCE =
  '/api/audience-management-custom-filter/v1/estimated-count-calculation/:id';
export const GET_SUPPORTED_COUNTRIES = '/api/audience-management-business-service/countries';
export const GET_AUDIENCES_LIST =
  '/api/audience-management-audience-business-service/v1/custom-audience/audiences';
export const GET_LIQUID_LOGIC_ATTRIBUTES =
  '/api/audience-management-business-service/v1/notifications/liquid-attributes';
export const CAMPAIGNS_ENDPOINT_V2 = '/api/audience-management-business-service/v2/campaigns';
export const GET_PACKAGES_LIST = '/api/audience-management-business-service/v1/packages';
export const GET_BANNER_IMAGES = '/audience-management-banner-service/banner-images';

export const CODE_200 = 200;
export const CODE_201 = 201;
export const CODE_202 = 202;
export const CODE_204 = 204;
export const CODE_404 = 404;

export const SUCCESS_CODE = [CODE_200, CODE_201, CODE_202, CODE_204];

export const HEADER_VENDOR_ID = 'vendorId';
export const HEADER_X_VENDOR = 'X-Vendor';
export const HEADER_X_BEES_CALLER = 'x-bees-caller';
export const HEADER_X_BEES_ORIGIN = 'x-bees-origin';

export const campaignGoalTaxonomy = 'campaing-campaign_goal_taxonomy-campaign_goal_taxonomy_enable';
export const optimizelyProjectName = 'BEES Engage';
export const customAudienceFT = 'custom-audience';
export const redirectCmsFT = 'redirect-cms';
export const pushNotificationFT = 'touchpoint-push-notification';
export const bannerFT = 'touchpoint-banner';
export const inAppFT = 'touchpoint-in-app';
export const sponsoredBDRFT = 'touchpoint-bdr-tasks';
export const bannerReorderingFT = 'banner-reordering';

export const PROTOCOLS_BEES_URL = ['bees:', 'http:', 'https:'];

export const SEARCH_DEBOUNCE_DELAY = 500;

export const CAMPAIGN_NAME_MAX_LENGTH = 50;

export const SCREEN_NAMES_ENUM = {
  DASHBOARD_PAGE: 'Campaign Dashboard Page',
  AUDIENCES_PAGE: 'Campaign Audiences Page',
  PRIMARY_GOAL_PAGE: 'Campaign Primary Goal Page',
  SETTINGS_PAGE: 'Campaign Settings Page',
  SUMMARY_PAGE: 'Campaign Summary Page',
  TOUCHPOINTS_PAGE: 'Campaign Touchpoints Page',
  TYPE_PAGE: 'Campaign Type Page',
  DETAILS_PAGE: 'Campaign Details Page',
  CONTENT_PAGE: 'Campaign Content Page'
};

export const EMPTY_HUB_HEADER_CONFIG: AppHeaderConfigPayload = {
  breadcrumbConfig: undefined,
  countryOptions: [],
  countrySelect: false,
  optionalButtons: [],
  pageTitle: '',
  vendorSelect: false
};
export const ADS_PACKAGE_TAB_ITEMS = ['connected', 'notConnected'];
export const TYPE_TAB_ITEMS = ['drivePurchase', 'driveBehavior'];
export const STATUS_TAB_ITEMS = [
  'draft',
  'loading',
  'scheduled',
  'in_progress',
  'ended',
  'error',
  'cancelled'
];

export const PRIMARY_BUTTON_COLOR = 'primary';
export const DESTRUCTIVE_BUTTON_COLOR = 'destructive';
export const SECONDARY_BUTTON_COLOR = 'secondary';
export const CHANGE_PACKAGE = 'Change package';
export const PDF_DOWNLOAD_STATUS_ENUM = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL'
};

export const ADS_ENABLED = {
  YES: 'YES',
  NO: 'NO'
};
