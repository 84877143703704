import { createEffect } from 'effector';
import { Sku } from '../../domain/sku';
import { getPackageById } from '../../services/partner/packages/PartnerPackagesService';
import { PackageResponse } from '../../services/partner/packages/PartnerPackagesService.d';
import { createSkus } from '../package/adapters/PackageDomainAdapter';

const getPackageSkusFx = createEffect(async (packageId: string): Promise<Sku[]> => {
  const packageResponse: PackageResponse = await getPackageById(packageId);
  return createSkus(packageResponse?.goalInputs);
});

export default getPackageSkusFx;
