import { Card, LoadingDots, Paragraph } from '@hexa-ui/components';
import { useStoreMap } from 'effector-react';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormatEstimatedAudience } from '../../hooks/useFormatEstimatedAudience';
import { $traits } from '../../stores/audienceManagement/TraitsStore';
import { getAudienceColorByIndex } from '../../utils/getAudienceColorByIndex';
import { AllTraitsModal } from '../AllTraitsModal';
import { UploadedByCSV } from '../CSVLabel/CSVFileLabel.styles';
import { LoadingRender } from '../LoadingRender';
import TextEllipsis from '../TextEllipsis/TextEllipsis';
import { AudienceCardProps } from './AudienceCardSummary.d';
import audienceCardSummaryStyles from './AudienceCardSummary.styles';
import AudienceInfo from './components/AudienceInfo/AudienceInfo';

export default function AudienceCardSummary({
  index,
  estimatedAudience,
  filters,
  isLoading,
  title,
  handleSelectAudience,
  isSelected,
  isCSV
}: AudienceCardProps) {
  const [isCardActive, setIsCardActive] = useState(false);
  const [isAllTraitsModalOpen, setIsAllTraitsModalOpen] = useState(false);

  const styles = audienceCardSummaryStyles({ isCardActive });

  const { formatMessage } = useIntl();

  const handleTraitModalState = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsAllTraitsModalOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    setIsCardActive(false);

    if (isSelected) {
      setIsCardActive(true);
    }
  }, [isCardActive, isSelected]);

  const totalAudience = useFormatEstimatedAudience(estimatedAudience);

  const hasFilters = !!filters.length;

  const traitMetaData = useStoreMap({
    store: $traits,
    keys: [hasFilters],
    fn: (store) =>
      store.find((trait) => {
        if (hasFilters) {
          return trait.id === filters[0].traitId;
        }
        return null;
      })
  });

  const showInfo = !!traitMetaData;

  return (
    <>
      <LoadingRender isLoading={isLoading} fallback={null}>
        <Card
          border="small"
          elevated="small"
          role="button"
          tabIndex={index}
          onKeyDown={handleSelectAudience}
          onClick={handleSelectAudience}
          className={styles.container}
          data-testid="audience-card-summary"
        >
          <div className={styles.header}>
            <div
              style={{ backgroundColor: getAudienceColorByIndex(index) }}
              className={styles.audienceColor}
            />

            <TextEllipsis
              minWidth="calc(100% - 16px)"
              textClassName={styles.title}
              text={title}
              placement="top"
            />
          </div>

          <LoadingRender
            isLoading={!totalAudience}
            fallback={
              <div className={styles.dots} data-testid="loader-dots">
                <LoadingDots />
              </div>
            }
          >
            <Paragraph className={styles.pocs} data-testid="estimated-audience-text">
              {totalAudience}
            </Paragraph>
          </LoadingRender>

          <div className={styles.divider} />

          {isCSV ? (
            <UploadedByCSV noPaddingTop>
              {formatMessage({ id: 'csv.csvFileDescription' })}
            </UploadedByCSV>
          ) : (
            showInfo && (
              <AudienceInfo
                filters={filters}
                title={traitMetaData.name}
                handleViewAllClick={handleTraitModalState}
              />
            )
          )}
        </Card>
      </LoadingRender>

      <AllTraitsModal
        filters={filters}
        isOpen={isAllTraitsModalOpen}
        handleToggle={handleTraitModalState}
      />
    </>
  );
}
