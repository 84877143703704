import { createEffect } from 'effector';
import Api from '../../Api/Api';
import { GET_LIQUID_LOGIC_ATTRIBUTES } from '../../constants';
import { ILiquidLogicAttributesData } from '../../stores/liquidLogicAttributes/LiquidLogicAttributesState';
import { Response } from './GetLiquidLogicAttributesFx.d';

export const getLiquidLogicAttributesFx = createEffect(
  async (): Promise<ILiquidLogicAttributesData[]> => {
    const response: Response<ILiquidLogicAttributesData[]> = await Api.get({
      url: GET_LIQUID_LOGIC_ATTRIBUTES
    });

    return response.data;
  }
);

export default getLiquidLogicAttributesFx;
