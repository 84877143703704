/* eslint-disable jsx-a11y/anchor-is-valid */
import { Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import Link from '../../../../Link/Link';
import Duration from '../../Duration/Duration';
import Label from '../../Label/Label';
import handleDate from '../utils';
import { InAppProps } from './InApp.d';
import { inAppStyles } from './InApp.styles';

export default function InApp({ content }: InAppProps) {
  const styles = inAppStyles();
  const { formatMessage } = useIntl();

  return (
    <div data-testid="in-app-container">
      <Duration start={content.start} end={content.end} />

      <div className={styles.row}>
        <Label text={formatMessage({ id: 'inAppCard.timePicker.label' })}>
          <Paragraph>{handleDate(content.timebox)}</Paragraph>
        </Label>
        <Label text={formatMessage({ id: 'inAppCard.frequencyInput.label' })}>
          <Paragraph>{content.counter}</Paragraph>
        </Label>
      </div>

      {content.deeplink && (
        <Label text={formatMessage({ id: 'inAppCard.deeplinkInput.label' })}>
          <Link url={content.deeplink} />
        </Label>
      )}

      <Label text={formatMessage({ id: 'inAppCard.imageURLInput.label' })}>
        <Link url={content.image_url} />
      </Label>

      <img className={styles.preview} src={content.image_url} alt="preview" />
    </div>
  );
}
