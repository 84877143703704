/* eslint-disable camelcase */
import { TTraitId, TTraitType } from './trait';

export enum TraitOperation {
  lowerThan = 'LOWER_THAN',
  equals = 'EQUALS',
  greaterThan = 'GREATER_THAN'
}

export interface TComparison {
  operator: TraitOperation;
  value: number | string;
}

export interface TraitListExp {
  value: TTraitId;
}

export interface TraitNumericExp {
  value: number;
}

export interface TraitListComparisonExp {
  value: TTraitId;
  comparison: TComparison;
}

export interface TraitListBooleanExp {
  value: boolean;
  flag: boolean;
}

export interface TraitNumericComparisonExp {
  comparison: TComparison;
}

export interface TraitBooleanExp {
  value: boolean;
}

export interface TraitPrioritization {
  values?: string;
  value?: string;
  number?: number | string;
}

export interface TraitPrioritizationRange {
  from: number;
  to: number;
}

export interface TraitListObjectExp {
  filterDisplayName: string;
  filterTraitKey: string;
  displayName: string;
  traitKey: string;
}

export type Expression =
  | TraitListExp
  | TraitListComparisonExp
  | TraitListBooleanExp
  | TraitNumericExp
  | TraitNumericComparisonExp
  | TraitBooleanExp
  | TraitPrioritization
  | TraitPrioritizationRange
  | TraitListObjectExp;

export interface Filter {
  _id: string;
  traitId?: TTraitId;
  traitType?: TTraitType;
  expressions?: Expression[];
}

export interface FiltersResponse {
  id: string;
  description?: string;
  estimatedPoc: string;
  filters: Filter[];
  filterId?: string;
  isCsv?: boolean;
  name?: string;
}
