import { useIntl } from 'react-intl';
import { CampaignStatusBadgeProps } from './CampaignStatusBadge.d';
import { StatusBadge } from './styles';

function CampaignStatusBadge({ status }: CampaignStatusBadgeProps) {
  const { formatMessage } = useIntl();

  return (
    <StatusBadge data-testid="status-badge" status={status}>
      {formatMessage({ id: `statuses.${status.toLowerCase()}` })}{' '}
    </StatusBadge>
  );
}

export { CampaignStatusBadge };
