import { TextButton } from '@hexa-ui/components';
import { Download } from '@hexa-ui/icons';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useCampaignPDF from '../../hooks/useCampaignPDF/useCampaignPDF';
import { DownloadPdfButtonProps } from './DownloadPdfButton.d';
import { DotsContainer } from './DownloadPdfButton.styles';

export default function DownloadPdfButton({
  dataSegmentPDF
}: {
  dataSegmentPDF: DownloadPdfButtonProps;
}) {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const { downloadPdf, isDownloading } = useCampaignPDF({
    ...dataSegmentPDF,
    button_label: formatMessage({ id: 'pdfDownload.button' })
  });

  const download = () => {
    downloadPdf();
  };

  useEffect(() => {
    setIsLoading(isDownloading);
  }, [isDownloading]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const undefinedIcon = () => <></>;

  return (
    <div>
      <TextButton
        data-testid="download-pdf"
        onClick={download}
        iconPosition="leading"
        disabled={isLoading}
        translate={null}
        icon={!isLoading ? Download : undefinedIcon}
        size="large"
      >
        {isLoading && <DotsContainer />}
        {isLoading
          ? formatMessage({ id: 'pdfDownload.loading' })
          : formatMessage({ id: 'pdfDownload.button' })}
      </TextButton>
    </div>
  );
}
