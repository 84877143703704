import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core';

export const traitStyles = makeStyles((theme: Theme) => ({
  trait: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.bzSpaceBasis} 0`,
    gap: theme.bzSpaceBasis,
    '& ul': {
      padding: 0,
      margin: 0,
      listStyle: 'none',
      fontFamily: theme.bzFontFamilyHeading,
      fontSize: '12px',
      lineHeight: '16px'
    },
    '& li': {
      fontFamily: '"Work Sans", "San Francisco", Roboto, "Segoe UI", Helvetica, sans-serif',
      fontSize: '12px',
      letterSpacing: '0.03px',
      lineHeight: '18px'
    }
  }
}));
