import { cloneDeep } from 'lodash';
import type {
  Banner,
  Campaign,
  Content,
  Engagement,
  InApp,
  Push,
  SponsoredBDR,
  TCampaignStatus
} from '../../domain/campaign';
import {
  CampaignsResponse,
  CampaignsSortRequest,
  CampaignsSortedResponse,
  ChangeCampaignStatusRequest,
  PublishCampaignRequest,
  ValidateCampaignNameRequest
} from '../../services/identity/campaigns/CampaignService.d';
import {
  cancelCampaign,
  changeCampaignStatus,
  deleteCampaign,
  getAllCampaigns,
  getAllCampaignsSorted,
  publishCampaign,
  updateCampaign,
  validateName
} from '../../services/identity/campaigns/CampaignsService';
import { fetchCampaignById, fetchCreateCampaign } from '../../stores/campaign/CampaignEffects';
import { addCampaign } from '../../stores/campaign/CampaignEvents';
import CampaignStore from '../../stores/campaign/CampaignStore';
import { resetChannel } from '../../stores/channel/ChannelEvents';
import {
  loadDashboardData,
  loadDashboardDataDone,
  loadDashboardDataFail,
  resetDashboard
} from '../../stores/dashboard/DashboardEvents';
import $DashboardStore from '../../stores/dashboard/DashboardStore';
import { resetSelectedGoalType } from '../../stores/goalType/GoalTypeEvents';
import {
  changeValidName,
  failingNewCampaign,
  loadingNewCampaign,
  validateAudience,
  validateChannel,
  validateContent,
  validateSettings
} from '../../stores/newCampaign/NewCampaignEvents';
import { fetchLoadPackage } from '../../stores/package/PackageEffects';
import { resetSettingsStore } from '../../stores/settings/SettingsEvents';
import $SupportedCountriesStore from '../../stores/supportedCountries/SupportedCountriesStore';
import isPast from '../../utils/validators/date/IsPast';
import { CampaignDomainAdapter } from './adapters/CampaignDomainAdapter';

const validateTouchpoint = {
  HOMEPAGE_BANNER: (touchpoint: Content) => {
    const tPoint = touchpoint as Banner;

    return (
      tPoint?.immutable_guid?.length > 0 && !tPoint?.immutable_guid.some((id) => id === 'DRAFT')
    );
  },
  PUSH_NOTIFICATION: (touchpoint: Content) => {
    const tPoint = touchpoint as Push;
    delete tPoint.deeplink;
    return Object.values(tPoint)?.every((touchpointValue) => touchpointValue);
  },
  IN_APP_NOTIFICATION: (touchpoint: Content) => {
    const tPoint = touchpoint as InApp;
    const filled = tPoint?.image_url.length > 0;

    return filled;
  },
  BEES_FORCE: (touchpoint: Content) => {
    const tPoint = touchpoint as SponsoredBDR;
    const filled = tPoint?.name.length > 0;

    return filled;
  }
};

export const validateContentStep = (engagements: Engagement[]): boolean => {
  return engagements?.every((engage) =>
    engage.touchpoints?.every((touchpoint) => {
      if (validateTouchpoint[touchpoint.touchpoint]) {
        return validateTouchpoint[touchpoint.touchpoint](touchpoint);
      }
      return false;
    })
  );
};

export const validateTouchpointStep = (engagements: Engagement[]): boolean => {
  return engagements?.every((engage) => engage.touchpoints?.length >= 1);
};

const execute = async (): Promise<void> => {
  try {
    loadDashboardData();

    const params = {
      page: $DashboardStore.getState().page,
      size: $DashboardStore.getState().size
    };
    const response: CampaignsResponse = await getAllCampaigns(params);
    const formattedCampaigns = response.content.map(CampaignDomainAdapter.adapt);
    loadDashboardDataDone({ content: formattedCampaigns, totalElements: response.totalElements });
  } catch (error) {
    loadDashboardDataFail();
  }
};

const executeGetCampaignById = async (
  campaignId: string,
  loadPackage = false
): Promise<Campaign> => {
  try {
    loadingNewCampaign({ isLoading: true });

    const campaign = await fetchCampaignById({ campaignId });
    if (loadPackage && campaign.package?.id) {
      await fetchLoadPackage(campaign.package.id);
    }

    loadingNewCampaign({ isLoading: false });

    return campaign;
  } catch (error) {
    failingNewCampaign();
    return undefined;
  }
};

const executeUpdateCampaign = async (): Promise<void> => {
  try {
    loadingNewCampaign({ isLoading: true });

    const data = {
      campaign: {
        ...CampaignStore.getState().content,
        country: $SupportedCountriesStore.getState().selectedCountry.code
      }
    };

    const response: Campaign = await updateCampaign(data);

    addCampaign(response);
    loadingNewCampaign({ isLoading: false });
  } catch (error) {
    failingNewCampaign();
  }
};

const executeDeleteCampaign = async (id, applyFilters): Promise<void> => {
  try {
    loadingNewCampaign({ isLoading: true });
    await deleteCampaign({ id });
    resetDashboard();
    await applyFilters();

    loadingNewCampaign({ isLoading: false });
  } catch (error) {
    failingNewCampaign();
  }
};

const executeCancelCampaign = async (id, applyFilters): Promise<void> => {
  try {
    loadingNewCampaign({ isLoading: true });
    await cancelCampaign({ id });
    await applyFilters();

    loadingNewCampaign({ isLoading: false });
  } catch (error) {
    failingNewCampaign();
  }
};

const executeApproveCampaign = async (): Promise<void> => {
  const publishCampaignRequest: PublishCampaignRequest = {
    campaignId: CampaignStore.getState().content.id,
    vendor: CampaignStore.getState().content.vendor
  };
  await publishCampaign(publishCampaignRequest);
};

const executePublishCampaign = async (): Promise<void> => {
  try {
    const publishCampaignRequest: PublishCampaignRequest = {
      campaignId: CampaignStore.getState().content.id,
      vendor: CampaignStore.getState().content.vendor
    };
    loadingNewCampaign({ isLoading: true });
    await executeUpdateCampaign();
    await publishCampaign(publishCampaignRequest);
    loadingNewCampaign({ isLoading: false });
  } catch (error) {
    failingNewCampaign();
    throw error;
  }
};

const executeValidateName = async (campaignName): Promise<boolean> => {
  try {
    const param: ValidateCampaignNameRequest = {
      campaignName,
      campaignId: CampaignStore.getState().content.id,
      vendor: CampaignStore.getState().content.vendor
    };
    await validateName(param);
    return true;
  } catch (error) {
    return false;
  }
};

const executeEditCampaign = (campaignToEdit: Campaign) => {
  try {
    loadingNewCampaign({ isLoading: true });

    resetSelectedGoalType();
    resetSettingsStore();
    resetChannel();

    addCampaign(campaignToEdit);

    const { name, start, end, campaign_goal } = campaignToEdit;
    const settingsCampaign = !name || !start || !end || campaign_goal?.goal_inputs?.length < 1;

    const isDatePast = isPast(start);

    if (settingsCampaign || isDatePast) {
      return '/campaign';
    }
    changeValidName({ isValid: true });
    validateSettings({
      isValid: true
    });

    if (!campaignToEdit.engagements?.some((engage) => engage?.audience_id)) {
      return '/campaign/audience';
    }
    validateAudience({ isValid: true });

    if (!validateTouchpointStep(cloneDeep(campaignToEdit.engagements))) {
      return '/campaign/channel';
    }
    validateChannel({ isValid: true });

    if (!validateContentStep(cloneDeep(campaignToEdit.engagements))) {
      return '/campaign/content';
    }
    validateContent({ isValid: true });

    loadingNewCampaign({ isLoading: false });
    return '/campaign/summary';
  } catch (error) {
    return '/campaign';
  }
};

const executeChangeCampaignStatus = async (status: TCampaignStatus): Promise<void> => {
  try {
    const changeCampaignStatusRequest: ChangeCampaignStatusRequest = {
      status,
      campaignId: CampaignStore.getState().content.id,
      vendor: CampaignStore.getState().content.vendor
    };
    loadingNewCampaign({ isLoading: true });
    await changeCampaignStatus(changeCampaignStatusRequest);
    loadingNewCampaign({ isLoading: false });
  } catch (error) {
    failingNewCampaign();
    throw error;
  }
};

const executeDuplicateCampaign = async (
  campaign: Campaign,
  callBackSuccess?: (newCampaing: Campaign, oldCampaing: Campaign) => void
): Promise<void> => {
  try {
    const oldCampaing = cloneDeep(campaign);

    const response = await fetchCreateCampaign({
      goalId: oldCampaing.campaign_goal.goal_id,
      goalName: oldCampaing.campaign_goal.goal_name,
      goalIcon: oldCampaing.campaign_goal.goal_icon,
      primaryCategory: oldCampaing.campaign_goal.primary_category
    });

    const { engagements } = oldCampaing;
    if (engagements && engagements[0]?.filter_id) {
      const correctFilterIdEngagement = cloneDeep(oldCampaing.engagements);
      correctFilterIdEngagement[0].engagement_id = null;
      oldCampaing.engagements = correctFilterIdEngagement;
    }

    const skus = oldCampaing.package ? null : oldCampaing.campaign_goal.goal_inputs;

    const duplicatedCampaign: Campaign = {
      ...oldCampaing,
      campaign_goal: {
        ...oldCampaing.campaign_goal,
        goal_inputs: skus
      },
      id: response.id,
      name: '',
      start: null,
      end: null,
      publishing_id: null,
      package: oldCampaing.package
    };

    addCampaign(duplicatedCampaign);
    callBackSuccess(duplicatedCampaign, oldCampaing);
  } catch (error) {
    failingNewCampaign();
  }
};

const executeCampaignSort = async (
  params: Partial<CampaignsSortRequest>,
  callBackSuccess?: (param: CampaignsSortedResponse) => void
) => {
  try {
    loadDashboardData();

    const response: CampaignsSortedResponse = await getAllCampaignsSorted(params);
    const formattedCampaigns = response.content.map(CampaignDomainAdapter.adapt);
    loadDashboardDataDone({
      content: formattedCampaigns,
      totalElements: response.pagination.totalElements
    });
    callBackSuccess(response);
  } catch (error) {
    loadDashboardDataFail();
  }
};

const CampaignUseCase = {
  execute,
  executeGetCampaignById,
  executeDeleteCampaign,
  executeCancelCampaign,
  executeUpdateCampaign,
  executeValidateName,
  executePublishCampaign,
  executeApproveCampaign,
  executeEditCampaign,
  executeChangeCampaignStatus,
  executeDuplicateCampaign,
  executeCampaignSort
};

export default CampaignUseCase;
