import { useEffect, useRef } from 'react';
import { addMarkTagAroundLiquidStatement } from '../../utils/liquidLogic/parser';
import MarkHighlightDiv from './LiquidHighlightText.styles';

export default function LiquidHighlightText({ text }: { text: string }) {
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    divRef.current.innerHTML = addMarkTagAroundLiquidStatement(text);
  }, [text]);

  return <MarkHighlightDiv ref={divRef} />;
}
