import { styled, theme } from '@hexa-ui/theme';

const fontSizeItem = 2;
const lineHeightItem = 3;
const marginTopItem = 1;

export const StatusBadge = styled('span', {
  borderRadius: '16px',
  fontFamily: theme.fonts.paragraph,
  fontSize: theme.fontSizes[fontSizeItem],
  lineHeight: theme.lineHeights[lineHeightItem],
  fontWeight: theme.fontWeights.medium,
  marginTop: theme.space[marginTopItem],
  padding: '4px 8px',
  variants: {
    status: {
      IN_PROGRESS: { backgroundColor: theme.colors.brandAccentColdBackground },
      DRAFT: { backgroundColor: theme.colors.alternativeImagination },
      CANCELLED: { backgroundColor: theme.colors.brandAccentExcitingBackground },
      ENDED: { backgroundColor: theme.colors.neutral20 },
      LOADING: { backgroundColor: theme.colors.brandAccentColdBackground },
      EXECUTION_REJECTED: { backgroundColor: theme.colors.neutral20 },
      SUBMITTED_FOR_APPROVAL: { backgroundColor: theme.colors.neutral20 },
      SCHEDULED: { backgroundColor: theme.colors.semanticWarningBackground },
      NONE: { backgroundColor: theme.colors.neutral20 },
      ERROR: { backgroundColor: theme.colors.brandAccentExcitingBackground }
    }
  }
});
