import { Breadcrumb } from '@hexa-ui/components';
import { useStoreMap } from 'effector-react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { matchPath, PathMatch } from 'react-router-dom';
import useNavigate from '../../hooks/useNavigate/useNavigate';
import CAMPAIGN_ROUTES from '../../pages/CampaignPage/constants/CampaignRoutes';
import CampaignStore from '../../stores/campaign/CampaignStore';
import { canLeavePage } from '../../stores/leaveModal/LeaveModalEffects';
import PackageStore from '../../stores/package/PackageStore';
import { AppRoute } from './Breadcrumb.d';
import { BreadcrumbItemText } from './components/BreadcrumbItemText';
import { breadCrumbStyles } from './styles';

export function BreadcrumbComponent() {
  const {
    replace,
    location: { pathname }
  } = useNavigate();

  const { formatMessage } = useIntl();
  const { breadCrumbContainer } = breadCrumbStyles();
  const campaignPackageId = useStoreMap({
    store: CampaignStore,
    keys: [],
    fn: (store) => store.content.package?.id
  });

  const { packageId, packageRoute } = useStoreMap({
    store: PackageStore,
    keys: [],
    fn: (store) => ({
      packageId: store.packageId,
      packageRoute: store.packageRoute
    })
  });

  const fromPartner =
    window.history.state?.fromPartner || (packageRoute && pathname.startsWith(packageRoute));
  const packageDetailsUrl = `/ad-campaigns/${campaignPackageId ?? packageId}`;
  const campaignRoutes = Object.keys(CAMPAIGN_ROUTES);
  const newCampaignTitle = formatMessage({ id: 'breadCrumb.newCampaign' });

  const getRoutes = (): AppRoute[] => {
    const defaultRoutes: AppRoute[] = [
      {
        path: '/goal',
        title: newCampaignTitle
      },
      {
        path: '/goal/primary',
        title: newCampaignTitle
      },
      {
        path: '/campaign/:campaignId/details',
        title: formatMessage({ id: 'campaignDetailsPage.title' })
      }
    ];

    const routesFromCampaing: AppRoute[] = campaignRoutes.map((el) => {
      return { path: el, title: newCampaignTitle };
    });

    return [...defaultRoutes, ...routesFromCampaing];
  };

  const routes = getRoutes();

  const indexRoute: AppRoute = fromPartner
    ? {
        path: packageDetailsUrl,
        title: formatMessage({ id: 'breadCrumb.packageSummary' })
      }
    : {
        path: '/',
        title: formatMessage({ id: 'breadCrumb.dashboard' })
      };

  let matchResult: PathMatch;

  const firstMatch = routes.find((route) => {
    matchResult = matchPath(
      {
        path: String(route.path),
        end: true,
        caseSensitive: false
      },
      pathname
    );
    return matchResult;
  });

  const handlePageChange = useCallback(
    async (url: string) => {
      if (campaignRoutes.includes(matchResult?.pathname)) {
        await canLeavePage(url);
      } else if (url === 'hub') {
        window.location.href = window.location.origin;
      } else if (url === packageDetailsUrl) {
        window.history.pushState({}, '', packageDetailsUrl);
      } else {
        replace(url);
      }
    },
    [matchResult, packageDetailsUrl, replace, campaignRoutes]
  );

  return (
    <div className={breadCrumbContainer}>
      <Breadcrumb.Root>
        <Breadcrumb.HomeItem asChild>
          <BreadcrumbItemText
            text={formatMessage({ id: 'breadCrumb.home' })}
            url="hub"
            handleClick={handlePageChange}
          />
        </Breadcrumb.HomeItem>

        <Breadcrumb.Item asChild isCurrentPage={pathname === '/'}>
          <BreadcrumbItemText
            text={indexRoute.title}
            url={fromPartner ? packageDetailsUrl : '/'}
            handleClick={handlePageChange}
          />
        </Breadcrumb.Item>

        {firstMatch && (
          <Breadcrumb.Item asChild isCurrentPage>
            <span>{firstMatch.title}</span>
          </Breadcrumb.Item>
        )}
      </Breadcrumb.Root>
    </div>
  );
}
