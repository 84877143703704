import { useStore, useStoreMap } from 'effector-react';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Content, TouchpointEnum } from '../../../../domain/campaign';
import useScroll from '../../../../hooks/useScroll/useScroll';
import useTouchpointsOrder, {
  UseTouchpointsOrderReturn
} from '../../../../hooks/useTouchpointsOrder/useTouchpointsOrder';
import getLiquidLogicAttributesFx from '../../../../services/liquidLogic/GetLiquidLogicAttributesFx';
import CampaignStore from '../../../../stores/campaign/CampaignStore';
import $selectedAudienceSummary from '../../../../stores/summary/SummaryStore';

import { LoadingRender } from '../../../LoadingRender';
import Banner from '../Content/Banner/Banner';
import InApp from '../Content/InApp/InApp';
import Push from '../Content/Push/Push';
import SponsoredBDR from '../Content/SponsoredBDR/SponsoredBDR';
import SectionHeader from '../SectionHeader/SectionHeader';
import { ContentSectionProps } from './ContentSection.d';
import contentSectionStyles from './ContentSection.styles';
import TouchpointItem from './components/TouchpointItem/TouchpointItem';

export default function ContentSection({ isEditable }: ContentSectionProps) {
  const { formatMessage } = useIntl();
  const styles = contentSectionStyles();

  const audienceId = useStore($selectedAudienceSummary);

  const touchpoints = useStoreMap({
    store: CampaignStore,
    keys: [audienceId],
    fn: ({ content }) =>
      content.engagements?.find((engagement) => engagement.audience_id === audienceId)
        ?.touchpoints ?? []
  });

  const convertedData = useMemo(() => {
    const convertedArr = touchpoints.map((data) => ({
      id: data.touchpoint_id,
      type: TouchpointEnum[data.touchpoint],
      ...data
    }));
    return convertedArr;
  }, [touchpoints]);

  const valuesToRender = useTouchpointsOrder(convertedData);

  const renderContent = useCallback(
    (content: UseTouchpointsOrderReturn<Content>) => {
      const { prefix } = content;
      switch (content.touchpoint) {
        case 'BEES_FORCE':
          return (
            <Fragment key={`${TouchpointEnum.BEES_FORCE}-${prefix}`}>
              <TouchpointItem type={TouchpointEnum.BEES_FORCE} prefix={prefix} />
              <div className={styles.content}>
                <SponsoredBDR content={content} />
              </div>
            </Fragment>
          );
        case 'HOMEPAGE_BANNER':
          return (
            <Fragment key={`${TouchpointEnum.HOMEPAGE_BANNER}-${prefix}`}>
              <TouchpointItem type={TouchpointEnum.HOMEPAGE_BANNER} prefix={prefix} />
              <div className={styles.content}>
                <Banner content={content} />
              </div>
            </Fragment>
          );
        case 'PUSH_NOTIFICATION':
          return (
            <Fragment key={`${TouchpointEnum.PUSH_NOTIFICATION}-${prefix}`}>
              <TouchpointItem type={TouchpointEnum.PUSH_NOTIFICATION} prefix={prefix} />
              <div className={styles.content}>
                <Push content={content} />
              </div>
            </Fragment>
          );
        case 'IN_APP_NOTIFICATION':
          return (
            <Fragment key={`${TouchpointEnum.IN_APP_NOTIFICATION}-${prefix}`}>
              <TouchpointItem type={TouchpointEnum.IN_APP_NOTIFICATION} prefix={prefix} />
              <div className={styles.content}>
                <InApp content={content} />
              </div>
            </Fragment>
          );
        default:
          return null;
      }
    },
    [styles]
  );

  useEffect(() => {
    getLiquidLogicAttributesFx();
  }, []);

  const isLiquidAttributesLoading = useStore(getLiquidLogicAttributesFx.pending);

  const { isScrolling, handleScroll } = useScroll();

  const scrollStyles = isScrolling ? `${styles.grid} ${styles.shadow}` : `${styles.grid}`;

  return (
    <LoadingRender isLoading={isLiquidAttributesLoading} fallback={null}>
      <div className={styles.container} data-testid="content-section">
        <div className={scrollStyles} data-testid="header-content">
          <SectionHeader
            title={formatMessage({ id: 'summaryPage.sections.touchpoint' })}
            page="channel"
            isEditable={isEditable}
          />
          <SectionHeader
            title={formatMessage({ id: 'summaryPage.sections.content' })}
            page="content"
            isEditable={isEditable}
          />
        </div>
        <div className={`${styles.grid} ${styles.info}`} onScroll={handleScroll}>
          {valuesToRender.map((data) => renderContent(data))}
        </div>
      </div>
    </LoadingRender>
  );
}
