import { Heading } from '@hexa-ui/components';
import TextEllipsis from '../../../TextEllipsis/TextEllipsis';
import { ListCardItemProps } from './ListCardItem.d';

export default function ListCardItem({ item: { subTitle, title } }: ListCardItemProps) {
  return (
    <li data-testid="listitem">
      <Heading size="H5">
        <TextEllipsis text={title}>{title}</TextEllipsis>
      </Heading>
      <span>{subTitle}</span>
    </li>
  );
}
