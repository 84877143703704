import { createStore } from 'effector';
import { cloneDeep } from 'lodash';
import {
  loadGoalTypeData,
  loadGoalTypeDataDone,
  loadGoalTypeDataFail,
  resetSelectedGoalType,
  selectGoalType
} from './GoalTypeEvents';
import { GoalTypeStorageProps } from './GoalTypeState';

const initialState: GoalTypeStorageProps = {
  isLoading: false,
  content: [],
  selectedContent: null
};

const GoalTypeStore = createStore(initialState)
  .on(loadGoalTypeData, (state) => {
    return cloneDeep({
      ...state,
      isLoading: !state.isLoading
    });
  })
  .on(loadGoalTypeDataFail, (state) => {
    return cloneDeep({
      ...state,
      isLoading: false
    });
  })
  .on(loadGoalTypeDataDone, (_, data) => {
    return cloneDeep({
      content: data.content,
      isLoading: false,
      selectedContent: null
    });
  })
  .on(selectGoalType, (state, data) => {
    return cloneDeep({
      ...state,
      selectedContent: data.content
    });
  })
  .on(resetSelectedGoalType, (state) => {
    return cloneDeep({
      ...state,
      selectedContent: null
    });
  });

export default GoalTypeStore;
