import { combine, createStore, merge, sample } from 'effector';
import _, { cloneDeep, uniqueId } from 'lodash';
import {
  Expression,
  Filter,
  TComparison,
  TraitListExp,
  TraitPrioritizationRange
} from '../../domain/filter';
import { TTraitId, TraitType } from '../../domain/trait';
import { getFiltersFx } from '../../services/identity/audiences/FiltersService';
import {
  applySelectedTraitValues,
  calculateEstimatedAudience,
  removeAppliedTraitValue,
  removeFilter,
  resetFilters,
  selectTrait
} from './Events';

export const MAX_FILTERS_ALLOWED = 5;

export const $filters = createStore<Filter[]>([]).reset(resetFilters);

export const $hasFiltersAdded = $filters.map((filters) => !!filters.length);
export const $isAllFiltersValid = $filters.map((filters) =>
  filters.every((filter) => !!filter.expressions?.length)
);
export const $isAudienceReady = combine(
  $hasFiltersAdded,
  $isAllFiltersValid,
  (state1, state2) => state1 && state2
);

const generateFilterId = (traitId: TTraitId): string => `${traitId}_${uniqueId()}`;

const getExpressions = (
  values: (string | number)[],
  comparison: TComparison,
  flag: boolean,
  priority: string | number,
  filter: Filter,
  range: TraitPrioritizationRange
): Expression[] => {
  switch (filter.traitType) {
    case TraitType.list:
    case TraitType.listComparison:
    case TraitType.listBoolean:
    case TraitType.numeric:
      return _.uniqBy(
        [...filter.expressions, ...values.map((value) => ({ value, comparison, flag, priority }))],
        'value'
      );
    case TraitType.boolean:
      return [...values.map((value) => ({ value }))] as Expression[];
    case TraitType.comparison:
      return [...filter.expressions, { comparison }];
    case TraitType.listPriority:
      return _.uniqWith(
        [...filter.expressions, ...values.map((value) => ({ value, number: priority }))],
        _.isEqual
      );
    case TraitType.listPriorityRange:
      return _.uniqWith([...filter.expressions, range], _.isEqual);
    case TraitType.listObject:
      return _.uniqWith([...filter.expressions, ...values], _.isEqual);
    default:
      return [];
  }
};

$filters
  .on(getFiltersFx.doneData, (state, payload) => {
    return [
      ...state,
      ...payload.filters.map((filter) => {
        return {
          ...filter,
          _id: generateFilterId(filter.traitId)
        };
      })
    ];
  })
  .on(getFiltersFx.fail, () => [])
  .on(selectTrait, (state, { trait }) => [
    ...state,
    {
      _id: generateFilterId(trait.id),
      traitId: trait.id,
      traitType: trait.type,
      expressions: []
    }
  ])
  .on(
    applySelectedTraitValues,
    (state, { values, onFilter: selectedFilter, comparison, flag, priority, range }) => {
      const cloneState = cloneDeep(state);
      return cloneState.map((filter) => {
        if (filter._id !== selectedFilter._id) {
          return filter;
        }
        return {
          ...filter,
          expressions: getExpressions(values, comparison, flag, priority, selectedFilter, range)
        };
      });
    }
  )
  .on(removeAppliedTraitValue, (state, { index, fromFilter: selectedFilter }) => {
    return state.map((filter) => {
      if (filter._id !== selectedFilter._id) {
        return filter;
      }

      return {
        ...filter,
        expressions: (filter.expressions as TraitListExp[]).filter(
          (item, expIndex) => expIndex !== index
        )
      };
    });
  })
  .on(removeFilter, (state, { id }) => state.filter((item) => item._id !== id));

sample({
  clock: merge([
    applySelectedTraitValues,
    removeAppliedTraitValue,
    removeFilter,
    getFiltersFx.doneData
  ]),
  source: $filters,
  target: calculateEstimatedAudience
});
