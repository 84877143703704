import { Paragraph } from '@hexa-ui/components';
import { useStore } from 'effector-react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import useNavigate from '../../../../hooks/useNavigate/useNavigate';
import PackageStore from '../../../../stores/package/PackageStore';
import { SectionHeaderProps } from './SectionHeader.d';
import sectionHeaderStyles from './SectionHeader.styles';

export default function SectionHeader({ title, page, isEditable }: SectionHeaderProps) {
  const styles = sectionHeaderStyles();

  const { formatMessage } = useIntl();

  const { push } = useNavigate();
  const { packageRoute } = useStore(PackageStore);

  const handleRedirect = useCallback(() => {
    push(`${packageRoute}/campaign/${page}`);
  }, [packageRoute, push, page]);

  return (
    <div className={styles.header}>
      <Paragraph className={styles.title}>{title}</Paragraph>
      {isEditable && (
        <Paragraph role="button" className={styles.edit} onClick={handleRedirect}>
          {formatMessage({ id: 'summaryPage.sections.editButton' })}
        </Paragraph>
      )}
    </div>
  );
}
