import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ChannelUsesCase from '../../../../../useCases/channel/ChannelUseCase';
import FullBanner from '../../../../FullBanner/FullBanner';
import { FullBannerPreviewImages } from '../../../../FullBanner/FullBanner.d';
import Duration from '../../Duration/Duration';
import Label from '../../Label/Label';
import { BannerProps } from './Banner.d';

export default function Banner({ content }: BannerProps) {
  const { formatMessage } = useIntl();
  const [bannerImages, setBannerImages] = useState<FullBannerPreviewImages[]>([]);

  const handleImagesBanner = useCallback(async () => {
    const cmsBanners = await ChannelUsesCase.execute();

    let bannersToShow: FullBannerPreviewImages[] = [];

    content.immutable_guid.forEach((bannerId) => {
      const bannerToFind = cmsBanners?.find((cmsBannerRef, index) => {
        return cmsBannerRef.immutable_guid === bannerId && index >= 0;
      });

      if (bannerToFind) {
        bannersToShow = [
          ...bannersToShow,
          {
            cmsIndex: cmsBanners.indexOf(bannerToFind) + 1,
            image: bannerToFind?.preview_images || [],
            name: bannerToFind.module_name[0]
          }
        ];
      }
    });
    setBannerImages(bannersToShow);
  }, [content.immutable_guid]);

  useEffect(() => {
    handleImagesBanner();
  }, [handleImagesBanner]);

  return (
    <div data-testid="banner-container">
      <Duration start={content.start} end={content.end} />

      <Label text={formatMessage({ id: 'summaryPage.banner.label' })}>
        <div>
          <FullBanner previewImages={bannerImages} />
        </div>
      </Label>
    </div>
  );
}
