/* eslint-disable effector/enforce-store-naming-convention */
import { createStore, sample } from 'effector';
import { cloneDeep } from 'lodash';
import {
  campaignDateChangedFx,
  fetchCampaignById,
  fetchCreateCampaign,
  fetchCreatePackageCampaign
} from './CampaignEffects';
import {
  addAudience,
  addCampaign,
  addDetailsCampaign,
  addPackage,
  addSkuList,
  addSkus,
  addTouchPoints,
  canResetDates,
  resetCampaign,
  resetCanResetDates,
  updateFrequency
} from './CampaignEvents';
import { CampaignStorageProps } from './CampaignState';

const initialState: CampaignStorageProps = {
  content: {
    id: '',
    name: '',
    start: null,
    end: null,
    status: undefined,
    duration: undefined,
    campaign_goal: {
      goal_id: '',
      goal_name: '',
      goal_icon: undefined,
      goal_inputs: [],
      primary_category: {
        id: '',
        description: '',
        name: '',
        target_kpi: []
      }
    },
    results: '',
    engagements: []
  }
};

const CampaignStore = createStore(initialState)
  .on(fetchCreateCampaign.doneData, (state, data) =>
    cloneDeep({
      ...state,
      content: data
    })
  )
  .on(fetchCreatePackageCampaign.doneData, (state, data) =>
    cloneDeep({
      ...state,
      content: data
    })
  )
  .on(fetchCampaignById.doneData, (state, data) =>
    cloneDeep({
      ...state,
      content: data
    })
  )
  .on(addCampaign, (state, payload) =>
    cloneDeep({
      ...state,
      content: {
        ...payload,
        engagements: payload.engagements || []
      }
    })
  )
  .on(addDetailsCampaign, (state, { end, name, start }) =>
    cloneDeep({ ...state, content: { ...state.content, end, name, start } })
  )
  .on(addSkus, (state, payload) =>
    cloneDeep({
      ...state,
      content: {
        ...state.content,
        campaign_goal: {
          ...state.content.campaign_goal,
          goal_inputs: payload
        }
      }
    })
  )
  .on(addAudience, (state, payload) => {
    return cloneDeep({ ...state, content: { ...state.content, engagements: [payload] } });
  })
  .on(addTouchPoints, (state, payload) => {
    const copy = cloneDeep(state);
    const index = copy.content.engagements.findIndex(
      (engage) => engage.audience_id === payload.audience_id
    );
    copy.content.engagements[index].touchpoints = payload.touchpoints;

    return copy;
  })
  .on(addPackage, (state, payload) => {
    return cloneDeep({
      ...state,
      content: {
        ...state.content,
        package: payload
      }
    });
  })
  .on(addSkuList, (state, payload) => {
    const copy = cloneDeep(state);
    const index = copy.content.engagements.findIndex(
      (engage) => engage.audience_id === payload.engagement[0].audience_id
    );
    copy.content.engagements[index]?.touchpoints.forEach((touch) => {
      if (touch.touchpoint === 'BEES_FORCE') {
        touch.skuList = payload.skuList;
      }
    });

    return copy;
  })
  .on(updateFrequency, (state, counter) => {
    const copy = cloneDeep(state);
    const newEngagements = copy.content?.engagements?.map((engage) => ({
      ...engage,
      touchpoints: engage.touchpoints.map((touch) => {
        if (
          touch.touchpoint === 'PUSH_NOTIFICATION' ||
          touch.touchpoint === 'IN_APP_NOTIFICATION'
        ) {
          touch.counter = counter;
        }
        return touch;
      })
    }));
    return { ...copy, content: { ...copy.content, engagements: newEngagements } };
  })
  .on(campaignDateChangedFx.doneData, (_, payload) => payload)
  .reset(resetCampaign);

export const $canResetDatesOnTouchpoints = createStore(false).reset(resetCanResetDates);

$canResetDatesOnTouchpoints.on(canResetDates, (_, payload) => payload);

sample({
  clock: addDetailsCampaign,
  source: {
    CampaignStoreSource: CampaignStore,
    canResetDatesOnTouchpointsSource: $canResetDatesOnTouchpoints
  },
  fn: ({ CampaignStoreSource, canResetDatesOnTouchpointsSource }) => ({
    campaign: CampaignStoreSource,
    canResetDatesOnTouchpoints: canResetDatesOnTouchpointsSource
  }),
  target: campaignDateChangedFx
});

export default CampaignStore;
