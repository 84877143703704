import { Heading } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { formatExpressions } from '../../../../useCases/audienceManagement/TraitExpressionsUseCase';
import { ExpressionParams } from '../../../../useCases/audienceManagement/TraitExpressionsUseCase.d';
import { TraitProps } from './Trait.d';
import { traitStyles } from './styles';

export default function Trait({ filter, operatorsTranslation, traitsList }: TraitProps) {
  const styles = traitStyles();
  const { formatMessage } = useIntl();

  const expressions = formatExpressions(filter, operatorsTranslation);

  const trait = traitsList?.find((traitValue) => traitValue.id === filter.traitId);

  return (
    <div className={styles.trait}>
      <Heading size="H5">{trait?.name}</Heading>
      <ul>
        {expressions.map((expressionParam: ExpressionParams) => (
          <li key={uuid()} data-testid="trait-value-item">
            {formatMessage(
              { id: `audience.chipCopywrite.${filter.traitId}` },
              { ...expressionParam }
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
