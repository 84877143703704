import { styled } from '@hexa-ui/theme';

export const Divider = styled('div', {
  marginTop: '32px'
});

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '1080px',
  margin: '24px auto 28px',
  gap: '1.5rem',

  '@global': {
    '*::-webkit-scrollbar': {
      width: '8px'
    },
    '*::-webkit-scrollbar-track': {},
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.15)',
      borderRadius: '10px'
    }
  }
});

export const ButtonsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'flex-end',
  gap: '1rem'
});

export const HeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
});

export const DownloadContainer = styled('div', {
  marginLeft: 'auto'
});
