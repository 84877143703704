import { createStore } from 'effector';
import { cloneDeep } from 'lodash';
import {
  changePage,
  changeSize,
  loadDashboardData,
  loadDashboardDataDone,
  loadDashboardDataFail,
  resetDashboard
} from './DashboardEvents';
import { CampaignStorageProps } from './DashboardState';

const initialState: CampaignStorageProps = {
  isLoading: false,
  content: [],
  totalElements: 0,
  page: 0,
  size: 25
};

const $DashboardStore = createStore(initialState)
  .on(loadDashboardData, (state) => {
    return cloneDeep({
      ...state,
      isLoading: true
    });
  })
  .on(loadDashboardDataFail, (state) => {
    return cloneDeep({
      ...state,
      isLoading: false
    });
  })
  .on(loadDashboardDataDone, (state, data) => {
    return cloneDeep({
      ...state,
      content: data.content,
      isLoading: false,
      totalElements: data.totalElements
    });
  })
  .on(changePage, (state, { newPage }) => {
    return cloneDeep({
      ...state,
      page: newPage
    });
  })
  .on(changeSize, (state, { newSize }) => {
    return cloneDeep({
      ...state,
      size: newSize
    });
  })
  .reset(resetDashboard);

export default $DashboardStore;
