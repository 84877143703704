import { PaginatedResponse } from '../../domain/PaginatedResponse';
import { TraitValue, TraitValueV2 } from '../../domain/traitValue';

const nonEmpties = (value: string) => !!value.length;

export const executeTraitValuesChangedUseCase = (
  state: PaginatedResponse<TraitValue>,
  response: PaginatedResponse<TraitValue>
): PaginatedResponse<TraitValue> => {
  if (response.pagination.page === 0) {
    return {
      ...response,
      values: response.values.filter(nonEmpties)
    };
  }

  return {
    ...response,
    values: [...state.values, ...response.values].filter(nonEmpties)
  };
};

export const executeTraitValuesChangedUseCaseV2 = (
  state: PaginatedResponse<TraitValueV2>,
  response: PaginatedResponse<TraitValueV2>
): PaginatedResponse<TraitValueV2> => {
  if (response.pagination.page === 0) {
    return {
      ...response,
      values: response.values
    };
  }

  return {
    ...response,
    values: [...state.values, ...response.values]
  };
};
