import { styled, theme } from '@hexa-ui/theme';

const MarkHighlightDiv = styled('div', {
  mark: {
    borderRadius: '3px',
    backgroundColor: `${theme.colors.semanticWarningBackground}`
  }
});

export default MarkHighlightDiv;
