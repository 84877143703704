import { NavigateOptions, useNavigate as useBaseNavigate, useLocation } from 'react-router-dom';
import { LocationWithState } from './useNavigate.d';

type Options = Omit<NavigateOptions, 'replace' | 'state'>;

export default function useNavigate<T>() {
  type TState = T & { referrer: string };

  const URL_REFERRER = window.location.href;
  const navigate = useBaseNavigate();
  const location: LocationWithState<TState> = useLocation();

  const push = (path: string, state?: TState, options?: Options) => {
    navigate(path, {
      ...options,

      state: { ...state, referrer: URL_REFERRER },
      replace: false
    });
  };

  const replace = (path: string, state?: TState, options?: Options) => {
    navigate(path, {
      ...options,
      state: { ...state, referrer: URL_REFERRER },
      replace: true
    });
  };

  return { location, push, replace };
}
