import { createEffect } from 'effector';
import Api from '../../Api/Api';
import { GET_SUPPORTED_COUNTRIES } from '../../constants';

export const getSupportedCountriesFx = createEffect(async (userCountry) => {
  const response = await Api.get({ url: GET_SUPPORTED_COUNTRIES });

  const userCountryData = response.data.find(({ code }) => code === userCountry);

  if (userCountryData) {
    return {
      data: response.data,
      selectedCountry: {
        code: userCountryData.code,
        abiVendorId: userCountryData.abiVendorId
      }
    };
  }
  return {
    data: response.data,
    selectedCountry: {
      code: response.data[0].code,
      abiVendorId: response.data[0].abiVendorId
    }
  };
});

export default getSupportedCountriesFx;
