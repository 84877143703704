/* eslint-disable no-await-in-loop */
/* eslint-disable effector/enforce-effect-naming-convention */
/* eslint-disable import/prefer-default-export */
import { useLogService as LoadSkuService } from 'admin-portal-shared-services';
import { createEffect } from 'effector';
import Api from '../../../../Api/Api';
import { GET_ALL_GOAL_INPUTS, SUCCESS_CODE } from '../../../../constants';
import { GetSkuResp, GetSkusParams } from './LoadSkuService.d';

const handlePromise = (resp): Promise<GetSkuResp | undefined> =>
  new Promise((resolve, reject) => {
    SUCCESS_CODE.includes(resp.status) ? resolve(resp.data) : reject(resp);
  });

export const getSkus = createEffect(
  async (
    { searchParam, page, pageSize }: GetSkusParams = {
      searchParam: '',
      page: 0,
      pageSize: 10
    }
  ): Promise<GetSkuResp> => {
    const log = LoadSkuService();
    try {
      const response = await Api.get({
        url: GET_ALL_GOAL_INPUTS,
        config: {
          params: {
            searchParam,
            page,
            pageSize
          }
        }
      });
      return handlePromise(response);
    } catch (error) {
      log.error('get-all-goal-input', error);
      return handlePromise(error);
    }
  }
);
