import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core';
import { StylesProps } from './Link.d';

export const linkStyles = makeStyles<Theme, StylesProps>((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  url: {
    color: theme.bzColorInterfaceLabelPrimary,
    fontSize: theme.bzFontSizeBasis,
    lineHeight: theme.bzFontLineHeightLarge,
    textAlign: 'left',
    letterSpacing: '0.1px',
    fontWeight: 500
  },
  icon: {
    opacity: ({ showCopy }) => (showCopy ? '100' : '0'),
    border: '0',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'opacity .1s ease',

    '& svg': {
      color: '#047AF1'
    }
  }
}));
