import { parse } from 'date-fns';
import { Formatter } from './Formatter';

export class DateFormatter implements Formatter<string> {
  format(value: string, options?: Intl.DateTimeFormatOptions): string {
    if (!value) return '';
    const valueData = parse(value, 'yyyy-MM-dd', new Date());
    const locale = 'en-US';

    return new Intl.DateTimeFormat(locale, options).format(valueData);
  }
}
