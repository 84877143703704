import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core';

export const allTraitsStyles = makeStyles((theme: Theme) => ({
  container: {
    '@global': {
      '*::-webkit-scrollbar': {
        width: '8px'
      },
      '*::-webkit-scrollbar-track': {},
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.15)',
        borderRadius: '10px'
      }
    },

    '& > div:nth-child(2)': {
      '& > div:nth-child(2)': {
        paddingRight: 0,
        marginRight: '32px'
      }
    }
  },
  title: {
    fontSize: theme.bzFontSizeXlarge,
    lineHeight: theme.bzFontLineHeightXlarge
  },
  containerItems: {
    display: 'flex',
    flexDirection: 'column',

    '& div:nth-child(even)': {
      background: theme.bzColorNeutral10
    }
  }
}));
