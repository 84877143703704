/* eslint-disable camelcase */
import { Campaign } from '../../domain/campaign';
import { campaignToCardMapper } from '../Mappers/campaignToCardMapper';

const transformTargetKPIs = (campaign: Campaign) => {
  const campaignKPI = [];
  const { targetsKpi } = campaignToCardMapper(campaign);

  targetsKpi.forEach((targetKPI) => {
    return campaignKPI.push({ campaign_KPIs: targetKPI.name });
  });

  return campaignKPI;
};

export default transformTargetKPIs;
