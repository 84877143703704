import { createEvent } from 'effector';
import { Campaign } from '../../domain/campaign';

export const loadDashboardData = createEvent('loadDashboardData');
export const loadDashboardDataDone = createEvent<{
  content: Campaign[];
  totalElements: number;
}>('loadDashboardDataDone');
export const loadDashboardDataFail = createEvent('loadDashboardDataFail');
export const changePage = createEvent<{ newPage: number }>('changePage');
export const changeSize = createEvent<{ newSize: number }>('changeSize');
export const resetDashboard = createEvent('resetDashboard');
