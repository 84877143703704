import { Paragraph } from '@hexa-ui/components';
import { LabelProps } from './Label.d';
import labelStyles from './Label.styles';

export default function Label({ text, children }: LabelProps) {
  const styles = labelStyles();

  return (
    <div className={styles.container}>
      <Paragraph className={styles.label}>{text}</Paragraph>
      {children}
    </div>
  );
}
