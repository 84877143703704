import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core';

export const audienceInfoStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontFamily: theme.bzFontFamilyHeading,
    fontSize: '12px',
    fontWeight: theme.bzFontWeightSemibold,
    lineHeight: theme.bzFontLineHeightBasis,
    marginBottom: '4px'
  },

  item: {
    fontFamily: '"Work Sans", "San Francisco", Roboto, "Segoe UI", Helvetica, sans-serif',
    color: theme.bzColorInterfaceLabelSecondary,
    fontSize: '12px',
    margin: 0,
    lineHeight: '18px'
  },

  viewAllButton: {
    margin: 0,
    marginTop: '8px',
    color: theme.bzColorSemanticInfoText,
    lineHeight: '20px',
    fontSize: '14px',
    cursor: 'pointer',
    width: 'fit-content'
  },

  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 16px'
  }
}));
