import { useIntl } from 'react-intl';

export function useCampaignGoalBarI18n() {
  const { formatMessage } = useIntl();
  const campaignGoal = formatMessage({ id: 'campaignGoalBar.campaignGoal' });
  const type = formatMessage({ id: 'campaignGoalBar.type' });
  const primaryGoal = formatMessage({ id: 'campaignGoalBar.primaryGoal' });
  const targetKpi = formatMessage({ id: 'campaignGoalBar.targetKpi' });
  const viewAllButton = formatMessage({ id: 'campaignGoalBar.viewAllButton' });

  return {
    campaignGoal,
    type,
    primaryGoal,
    targetKpi,
    viewAllButton
  };
}
