import { Heading } from '@hexa-ui/components';
import { HeaderProps } from './Header.d';
import { useHeaderStyles } from './Header.style';

function Header({ title }: HeaderProps): JSX.Element {
  const { headerContainer } = useHeaderStyles();
  return (
    <div className={headerContainer}>
      <Heading size="H1">{title}</Heading>
    </div>
  );
}

export { Header };
