import { useMemo } from 'react';
import { TouchpointEnum } from '../../domain/campaign';
import { TouchpointData } from '../../pages/TouchpointsPage/TouchpointsPage.d';

export type UseTouchpointsOrderReturn<T> = {
  prefix: string;
} & T &
  TouchpointData;

export default function useTouchpointsOrder<T extends TouchpointData>(
  data: T[],
  callback?: (data: UseTouchpointsOrderReturn<T>[]) => UseTouchpointsOrderReturn<T>[]
): UseTouchpointsOrderReturn<T>[] {
  const sortedData = useMemo(() => {
    const banners = data.filter((el) => el.type === TouchpointEnum.HOMEPAGE_BANNER);
    const inApps = data.filter((el) => el.type === TouchpointEnum.IN_APP_NOTIFICATION);
    const pushs = data.filter((el) => el.type === TouchpointEnum.PUSH_NOTIFICATION);
    const bdrs = data.filter((el) => el.type === TouchpointEnum.BEES_FORCE);

    return [...banners, ...inApps, ...pushs, ...bdrs];
  }, [data]);

  const formattedData = useMemo(() => {
    const initialState: { [key in TouchpointEnum]: number } = {
      HOMEPAGE_BANNER: 0,
      IN_APP_NOTIFICATION: 0,
      PUSH_NOTIFICATION: 0,
      BEES_FORCE: 0
    };

    return sortedData.map((item) => {
      if (sortedData.filter((el) => el.type === item.type).length === 1) {
        return {
          ...item,
          prefix: ``
        };
      }

      initialState[item.type] += 1;

      return {
        ...item,
        prefix: `${initialState[item.type]}.`
      };
    });
  }, [sortedData]);

  return callback ? callback(formattedData) : formattedData;
}
