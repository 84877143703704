import React from 'react';

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  optimizelyKey: string;
  xVendorBR: string;
  xVendorMX: string;
  cmsHost: string;
  powerBiId: string;
  segmentKeyBR: string;
  segmentKeyMX: string;
};

const defaultInitialValue: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  optimizelyKey: '',
  xVendorBR: '',
  xVendorMX: '',
  cmsHost: '',
  powerBiId: '',
  segmentKeyBR: '',
  segmentKeyMX: ''
};

export const EnvContext = React.createContext(defaultInitialValue);
export const useEnvContext = () => React.useContext(EnvContext);

export function EnvProvider({ env, children }: { env: EnvConfig; children: React.ReactNode }) {
  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
}
