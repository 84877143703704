import { createEffect } from 'effector';
import { getPackageById } from '../../services/partner/packages/PartnerPackagesService';
import { PackageResponse } from '../../services/partner/packages/PartnerPackagesService.d';
import { packageReset } from './PackageEvents';
import { PackageStorageProps } from './PackageState';
import createPackageStorageFromPackageResponse from './adapters/PackageDomainAdapter';

export const fetchLoadPackage = createEffect(async (packageId): Promise<PackageStorageProps> => {
  packageReset();
  const packageResponse: PackageResponse = await getPackageById(packageId);
  return createPackageStorageFromPackageResponse(packageResponse);
});
