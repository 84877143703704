import { createEvent } from 'effector';
import { GoalType } from '../../domain/goalType';

export const loadGoalTypeData = createEvent('loadGoalTypeData');
export const loadGoalTypeDataDone = createEvent<{
  content: GoalType[];
}>('loadGoalTypeDataDone');
export const loadGoalTypeDataFail = createEvent('loadGoalTypeDataFail');
export const selectGoalType = createEvent<{
  content: GoalType;
}>('selectGoalType');
export const resetSelectedGoalType = createEvent('resetSelectedGoalType');
