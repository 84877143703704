/* eslint-disable effector/no-getState */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios from 'axios';
import { v4 as uuid } from 'uuid';
import { HEADER_X_BEES_CALLER, HEADER_X_BEES_ORIGIN, HEADER_X_VENDOR } from '../../../constants';
import { getCmsHost } from '../../../services/host/HostService';
import $SupportedCountriesStore from '../../../stores/supportedCountries/SupportedCountriesStore';
import { IRequest } from './CmsApi.d';

Axios.interceptors.request.use((config) => {
  const { headers } = config;
  const authentication = useAuthenticationService();
  const token = authentication.getAuthHeader();
  const { selectedCountry } = $SupportedCountriesStore.getState();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const xVendor = selectedCountry && selectedCountry.abiVendorId;

  headers.Authorization = token;
  headers.requestTraceId = uuid();
  headers.country = (selectedCountry && selectedCountry.code) || userCountry || 'BR';
  headers[HEADER_X_VENDOR] = xVendor;

  headers[HEADER_X_BEES_CALLER] = 'admin-portal-audman-mfe';
  headers[HEADER_X_BEES_ORIGIN] = 'admin-portal-audman-mfe';

  return { ...config, headers };
});

const CmsApi = {
  get: ({ url, config }: IRequest): Promise<any> =>
    Axios.get(`${getCmsHost()}${url}`, {
      ...config,
      headers: { ...config?.headers, Accept: 'application/json' }
    }).catch((error) => ({ hasError: true, ...error })),

  put: ({ url, body, config }: IRequest): Promise<any> =>
    Axios.put(`${getCmsHost()}${url}`, body, {
      ...config,
      headers: { ...config?.headers, Accept: 'application/json' }
    }).catch((error) => ({
      hasError: true,
      ...error
    }))
};

export default CmsApi;
