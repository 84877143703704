import { useLogService as logService } from 'admin-portal-shared-services';
import Api from '../../../Api/Api';
import { CAMPAIGNS_ENDPOINT, CAMPAIGNS_ENDPOINT_V2, SUCCESS_CODE } from '../../../constants';
import { PaginationProps } from '../../Service.d';
import {
  CampaignsSortRequest,
  ChangeCampaignStatusRequest,
  CreateCampaignRequest,
  CreatePackageCampaignRequest,
  PublishCampaignRequest,
  ValidateCampaignNameRequest
} from './CampaignService.d';

const handlePromise = (resp: any): Promise<any> =>
  new Promise((resolve, reject) => {
    if (SUCCESS_CODE.includes(resp.status)) {
      resolve(resp.data);
    } else {
      reject(resp);
    }
  });

export const getAllCampaigns = async <Response>(params: PaginationProps): Promise<Response> => {
  const log = logService();
  let resp: any;
  try {
    const response = await Api.get({
      url: CAMPAIGNS_ENDPOINT,
      config: {
        params
      }
    });
    resp = response;
  } catch (error) {
    log.error('get-all-campaigns-error', error);
    resp = error;
  }

  return handlePromise(resp);
};

export const getCampaignById = async <Response>(campaignId: string): Promise<Response> => {
  const log = logService();
  let resp: any;
  try {
    resp = await Api.get({
      url: `${CAMPAIGNS_ENDPOINT}/${campaignId}`
    });
  } catch (error) {
    log.error('get-campaign-by-id', error);
    resp = error;
  }

  return handlePromise(resp);
};

export const createCampaign = async <Response>({
  goalId,
  goalName,
  goalIcon,
  primaryCategory
}: CreateCampaignRequest): Promise<Response> => {
  const log = logService();
  try {
    const { data } = await Api.post({
      url: CAMPAIGNS_ENDPOINT,
      body: {
        campaign_goal: {
          goal_id: goalId,
          goal_name: goalName,
          goal_icon: goalIcon,
          primary_category: primaryCategory
        },
        package: null
      }
    });
    return data;
  } catch (error) {
    log.error('create-campaign', error);
    return error;
  }
};

export const createPackageCampaign = async <Response>(
  packageCampaign: CreatePackageCampaignRequest,
  vendor: string
): Promise<Response> => {
  const log = logService();
  let resp: any;
  try {
    const response = await Api.post({
      url: CAMPAIGNS_ENDPOINT,
      body: {
        ...packageCampaign,
        package: {
          ...packageCampaign.package,
          manufacturerId: vendor
        }
      }
    });
    resp = response;
  } catch (error) {
    log.error('create-package-campaign', error);
    resp = error;
  }

  return handlePromise(resp);
};

export const updateCampaign = async <Response>({ campaign }): Promise<Response> => {
  const log = logService();
  let resp: any;

  try {
    const response = await Api.put({
      url: `${CAMPAIGNS_ENDPOINT_V2}/${campaign.id}`,
      body: campaign
    });
    resp = response;
  } catch (error) {
    log.error('update-campaign', error);
    resp = error;
  }

  return handlePromise(resp);
};

export const deleteCampaign = async <Response>({ id }): Promise<Response> => {
  const log = logService();
  let resp: any;
  try {
    const response = await Api.delete({
      url: `${CAMPAIGNS_ENDPOINT}/${id}`
    });
    resp = response;
  } catch (error) {
    log.error('delete-campaign', error);
    resp = error;
  }

  return handlePromise(resp);
};

export const cancelCampaign = async <Response>({ id }): Promise<Response> => {
  const log = logService();
  let resp: any;
  try {
    const response = await Api.post({
      url: `${CAMPAIGNS_ENDPOINT}/${id}/cancel`
    });
    resp = response;
  } catch (error) {
    log.error('cancel-campaign', error);
    resp = error;
  }

  return handlePromise(resp);
};

export const publishCampaign = async <Response>(
  param: PublishCampaignRequest
): Promise<Response> => {
  const log = logService();
  let resp: any;
  try {
    const response = await Api.post({
      url: `${CAMPAIGNS_ENDPOINT}/${param.campaignId}/publish`,
      body: null
    });
    resp = response;
  } catch (error) {
    log.error('publish-campaign', error);
    resp = error;
  }

  return handlePromise(resp);
};

export const validateName = async (param: ValidateCampaignNameRequest) => {
  const log = logService();
  let resp: any;
  try {
    const response = await Api.get({
      url: `${CAMPAIGNS_ENDPOINT}/validate-name`,
      body: null,
      config: {
        params: {
          campaignId: param.campaignId,
          campaignName: param.campaignName
        }
      }
    });
    resp = response;
  } catch (error) {
    log.error('validate-name', error);
    resp = error;
  }

  return handlePromise(resp);
};

export const changeCampaignStatus = async <Response>({
  status,
  campaignId
}: ChangeCampaignStatusRequest): Promise<Response> => {
  const log = logService();
  let resp: any;
  try {
    const response = await Api.patch({
      url: `${CAMPAIGNS_ENDPOINT}/${campaignId}`,
      body: { status }
    });
    resp = response;
  } catch (error) {
    log.error('change-campaign-status', error);
    resp = error;
  }

  return handlePromise(resp);
};

export const getAllCampaignsSorted = async <Response>(
  params: Partial<CampaignsSortRequest>
): Promise<Response> => {
  const log = logService();
  let resp: any;
  try {
    const response = await Api.get({
      url: CAMPAIGNS_ENDPOINT,
      config: {
        params: {
          ...params,
          status: `${params.status}` || undefined,
          goalId: `${params.goalId}` || undefined
        }
      }
    });
    resp = response;
  } catch (error) {
    log.error('get-all-campaigns-error', error);
    resp = error;
  }

  return handlePromise(resp);
};
