import { Dialog, Heading } from '@hexa-ui/components';
import { useStoreMap } from 'effector-react';
import { TargetIcon } from '../../assets/img/TargetIcon';
import { SCREEN_NAMES_ENUM } from '../../constants';
import { useCampaignGoalBarI18n } from '../../hooks/useCampaignGoalBarI18n';
import useSegment from '../../hooks/useSegment/useSegment';
import CampaignStore from '../../stores/campaign/CampaignStore';
import { CampaignGoalBarProps } from './CampaignGoalBar.d';
import { CampaignGoalBarStyles } from './styles';

function CampaignGoalBar({
  campaignTypeName,
  primaryGoalName,
  targetKpiName,
  targetsKpi
}: CampaignGoalBarProps) {
  const { campaignGoal, primaryGoal, targetKpi, type, viewAllButton } = useCampaignGoalBarI18n();

  const { triggerEvent } = useSegment();

  const campaingId = useStoreMap({
    store: CampaignStore,
    fn: (value) => value.content.id,
    keys: []
  });

  const {
    CampaignGoalBarContainer,
    InfoContent,
    InfoTitle,
    InfoColumn,
    LeftContainer,
    RightContainer,
    InfoContainer,
    ViewAllColumn,
    DialogInfoTitle,
    DialogInfoContent,
    DialogInfoColumn,
    ViewAllButton
  } = CampaignGoalBarStyles();

  const handleSendSegment = () => {
    const CURRENT_SCREEN = {
      campaign: SCREEN_NAMES_ENUM.SETTINGS_PAGE,
      audience: SCREEN_NAMES_ENUM.AUDIENCES_PAGE,
      channel: SCREEN_NAMES_ENUM.TOUCHPOINTS_PAGE,
      summary: SCREEN_NAMES_ENUM.SUMMARY_PAGE,
      content: SCREEN_NAMES_ENUM.CONTENT_PAGE
    };

    const url = document.location.href;
    triggerEvent({
      type: 'VIEW_ALL_TARGET_KPIS',
      payload: {
        button_name: 'View All',
        campaign_id: campaingId,
        screen_name: CURRENT_SCREEN[url.split('/').pop()],
        button_label: viewAllButton,
        url
      }
    });
  };

  return (
    <div className={CampaignGoalBarContainer} data-testid="campaign-goal-bar">
      <div className={LeftContainer}>
        <TargetIcon />
        <Heading size="H5">{campaignGoal}</Heading>
      </div>
      <div className={RightContainer}>
        <div className={InfoContainer}>
          <div className={InfoColumn}>
            <span className={InfoTitle}>{type}</span>
            <span className={InfoContent} data-testid="selected-campaign-type">
              {campaignTypeName ?? '-'}
            </span>
          </div>
          <div className={InfoColumn}>
            <span className={InfoTitle}>{primaryGoal}</span>
            <span className={InfoContent}>{primaryGoalName ?? '-'}</span>
          </div>
          <div className={InfoColumn}>
            <span className={InfoTitle}>{targetKpi}</span>
            <span className={InfoContent}>{targetKpiName ?? '-'}</span>
          </div>
        </div>
        {targetsKpi && targetsKpi.length > 1 ? (
          <div className={ViewAllColumn}>
            <Dialog.Root
              title={<Heading size="H3">{campaignGoal}</Heading>}
              trigger={
                <div
                  className={ViewAllButton}
                  onClick={handleSendSegment}
                  role="button"
                  onKeyDown={handleSendSegment}
                  tabIndex={0}
                >
                  <span>{viewAllButton}</span>
                </div>
              }
            >
              <div className={DialogInfoColumn}>
                <span className={DialogInfoTitle}>{type}</span>
                <span className={DialogInfoContent} data-testid="selected-campaign-type">
                  {campaignTypeName ?? '-'}
                </span>
              </div>
              <div className={DialogInfoColumn}>
                <span className={DialogInfoTitle}>{primaryGoal}</span>
                <span className={DialogInfoContent}>{primaryGoalName ?? '-'}</span>
              </div>
              <div className={DialogInfoColumn}>
                <span className={DialogInfoTitle}>{targetKpi}</span>
                <span className={DialogInfoContent}>
                  {targetsKpi.map(({ name, id }) => {
                    return <div key={id}>{name}</div>;
                  })}
                </span>
              </div>
            </Dialog.Root>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CampaignGoalBar;
