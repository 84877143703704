import { useStoreMap } from 'effector-react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import useScroll from '../../../../hooks/useScroll/useScroll';
import CampaignStore from '../../../../stores/campaign/CampaignStore';
import {
  resetSelectAudienceSummary,
  selectAudienceSummaryById
} from '../../../../stores/summary/SummaryEvents';
import SectionHeader from '../SectionHeader/SectionHeader';
import { AudienceSectionProps } from './AudienceSection.d';
import audienceSectionStyles from './AudienceSection.styles';
import AudienceItem from './components/AudienceItem/AudienceItem';

export default function AudienceSection({ isEditable }: AudienceSectionProps) {
  const { formatMessage } = useIntl();
  const styles = audienceSectionStyles();

  const engagements = useStoreMap({
    store: CampaignStore,
    keys: [],
    fn: (store) => store.content.engagements
  });

  useEffect(() => {
    selectAudienceSummaryById(engagements?.[0].audience_id);
    return () => {
      resetSelectAudienceSummary();
    };
  }, [engagements]);

  const { isScrolling, handleScroll } = useScroll();

  const scrollStyles = isScrolling ? `${styles.header} ${styles.shadow}` : `${styles.header}`;

  return (
    <div className={styles.container} data-testid="audience-section">
      <div className={scrollStyles} data-testid="header-audience">
        <SectionHeader
          title={formatMessage({ id: 'summaryPage.sections.audience' })}
          page="audience"
          isEditable={isEditable}
        />
      </div>

      <div className={styles.content} onScroll={handleScroll} data-testid="content-audience">
        {engagements.map((engagement, index) => (
          <AudienceItem
            key={engagement.audience_id}
            title={engagement.audience_name}
            audienceId={engagement.audience_id}
            filterId={engagement.filter_id}
            index={index}
            isCsv={engagement.isCsv}
          />
        ))}
      </div>
    </div>
  );
}
