import { makeStyles } from '@material-ui/core/styles';

export const breadCrumbItemTextStyles = makeStyles(() => ({
  breadItem: {
    cursor: 'pointer'
  },
  breadItemHome: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    lineHeight: '0rem',
    fontSize: '0rem',
    color: 'transparent',
    cursor: 'pointer'
  }
}));
