import { useEffect, useRef } from 'react';
import { FunctionType, UseIntervalProps } from './useInterval.d';

export function useInterval({ callback, delay, stopCallBack = false }: UseIntervalProps) {
  const savedCallBack = useRef<FunctionType>();

  useEffect(() => {
    if (stopCallBack) savedCallBack.current = null;
    else savedCallBack.current = callback;
  }, [callback, stopCallBack]);

  const tick = () => {
    savedCallBack.current();
  };

  useEffect(() => {
    if (!savedCallBack.current) return undefined;

    const id = setInterval(tick, delay);

    return () => {
      clearInterval(id);
    };
  }, [delay, stopCallBack]);
}
