import { createEvent } from 'effector';
import { Campaign, Engagement, Package } from '../../domain/campaign';
import { Sku } from '../../domain/sku';
import { SkuListProps } from './CampaignState';

export const addCampaign = createEvent<Campaign>('addCampaign');
export const addDetailsCampaign =
  createEvent<Pick<Campaign, 'end' | 'name' | 'start'>>('addDetailsCampaign');
export const addSkus = createEvent<Sku[]>('addSkus');
export const addAudience = createEvent<Engagement>('addAudience');
export const addTouchPoints = createEvent<Partial<Engagement>>('addEngagements');
export const addSkuList = createEvent<Partial<SkuListProps>>('addSkuList');

export const updateFrequency = createEvent<number>('updatePushFrequency');
export const resetCampaign = createEvent();

export const canResetDates = createEvent<boolean>();
export const resetCanResetDates = createEvent();

export const addPackage = createEvent<Package>();
