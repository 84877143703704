import { useStore, useStoreMap } from 'effector-react';
import { useIntl } from 'react-intl';
import useNavigate from '../../hooks/useNavigate/useNavigate';
import CampaignStore from '../../stores/campaign/CampaignStore';
import PackageStore from '../../stores/package/PackageStore';
import { formatToViewItem } from '../../utils/formatToViewItem';
import ListCard from '../ListCard/ListCard';
import { SummarySkuCardProps } from './SummarySkuCard.d';

export default function SummarySkuCard({
  isEditEnable = true,
  isFullWidth = false
}: SummarySkuCardProps): JSX.Element {
  const { replace } = useNavigate();
  const { packageRoute } = useStore(PackageStore);
  const { formatMessage } = useIntl();

  const { skuList } = useStoreMap({
    store: CampaignStore,
    keys: [],
    fn: ({ content }) => {
      return { skuList: content.campaign_goal.goal_inputs || [] };
    }
  });

  const handleEditSku = () => {
    replace(`${packageRoute}/campaign`);
  };

  const skuListWithPrefix = skuList.map((el) => {
    return {
      ...el,
      value: `${formatMessage({ id: 'listCardItem.sku' })} ${el.value}`
    };
  });

  return (
    <ListCard
      contentList={formatToViewItem(skuListWithPrefix, 'sub_description', 'value')}
      cardInfo={{
        title: formatMessage({ id: 'settingsPage.skus' }),
        subTitle: `${skuList.length} ${formatMessage({ id: 'settingsPage.selected' })}`,
        size: isFullWidth ? 'large' : 'small',
        handleEdit: isEditEnable ? handleEditSku : undefined
      }}
    />
  );
}
