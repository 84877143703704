import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core';

export const labelStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24,
    maxWidth: 'min(368px, 100%)',
    textAlign: 'justify'
  },
  label: {
    color: theme.bzColorInterfaceLabelSecondary,
    fontSize: theme.bzFontSizeSmall,
    lineHeight: theme.bzFontLineHeightMedium,
    marginBottom: 4
  }
}));

export default labelStyles;
