import { useLogService } from 'admin-portal-shared-services';
import { createEffect } from 'effector';
import Api from '../../../Api/Api';
import {
  GET_CUSTOM_FILTER_TRAITS_URL,
  GET_CUSTOM_FILTER_TRAIT_VALUES_URL,
  GET_CUSTOM_FILTER_TRAIT_VALUES_URL_V2,
  GET_RETRIEVE_FILTERS_AVAILABLE,
  SUCCESS_CODE
} from '../../../constants';
import { PaginatedResponse } from '../../../domain/PaginatedResponse';
import { Trait } from '../../../domain/trait';
import { FilterAvailable, TraitValue, TraitValueV2 } from '../../../domain/traitValue';

const pageSizeNumber = 50;

const handlePromise = (resp): Promise<any> =>
  new Promise((resolve, reject) => {
    const hasStatus = SUCCESS_CODE.includes(resp.status);
    if (hasStatus) resolve(resp.data);
    else reject(resp);
  });

export const getTraitsFx = createEffect<void, Trait[]>(async () => {
  try {
    const response = await Api.get({
      url: GET_CUSTOM_FILTER_TRAITS_URL
    });
    return handlePromise(response);
  } catch (error) {
    useLogService().error('get-traits', error);
    return handlePromise(error);
  }
});

export const getTraitValuesFx = createEffect<
  { traitId: string; query?: string; page?: number; pageSize?: number },
  PaginatedResponse<TraitValue>
>(async ({ traitId, query = '', page = 0, pageSize = pageSizeNumber }) => {
  try {
    const response = await Api.get({
      url: GET_CUSTOM_FILTER_TRAIT_VALUES_URL.replace(':id', traitId),
      config: {
        params: {
          page,
          pageSize,
          query
        }
      }
    });
    return handlePromise(response);
  } catch (error) {
    useLogService().error('get-trait-values', error);
    return handlePromise(error);
  }
});

export const getTraitValuesV2Fx = createEffect<
  { traitId: string; traitKey: string; query?: string; page?: number; pageSize?: number },
  PaginatedResponse<TraitValueV2>
>(async ({ traitId, traitKey, query = '', page = 0, pageSize = pageSizeNumber }) => {
  try {
    const response = await Api.get({
      url: GET_CUSTOM_FILTER_TRAIT_VALUES_URL_V2.replace(':id', traitId),
      config: {
        params: {
          traitKey,
          page,
          pageSize,
          query
        }
      }
    });
    return handlePromise(response);
  } catch (error) {
    useLogService().error('get-trait-values', error);
    return handlePromise(error);
  }
});

export const getFiltersAvailableFx = createEffect<{ traitId: string }, FilterAvailable[]>(
  async ({ traitId }) => {
    try {
      const response = await Api.get({
        url: GET_RETRIEVE_FILTERS_AVAILABLE.replace(':id', traitId)
      });
      return handlePromise(response);
    } catch (error) {
      useLogService().error('get-filters-available', error);
      return handlePromise(error);
    }
  }
);
