import { Text, View } from '@react-pdf/renderer';
import { useStore } from 'effector-react';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getTraitsFx } from '../../services/identity/audiences/TraitsService';
import { $traits } from '../../stores/audienceManagement/TraitsStore';
import { formatExpressions } from '../../useCases/audienceManagement/TraitExpressionsUseCase';
import { ExpressionParams } from '../../useCases/audienceManagement/TraitExpressionsUseCase.d';
import { AllTraitsProps } from './GetTraits.d';
import { styles } from './styles';

export function GetTraits({ filters }: AllTraitsProps) {
  const { formatMessage } = useIntl();
  const traitsList = useStore($traits);

  useEffect(() => {
    const loadData = async () => {
      try {
        await getTraitsFx();
      } catch (err) {}
    };

    if (!traitsList.length) {
      loadData();
    }
  }, [traitsList]);

  const allTraits = useMemo(() => {
    const operatorsTranslation = {
      GREATER_THAN: formatMessage({ id: 'audience.operators.greaterThan' }),
      LOWER_THAN: formatMessage({ id: 'audience.operators.lowerThan' }),
      EQUALS: formatMessage({ id: 'audience.operators.equals' }),
      YES: formatMessage({ id: 'audience.operators.yes' }),
      NO: formatMessage({ id: 'audience.operators.no' })
    };
    return (
      <View key="filters" style={{ ...styles.divider, width: 300 }}>
        {filters?.map((filter, index) => {
          const expressions = formatExpressions(filter, operatorsTranslation);
          const allTraits = $traits.getState();
          const trait = allTraits?.find((traitData) => traitData.id === filter.traitId);

          const valueExpression = expressions?.map(function (expression) {
            if (expression?.value && !expression?.operator && !expression?.number) {
              return `${expression.value};`;
            }
            return '';
          });

          return (
            <View key={filter.traitId}>
              <Text style={{ ...styles.tableContent, ...styles.bottom }}>{trait?.name}</Text>
              {expressions?.map((expressionParam: ExpressionParams, index: number) => {
                return expressionParam?.value &&
                  !expressionParam?.operator &&
                  !expressionParam?.number ? null : (
                  <Text style={styles.tableContent} key={filter.traitId}>
                    {formatMessage(
                      { id: `audience.chipCopywrite.${filter.traitId}` },
                      { ...expressionParam }
                    )}
                  </Text>
                );
              })}
              <Text style={styles.contentTrait} key={filter.traitId}>
                {valueExpression}
              </Text>
              {filters.length - 1 !== index && <View style={styles.mainDivider} />}
            </View>
          );
        })}
      </View>
    );
  }, [filters, formatMessage, traitsList]);

  return <View>{allTraits}</View>;
}
