import { Dialog, Heading } from '@hexa-ui/components';
import { useStore } from 'effector-react';

import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getTraitsFx } from '../../services/identity/audiences/TraitsService';
import { $traits } from '../../stores/audienceManagement/TraitsStore';
import { AllTraitsModalProps } from './AllTraitsModal.d';
import Trait from './components/Trait';
import { allTraitsStyles } from './styles';

export function AllTraitsModal({ isOpen, handleToggle, filters }: AllTraitsModalProps) {
  const { formatMessage } = useIntl();
  const styles = allTraitsStyles();

  const traitsList = useStore($traits);

  useEffect(() => {
    const loadData = async () => {
      await getTraitsFx();
    };

    if (!traitsList.length) {
      loadData();
    }
  }, [traitsList]);

  const allTraits = useMemo(() => {
    const operatorsTranslation = {
      GREATER_THAN: formatMessage({ id: 'audience.operators.greaterThan' }),
      LOWER_THAN: formatMessage({ id: 'audience.operators.lowerThan' }),
      EQUALS: formatMessage({ id: 'audience.operators.equals' }),
      YES: formatMessage({ id: 'audience.operators.yes' }),
      NO: formatMessage({ id: 'audience.operators.no' })
    };

    return (
      <div className={styles.containerItems}>
        {filters?.map((filter) => (
          <Trait
            key={filter._id + filter.traitId}
            filter={filter}
            operatorsTranslation={operatorsTranslation}
            traitsList={traitsList}
          />
        ))}
      </div>
    );
  }, [filters, styles.containerItems, formatMessage, traitsList]);

  return (
    <div className={styles.container}>
      <Dialog.Root
        data-testid="traits-modal-container"
        title={
          <Heading className={styles.title}>{`${formatMessage({ id: 'touchpoint.traits' })} (${
            filters.length
          })`}</Heading>
        }
        open={isOpen}
        onClose={(e) => handleToggle(e)}
      >
        {allTraits}
      </Dialog.Root>
    </div>
  );
}
