import { LoadingDots } from '@hexa-ui/components';
import { useState } from 'react';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { InfiniteListProps } from './InfiniteList.d';
import { List } from './components/List';
import { infiniteListStyles } from './style';

export function InfiniteList<T>({
  items,
  containerClassName,
  itemsPerLoad,
  renderItem,
  onNextPage,
  hasMore = false,
  isLoading = false
}: InfiniteListProps<T>) {
  const [totalItems, setTotalItems] = useState(itemsPerLoad);

  const setRefElement = useInfiniteScroll(() => {
    if (totalItems > items.length) {
      if (typeof onNextPage === 'function' && hasMore && !isLoading) {
        onNextPage();
      }
      return;
    }

    setTotalItems((total) => total + itemsPerLoad);
  }, items);

  const { loadingWrapper } = infiniteListStyles();

  return (
    <List
      items={items.slice(0, totalItems)}
      containerClassName={containerClassName}
      renderItem={renderItem}
    >
      <div ref={setRefElement} className={loadingWrapper} data-testid="infinite-list-ref">
        {isLoading && (
          <div data-testid="infinite-list-loading-wrapper">
            <LoadingDots />
          </div>
        )}
      </div>
    </List>
  );
}
