import { createStore } from 'effector';
import { GoalType } from '../../domain/goalType';
import { getAllGoalTypeFx } from '../../services/identity/campaignTypes/campaignTypesService';
import { campaignTypeListStoreReset, selectCampaignType } from './CampaignTypeEvents';

export const $campaignTypeListStore = createStore<GoalType[]>([]).reset(campaignTypeListStoreReset);
$campaignTypeListStore
  .on(getAllGoalTypeFx.doneData, (_, payload) => {
    return payload.content;
  })
  .on(getAllGoalTypeFx.fail, () => []);

export const $campaignTypeSelectStore = createStore<GoalType>({} as GoalType).reset(
  campaignTypeListStoreReset
);
$campaignTypeSelectStore.on(selectCampaignType, (_, payload) => payload);
