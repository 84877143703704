import { useFeatureToggleV2 } from 'admin-portal-shared-services';
import { optimizelyProjectName } from '../../constants';
import EngageToggles from './useFeatureToggle.types';

export const useFeatureToggle = (toggle: EngageToggles) => {
  const [isEnable, isLoading] = useFeatureToggleV2(toggle, optimizelyProjectName);
  return [isEnable, isLoading];
};

export default useFeatureToggle;
