import { format, parse } from 'date-fns';

export const handleDateFormat = (date: string) => {
  const newDate = parse(date, 'yyyy-MM-dd', new Date());
  return format(newDate, 'MM/dd/Y');
};

export const getFormattedDate = (): string => {
  const currentDate = new Date();
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  };
  return currentDate.toLocaleString('en-US', options);
};

export const getStringDate = (): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const padStartNumber = 2;
  const month = (currentDate.getMonth() + 1).toString().padStart(padStartNumber, '0');
  const day = currentDate.getDate().toString().padStart(padStartNumber, '0');
  return `${year}${month}${day}`;
};
