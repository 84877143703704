import { createStore } from 'effector';
import { cloneDeep } from 'lodash';
import { fetchLoadPackage } from './PackageEffects';
import { packageAdded, packageReset } from './PackageEvents';
import { PackageStorageProps } from './PackageState';

const initialState: PackageStorageProps = {
  packageId: '',
  packageName: '',
  vendorId: '',
  partnerLegalName: '',
  packageDetailsUrl: '',
  packageRoute: '',
  skus: [],
  minStartDate: undefined,
  maxEndDate: undefined,
  goal: undefined
};

const PackageStore = createStore(initialState)
  .on(fetchLoadPackage.doneData, (state, payload) =>
    cloneDeep({
      ...state,
      ...payload
    })
  )
  .on(packageAdded, (state, payload) =>
    cloneDeep({
      ...state,
      ...payload
    })
  )
  .reset(packageReset);

export default PackageStore;
