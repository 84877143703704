import { Paragraph } from '@hexa-ui/components';
import Carousel, { CarouselNavProps } from 'react-material-ui-carousel';
import { FullBannerProps } from './FullBanner.d';
import { FullBannerStyles } from './FullBanner.styles';

export default function FullBanner({ previewImages }: FullBannerProps) {
  const { fullBanner, carouselContainer, carouselItem, bannerLegend } = FullBannerStyles();

  if (previewImages?.length < 1) return <></>;
  const isSingleImage = previewImages?.length === 1;
  const navButtonsProps: CarouselNavProps = {
    style: {
      backgroundColor: '#fff',
      border: '1px #888 solid',
      color: '#000',
      width: '35px',
      height: '35px'
    }
  };

  return (
    <div className={fullBanner} data-testid="fullbanner">
      <Carousel
        className={carouselContainer}
        autoPlay={false}
        timeout={300}
        indicators={!isSingleImage}
        navButtonsAlwaysInvisible={isSingleImage}
        navButtonsAlwaysVisible={!isSingleImage}
        navButtonsProps={navButtonsProps}
        indicatorContainerProps={{
          style: {
            marginTop: '8px'
          }
        }}
        navButtonsWrapperProps={{
          style: {
            height: '131px',
            top: '28px'
          }
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: '#047AF1'
          }
        }}
      >
        {previewImages?.map((url) => (
          <div key={url.name}>
            <Paragraph className={bannerLegend} size="basis">
              {url.cmsIndex}: {url.name}
            </Paragraph>
            <div
              className={carouselItem}
              style={{
                backgroundImage: `url("${url.image}")`
              }}
              data-testid="banneritem"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
