import { Theme } from '@admin-portal-shared-components/types';
import { makeStyles } from '@material-ui/core/styles';

const spaceBetween = 'space-between';

export const customCardStyles = makeStyles((theme: Theme) => ({
  customCardLarge: {
    width: '100%',
    maxWidth: '1080px',
    '@global': {
      '*::-webkit-scrollbar': {
        width: '8px'
      },
      '*::-webkit-scrollbar-track': {},
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.15)',
        borderRadius: '10px'
      }
    }
  },
  customCardMedium: {
    width: '100%',
    maxWidth: '864px'
  },
  customCardSmall: {
    width: '100%',
    maxWidth: '424px'
  },
  headerContainer: {
    padding: '24px 24px 14px',
    display: 'flex',
    justifyContent: spaceBetween
  },
  headerContainerLeft: {
    display: 'flex',
    justifyContent: spaceBetween
  },
  headerContainerHeading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: spaceBetween,
    alignItems: 'flex-start'
  },
  iconContainer: {
    marginRight: '24px'
  },
  subtitleContainer: {
    marginTop: '4px',
    color: theme.bzColorInterfaceLabelSecondary,
    fontWeight: theme.bzFontWeightSemibold,
    lineHeight: '24px'
  },
  editButton: {
    color: theme.bzColorSemanticInfoText,
    fontFamily: 'Work Sans',
    textDecoration: 'none',
    fontSize: '0.9rem',
    '& span': {
      cursor: 'pointer'
    }
  }
}));
